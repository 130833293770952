<template>
    <admin-layout title="System Notifications">
       
        <div class="">
            <DataTable 
            :headers="headers" 
            :count="items?.data.length">
                <template #body>
                    <tr v-for="item in items?.data" :key="item">
                        <td class="w-6/12 px-6 py-5 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.subject }}
                        </td>
                        <td class="w-4/12 px-6 py-5 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.receiver }}
                        </td>
                        <td class="text-center">
                            <ActionButton 
                                edit-btn
                                @click="router.push(`/contents/system-notification/edit/${item.id}`)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexSystemNotification"
            />
        </div>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';

import SystemNotification from "@/classes/SystemNotification";
import { paginatePage } from '@/helpers/PageHelper';

import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';

const router = useRouter();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

const goToPage   = ref();

const items = ref(null);

// Methods
const indexSystemNotification = (data: string) => {
    loader.value = true;

    const goToPageValue   = paginatePage(data);

    SystemNotification.indexSystemNotification(`${goToPageValue}`)
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.items;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const headers: { text: string }[] = [
    { text: 'Notification' },
    { text: 'Receiver' },
];

onMounted( () => {
    indexSystemNotification(goToPage.value);
});

</script>