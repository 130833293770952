<template>

    <admin-layout title="Notifications">

        <div v-if="$hasPermission('receive-system-notifications')" class="flex w-full">
            <div class="w-1/2 h-screen border-r">
                <div v-if="$hasPermission('mark-notifications-as-read')" class="flex p-6 items-center justify-between border-b">
                    <span
                        class="px-2 text-xs bg-[#23A2B4] rounded-full text-white py-[2px] font-semibold leading-4">{{ unreadCounts }}
                        Unread</span>
                    <p class="text-sm cursor-pointer" @click.prevent="markReadAll">Mark all as Read</p>
                </div>
                <div class="notif-container">
                    <template v-for="(item, index) in items" :key="index">
                        <div class="notif-item p-6 border-b cursor-pointer" @click.prevent="markRead(item.id)">
                            <div class="flex items-center justify-between mb-2">
                                <div class="flex items-center gap-2">
                                    <p class="text-sm"
                                    >{{ item.data.title }}</p>
                                    <span
                                        class="px-2 text-xs bg-[#23A2B4] rounded-full text-white py-[2px] font-semibold leading-4"
                                        :class="item.read_at ? 'hidden' : 'block'"
                                        >New</span>
                                </div>
                                <div class="date">
                                    <p class="text-sm text-admin-gray-500">{{ item.created_at }}</p>
                                </div>
                            </div>
                            <p class="lineclamp-2 text-sm text-admin-gray-500">{{ item.data.message }}</p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="w-1/2">
                <template v-if="selectedNotification">
                    <div class="p-6">
                        <div class="flex flex-col gap-2 mb-6">
                            <p class="text-lg font-semibold">{{ selectedNotification.data.title }}</p>
                            <p class="text-sm text-admin-gray-500">{{ selectedNotification.created_at  }}</p>
                            <p class="text-sm">{{ selectedNotification.data.message }}</p>
                        </div>
                        <a href="#" class="inline-flex items-center font-normal p-3 border border-transparent rounded-lg text-sm focus:outline-none focus:border-admin-gray-900 focus:ring focus:ring-admin-gray-300 disabled:opacity-25 transition bg-admin-gray-900 hover:bg-admin-gray-700 active:bg-admin-gray-900 text-white">
                            View Schedule
                            <ArrowRightIcon class="text-white w-4 h-4 ml-2"/>
                        </a>
                    </div>
                </template>
            </div>
        </div>

    <PageLoader v-if="loader" />

    <ErrorModal
    width="w-[548px]"
    title="Error"
    action-text="Close"
    cancel-text="Go back"
    :show="showError"
    @close="showError = false"
    @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    </admin-layout>

</template>
<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import AdminNotification from "@/classes/AdminNotification";
import { onMounted, ref } from 'vue';


// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

const items = ref(null);
const unreadCounts = ref(0);
const selectedNotification = ref(null)

// Methods
const indexAdminNotification = () => {
    loader.value = true;

    AdminNotification.indexAdminNotification()
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.notifications;
            unreadCounts.value = items.value.filter(x => x.read_at == null).length;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const markRead = (id) => {
    loader.value = true;

    selectedNotification.value = items.value.find(x => x.id == id)

    AdminNotification.readAdminNotification(id)
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.notifications;
            unreadCounts.value = items.value.filter(x => x.read_at == null).length;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}


const markReadAll = () => {
    loader.value = true;

    AdminNotification.readAllAdminNotification()
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.notifications;
            unreadCounts.value = items.value.filter(x => x.read_at == null).length;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted( () => {
    indexAdminNotification();
});

</script>