<template>
    <FormSection v-if="appointment">
        <template #title>
            Booking Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="First Name"
                    placeholder="First Name"
                    v-model="appointment.patient.first_name"
                    id="first_name"
                    name="first_name"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Middle Name"
                    placeholder="Middle Name"
                    v-model="appointment.patient.middle_name"
                    id="middle_name"
                    name="middle_name"
                />
            </div>
            
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Last Name"
                    placeholder="Last Name"
                    v-model="appointment.patient.last_name"
                    id="last_name"
                    name="last_name"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Patient ID"
                    placeholder="Patient ID"
                    v-model="appointment.patient.patient_id"
                    id="patient_id"
                    name="patient_id"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Hospital ID"
                    placeholder="Hospital ID"
                    v-model="appointment.patient.hospital_no"
                    id="hospital_no"
                    name="hospital_no"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    label="Email Address"
                    placeholder="Email Address"
                    v-model="payload.email_address"
                    :disabled="!isNetworkAvailable"
                    id="email_address"
                    name="email_address"
                />
            </div>
            
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Patient Type"
                    placeholder="Patient Type"
                    v-model="appointment.patient.hospital_no"
                    id="hospital_no"
                    name="hospital_no"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Branch"
                    placeholder="Branch"
                    v-model="appointment.branch_name"
                    id="branch_name"
                    name="branch_name"
                />
            </div>
            
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Medical Questionnaire Filled"
                    placeholder="Medical Questionnaire Filled"
                    v-model="medicalQuestionnaireFilled"
                    id="medicalQuestionnaireFilled"
                    name="medicalQuestionnaireFilled"
                />
            </div>

            <div class="col-span-6"></div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Booking Date"
                    placeholder="Booking Date"
                    v-model="new Date(appointment.booking_date).toISOString().split('T')[0]"
                    id="booking_date"
                    name="booking_date"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Time Slot"
                    placeholder="Time Slot"
                    v-model="timeslot"
                    id="booking_start_time"
                    name="booking_start_time"
                />
            </div>

            <!-- <div class="col-span-6">
                <TextInput 
                    disabled
                    label="End Time"
                    placeholder="End Time"
                    v-model="new Date(appointment.booking_end_time).toISOString().split('T')[1]"
                    id="booking_end_time"
                    name="booking_end_time"
                />
            </div> -->

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Package"
                    placeholder="Package"
                    v-model="appointment.package_name"
                    id="package_name"
                    name="package_name"
                />
            </div>
            
            
            <div class="col-span-6">
                <p class="block text-sm text-gray-900">Rider/s</p>
                <ul class="pl-5 list-disc">
                    <li class="text-gray-500 text-sm mt-1.5">
                        {{ riders }}
                    </li>
                </ul>
                <!-- <TextInput 
                    disabled
                    label="Rider/s"
                    placeholder="Rider/s"
                    v-model="riders"
                    id="riders"
                    name="riders"
                /> -->
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    add-on-left
                    add-on-text="₱"
                    label="Package Fee"
                    placeholder="Package Fee"
                    id="packageFee"
                    name="packageFee"
                    v-model="packageFee"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    add-on-left
                    add-on-text="₱"
                    label="Miscellaneous Fee"
                    placeholder="Miscellaneous Fee"
                    v-model="miscFee"
                    id="miscFee"
                    name="miscFee"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    add-on-left
                    add-on-text="₱"
                    label="Total Fee"
                    placeholder="Total Fee"
                    v-model="appointment.total_fee"
                    id="total_fee"
                    name="total_fee"
                />
            </div>

            <div class="col-span-6"></div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Booking Type"
                    placeholder="Booking Type"
                    v-model="appointment.booking_type"
                    id="booking_type"
                    name="booking_type"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Booking Status"
                    placeholder="Booking Status"
                    v-model="appointment.status"
                    id="status"
                    name="status"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="HMO ID"
                    v-model="appointment.patient.hmo_name"
                    :placeholder="appointment.patient?.hmo_name ? 'Yes' : 'No'"
                    id="hmo_id"
                    name="hmo_id"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Senior Citizen ID Uploaded"
                    :placeholder="appointment.patient?.senior_citizen ? 'Yes' : 'No'"
                    id="senior_citizen"
                    name="senior_citizen"
                     @click="viewID(appointment.patient.senior_citizen_id_base_64)"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="PWD ID Uploaded"
                    :placeholder="appointment.patient?.pwd_id_base_64 ? 'Yes' : 'No'"
                    id="pwd_id"
                    name="pwd_id"
                    @click="viewID(appointment.patient.pwd_id_base_64)"
                />
            </div>

            <div class="col-span-6"></div>

            <div class="col-span-full" v-if="doctorNote">
                <TextInput
                    textarea
                    disabled
                    label="Doctor's Note"
                    placeholder="Doctor's Note"
                    v-model="doctorNote"
                    id="doctorNote"
                    name="doctorNote"
                />
            </div>
            <div class="col-span-full" v-if="doctorTestResult">
                <DropzoneInput
                    label="Test Result"
                    description="PDF file"
                    v-model="doctorTestResult"
                    id="doctorTestResult"
                    name="doctorTestResult"
                />
            </div>

            <div class="col-span-full">
                <TextInput
                    disabled
                    label="Address Line"
                    placeholder="Address Line"
                    v-model="appointment.patient.building_number"
                    id="addressLine"
                    name="addressLine"
                />
            </div>
            <div class="col-span-6">
                <TextInput
                    disabled
                    label="Region"
                    placeholder="Region"
                    v-model="appointment.patient.region"
                    id="region"
                    name="region"
                />
            </div>
            <div class="col-span-6">
                <TextInput
                    disabled
                    label="Province"
                    placeholder="Province"
                    v-model="appointment.patient.province"
                    id="province"
                    name="province"
                />
            </div>
            <div class="col-span-6">
                <TextInput
                    disabled
                    label="City"
                    placeholder="City"
                    v-model="appointment.patient.city"
                    id="city"
                    name="city"
                />
            </div>
            <div class="col-span-6">
                <TextInput
                    disabled
                    label="Barangay"
                    placeholder="Barangay"
                    v-model="appointment.patient.barangay"
                    id="barangay"
                    name="barangay"
                />
            </div>
            <div class="col-span-6">
                <TextInput
                    disabled
                    label="Zip Code"
                    placeholder="Zip Code"
                    v-model="appointment.patient.zip_code"
                    id="zipCode"
                    name="zipCode"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    v-model="appointment.patient.cellphone_number"
                    id="mobileNumber"
                    name="mobileNumber"
                    />
                    <!-- addOnText="+63"
                        addOnLeft
                    :maxlength="10" -->
            </div>
        </template>

        <template #actions v-if="isNetworkAvailable">
            <ButtonComponent v-if="$hasPermission('set-individual-patient-appointment-time')" @click="showConfirmation = true">
                Update
            </ButtonComponent>
            <slot />
        </template>
    </FormSection>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        :errors="errors"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Confirm"
        title="Booking Submitted!"
        content="Booking has been updated successfully"
    />

    <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; updatePatientEmail() "
        cancel-text="Cancel"
        action-text="Confirm"
        title="Submit Booking?"
        content="Are you sure you wish to update this booking?"
    />

</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';

import ButtonComponent from '@/components/ButtonComponent.vue';
import DropzoneInput from '@/components/inputs/DropzoneInput.vue';

import { useRoute } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import BookingService from '@/classes/BookingService';
import { useNetworkStore } from '@/store/network';
import { onIonViewWillEnter } from '@ionic/vue';
import { format } from 'date-fns'
import { formateTimeSlot } from "@/helpers/TimeFormatter"
import { viewID } from "@/helpers/PackageRiderHelper"

const route = useRoute();

// const props = defineProps({
//     isNetworkAvailable: {
//         type: Boolean,
//         default: true,
//     },
// });

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const appointment = ref(null);
const medicalQuestionnaireFilled = computed(() => appointment.value.medical_questionnaire ? 'Yes' : 'No');
const riders = computed(() => {
    let items = '';

    if(appointment.value.other_charges_details.length > 0) {
        appointment.value.other_charges_details[0].trans_line.forEach(element => {
            items += element.items ? element.items.description + ', ' : '';
        });
    }
    return items;
});

const packageFee = computed(() => {
    if(appointment.value.package_details.length > 0){
        console.log('hello');
        
        return appointment.value.package_details[0].trans_line.reduce((a, b) => a + b.price, 0)
    }
    
    return 0;

});
const miscFee = computed(() => appointment.value.other_charges_details.length > 0 ? appointment.value.other_charges_details[0].trans_line.reduce((a, b) => a + b.price, 0) : 0);

const payload = ref({
    patient_id: null,
    email_address: null,
    last_name: null,
    first_name: null,
    sex: null,
    birthdate: null,
});

const emit = defineEmits<{
    ( e: 'fetched-item', val: object) : void
}>()

const isEmailValid = () => {
    const reg = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    
    return reg.test(payload.value.email_address) ? true : false;
}

const fetchIndividualBooking = () => {
    console.log("wew")
    loader.value = true

    BookingService.fetchIndividualBooking(route.params.bookingId)
        .then((result: any) => {
            loader.value = false;
            // console.log(result.data.response);
            appointment.value = result.data.response;

            emit("fetched-item", appointment.value);

            // Fill up payloads
            payload.value.patient_id = appointment.value.patient.patient_id;
            payload.value.email_address = appointment.value.patient.email_address;
            payload.value.last_name = appointment.value.patient.last_name;
            payload.value.first_name = appointment.value.patient.first_name;
            payload.value.sex = appointment.value.patient.sex;
            payload.value.birthdate = appointment.value.patient.birthdate;

            timeslot.value = formateTimeSlot(appointment.value.booking_start_time, appointment.value.booking_end_time)
            
            if(result.data.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const updatePatientEmail = () => {
    if(!isEmailValid()) {
        showError.value = true;
        errorMsg.value = "Please input a valid email address";

        return false;
    }

    loader.value = true;


    BookingService.updatePatientEmail(payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                showSuccess.value = true;
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const doctorNote = ref(null);
const doctorTestResult = ref(null);
const timeslot = ref<string>('');

const fetchNoteAndResult = () => {
    loader.value = true

    BookingService.fetchNoteAndResult(route.params.bookingId)
        .then((result: any) => {
            loader.value = false;
            doctorNote.value = result.data.response.doctors_note;

            if(result.data.response.lab_result_base_64) {
                setTimeout(() => {
                    convertBase64ToPdf(result.data.response.lab_result_base_64);
                }, 100)
            }
            
            if(result.data.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const convertBase64ToPdf = (base64File) => {

    if(base64File) {
        const decodedString = atob(base64File);
        const uint8Array = Uint8Array.from([...decodedString].map(char => char.charCodeAt(0)));
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        doctorTestResult.value = URL.createObjectURL(blob);
    }
}

onMounted(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        fetchIndividualBooking();
        // TODO : IF NEEDED
        // fetchNoteAndResult();
    }, 1000);
})

onIonViewWillEnter(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        fetchIndividualBooking();
        // TODO : IF NEEDED
        // fetchNoteAndResult();
    }, 1000);
})

</script>