<template>
    <div>
        <FormSection>
            <template #title>
                Booking Information
            </template>

            <template #description>
                Relevant data and other details
            </template>

            <template #form>
                <div class="col-span-12">
                    <TextInput
                        :model-value="packageName"
                        id="packageName"
                        name="packageName"
                        label="Package"
                        placeholder="Package"
                        disabled
                    />
                </div>
                <div class="col-span-12">
                    <label class="block text-sm text-gray-900">Riders</label>
                    <!-- Todo: Display actual riders once available -->
                    <div v-if="corporateBooking.other_charges_details && corporateBooking.other_charges_details.length > 0">
                        <ul v-for="(rider, index) in corporateBooking.other_charges_details" :key="index" class="pl-5 text-sm list-disc">
                            <li>{{ rider.item_name ?? 'TBD' }}</li>
                        </ul>
                    </div>
                    <div v-else>
                        <p>N/A</p>
                    </div>
                </div>
                <div class="col-span-6">
                    <DatePicker
                        v-if="unformattedBookingDate"
                        v-model="unformattedBookingDate"
                        id="unformattedBookingDate"
                        name="unformattedBookingDate"
                        label="Booking Date"
                        placeholder="MM/DD/YYYY"
                        disabled
                    />
                </div>

                <div class="col-span-6">
                    <TextInput 
                        v-if="unformattedBookingDate"
                        disabled
                        label="Time Slot"
                        placeholder="Time Slot"
                        id="time_slot"
                        name="time_slot"
                        v-model="timeSlot"
                    />
                </div>
                <!-- <div class="col-span-6">
                    <DatePicker
                        v-if="corporateBooking.booking_time_start"
                        v-model="corporateBooking.booking_time_start"
                        label="Start Time"
                        placeholder="Start Time"
                        timePicker
                        enableTimePicker
                        disabled
                    />
                </div> -->
                <div class="col-span-6">
                    <TextInput
                        v-model="employeeCount"
                        id="employeeCount"
                        name="employeeCount"
                        label="Number of Employees"
                        placeholder="Number of Employees"
                        disabled
                    />
                </div>
                
                <div class="col-span-12" v-if="isNetworkAvailable">
                    <DropzoneInput
                        v-model:file="csvFile"
                        label="Participating Corporate Employees"
                        description="CSV, EXCEL file"
                        accept=".csv"
                    />
                    <a
                        href="/assets/files/shinagawa-patients.csv"
                        download
                        class="inline-block mt-3 text-blue-500"
                    >
                        Download Corporate Patient List Manifest
                    </a>
                </div>
            </template>
        </FormSection>

        <div class="grid grid-cols-4 gap-6 pt-6 mb-6 border-t mx-7" v-if="isNetworkAvailable">
            <div>
                <TextInput
                    v-model="employee.first_name"
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    placeholder="First Name"
                />
            </div>
            <div>
                <TextInput
                    v-model="employee.middle_name"
                    id="middle_name"
                    name="middle_name"
                    label="Middle Name"
                    placeholder="Middle Name"
                />
            </div>
            <div>
                <TextInput 
                    v-model="employee.last_name"
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                />
            </div>
            <div>
                <TextInput 
                    v-model="employee.suffix"
                    id="suffix"
                    name="suffix"
                    label="Suffix"
                    placeholder="Suffix"
                />
            </div>
            <div>
                <TextInput
                    v-model="employee.email_address"
                    id="email_address"
                    name="email_address"
                    label="Email"
                    placeholder="Email"
                />
            </div>
            <div>
                <MultiselectInput
                    v-model="employee.sex"
                    id="sex"
                    name="sex"
                    label="Sex"
                    placeholder="Sex"
                    :options="sexes"
                />
            </div>
            <div>
                <DatePicker
                    v-model="employee.birthdate"
                    id="birthdate"
                    name="birthdate"
                    label="Birthday"
                    placeholder="MM/DD/YYYY"
                />
            </div>
        </div>

        <div class="mb-6 text-right px-7" v-if="isNetworkAvailable">
            <ButtonComponent
                @click="validateEmployee()"
            >
                Add Employee
            </ButtonComponent>
        </div>

        <div class="">
            <DataTable 
            :headers="headers" 
            :no-action="noAction"
            :count="employeeCount">
                <template #body>
                    <tr v-for="(employee, index) in payload.patients" :key="index">
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.first_name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.middle_name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.last_name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.suffix }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            <TextInput
                                v-model="payload.patients[index].email_address"
                                id="patient_email_address"
                                name="patient_email_address"
                                placeholder="Email"
                                :disabled="!isNetworkAvailable || employee.status != 'Booked'"
                            />
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.sex }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{  format(new Date(employee.birthdate), 'LLL dd, yyyy') }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                           <ButtonComponent
                                :disabled="loader"
                                @click="resendEmail(payload.patients[index])"
                            > 
                                    Resend Email
                            </ButtonComponent> 
                        </td>
                        <td 
                        v-if="!noAction && isNetworkAvailable && employee.status == 'Booked'"
                        class="flex p-3 text-center">
                            <ActionButton
                            @click="removeEmployee(index)"
                            close-btn/>
                            <!-- <ButtonComponent
                            :disabled="loader"
                            @click="resendEmail(payload.patients[index])"> 
                                Resend Email
                            </ButtonComponent>  -->
                        </td>
                    </tr>
                </template>
            </DataTable>
        </div>

        <div class="text-right p-7" v-if="isNetworkAvailable">
            <ButtonComponent v-if="$hasPermission('update-participating-employees')" @click="showConfirmation = true">
                Submit
            </ButtonComponent>
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="redirectToBookingIndex()"
            action-text="Confirm"
            title="Corporate Patient List Updated!"
            content="Corporate patient list has been updated"
        />

        <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; updateCorporatePatients() "
            cancel-text="Cancel"
            action-text="Confirm"
            title="Update Corporate Patient List?"
            content="Are you sure you wish to update the patient list for this corporate booking?"
        />
    </div>
</template>

<script setup lang="ts">
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DropzoneInput from '@/components/inputs/DropzoneInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';

import { ref, watch, computed, onMounted } from 'vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import BookingService from '@/classes/BookingService';
import { format } from 'date-fns';
import { useRouter, useRoute } from 'vue-router';
import { 
    Employee,
    updateCorporatePatientListPayload
} from '@/interfaces/CorporateBookingInterface';
import { useNetworkStore } from '@/store/network';
import { onIonViewWillEnter } from '@ionic/vue';
import { formateTimeSlot } from "@/helpers/TimeFormatter"

const router = useRouter();
const route = useRoute();

// defineProps({
//     isNetworkAvailable: {
//         type: Boolean,
//         default: true,
//     },
// });
const noAction = ref(false)
const loader = ref(false)
const showError = ref(false)
const errorMsg = ref()
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)
const csvFile = ref();

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const headers: { text: string }[] = [
    { text: 'First Name' },
    { text: 'Middle Name' },
    { text: 'Last Name' },
    { text: 'Suffix' },
    { text: 'Email' },
    { text: 'Gender' },
    { text: 'Birthday' },
    { text: 'Resend Email' },
];

const sexes = [
    { id: '1', label: 'Male', value: 'Male' },
    { id: '2', label: 'Female', value: 'Female' },
    // { id: '3', label: 'Prefer not to Say', value: 'Prefer not to Say' },
];

const patientDefaultValues = {
    cellphone_number : "TBA",
    telephone_number : "TBA",
    civil_status     : "TBA",
    nationality      : "TBA",
    religion         : "TBA",
    job_title        : "TBA",
    birthplace       : "TBA",
    building_number  : "TBA",
    street           : "TBA",
    barangay         : "TBA",
    city             : "TBA",
    province         : "TBA",
    region           : "TBA",
    zip_code         : "TBA",
    philhealth_number: "000000000"
};

const bookingId  = ref(Number(route.params.bookingId)) as unknown as number;
const employeeCount = ref(0);
const unformattedBookingDate = ref(null);
const corporateBooking = ref({});
const timeSlot = ref<string>('');

const packageName = computed(() => {
    return corporateBooking.value.package_name;
});

const employee = ref<Employee>({
    suffix : null,
    first_name : null,
    middle_name : null,
    last_name : null,
    email_address : null,
    sex : null,
    birthdate : null,
})

const payload = ref<updateCorporatePatientListPayload>({
    corporate_booking_id: bookingId,
    patients: [],
});

const emit = defineEmits<{
    ( e: 'fetched-item', val: object) : void
}>()

const isEmailValid = (email: string) => {
    const reg = /[^\s@]+@[^\s@]+\.[^\s@]+/;

    if(email) {
        return reg.test(email) ? true : false;
    }
    
    return false;
}

const validateEmployee = () => {
    if(
        employee.value.first_name == null ||
        employee.value.last_name == null ||
        employee.value.email_address == null ||
        employee.value.sex == null ||
        employee.value.birthdate == null
    ) {
        showError.value = true;
        errorMsg.value = "Please fill up all the the patient fields.";
    } else if(!isEmailValid(employee.value.email_address)) {
        showError.value = true;
        errorMsg.value = "Please input a valid email address";
    } else {
        addEmployee();
    }
}

const addEmployee = () => {
    const birthdate = new Date(employee.value.birthdate).toISOString().split('T')[0];

    const patientDetails = {
        suffix : employee.value.suffix, 
        first_name : employee.value.first_name,
        middle_name : employee.value.middle_name,
        last_name : employee.value.last_name,
        email_address : employee.value.email_address,
        sex : employee.value.sex,
        birthdate : birthdate, 
    };

    payload.value.patients.push(Object.assign(patientDetails, patientDefaultValues));

    employeeCount.value++;
    
    resetEmployeeFields();
}

const removeEmployee = (index: number) => {
    payload.value.patients.splice(index, 1);
    employeeCount.value--;
};

const resetEmployeeFields = () => {
    const keys = Object.keys(employee.value);
    keys.forEach(key => employee.value[key] = null);
}

const redirectToBookingIndex = () => {
    showSuccess.value = false;
    router.push(`/branches/${route.params.branchId}/bookings`);
}

const patientExists = (email_address: string) => {
    return payload.value.patients.some(patient => patient.email_address == email_address)
}

const pushPatientList = () => {
    if(corporateBooking.value.employee_list && corporateBooking.value.employee_list.length > 0) {
        const activeBookings = corporateBooking.value.employee_list
                                .filter(booking => booking.status == 'Booked' || booking.status == 'Done')
                                .sort((a, b) => b.booking_id - a.booking_id);
                            
        activeBookings.forEach(booking => {
            const employee_birthdate = new Date(booking.patient.birthdate).toISOString().split('T')[0]
    
            if(!patientExists(booking.patient.email_address)) {
                const patientDetails = {
                    suffix : booking.patient.suffix, 
                    first_name : booking.patient.first_name,
                    middle_name : booking.patient.middle_name,
                    last_name : booking.patient.last_name,
                    email_address : booking.patient.email_address,
                    sex : booking.patient.sex,
                    birthdate : employee_birthdate,
                    status : booking.status,
                };

                payload.value.patients.push(Object.assign(patientDetails, patientDefaultValues));
                employeeCount.value++;
            }
        });
    }
}

/**
 * Return the formatted time picker formatted values.
 *
 * @param  string[] time
 * @return object
 */
const timePickerFormat = (time: string[]) => {
    return {
        hours  : time[0],
        minutes: time[1],
        seconds: '00'
    };
}

/**
 * Get the formatted time string array.
 *
 * @param  string|null time
 * @return string[]
 */
const getFormattedTime = (time: string|null) => {
    if(time) {
        const timeSplit  = new Date(corporateBooking.value.booking_time_start).toISOString().split('T')[1];
        const timeStringArray = timeSplit.split(":")

        return timePickerFormat(timeStringArray)
    }

    return null;
}

const fetchCorporateBooking = () => {
    loader.value = true;

    BookingService.fetchCorporateBooking(route.params.bookingId)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                corporateBooking.value = result.data.response.response;

                timeSlot.value = formateTimeSlot(corporateBooking.value.booking_time_end, corporateBooking.value.booking_time_start)
                unformattedBookingDate.value = new Date(corporateBooking.value.booking_date).toISOString().split('T')[0]
            
                corporateBooking.value.booking_time_start = getFormattedTime(corporateBooking.value.booking_time_start);

                emit("fetched-item", corporateBooking.value);
                pushPatientList()
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const updateCorporatePatients = () => {
    loader.value = true;

    BookingService.updateCorporatePatientsList(payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                showSuccess.value = true;
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const isDateValid = (dateString: string) => {
    const timestamp = Date.parse(dateString);
    if (isNaN(timestamp)) {
        return false;
    } else {
        return true;
    }
}

const pushEmployeesFromCsv = () => {
    if(csvFile.value) {
        // FileReader Object
        const reader = new FileReader();
        // Read file as string 
        reader.readAsText(csvFile.value);

        // Load event
        reader.onload = function(event) {

            // Read file data
            const csvdata = event.target.result;

            // Split by line break to gets rows Array
            const rowData = csvdata.split('\n');

            let invalidPatientCount = 0;

            // Loop on the row Array (change row=0 if you also want to read 1st row)
            for (let row = 1; row < rowData.length; row++) {

                // Split by comma (,) to get column Array
                const rowColData = rowData[row].split(',');

                if(isDateValid(rowColData[6])) {
                    employeeCount.value++;

                    const patientDetails = {
                        first_name : rowColData[0],
                        middle_name : rowColData[1],
                        last_name : rowColData[2],
                        suffix : rowColData[3], 
                        email_address : rowColData[4],
                        sex : rowColData[5],
                        birthdate : rowColData[6],
                    };

                    payload.value.patients.push(Object.assign(patientDetails, patientDefaultValues));
                } else {
                    invalidPatientCount++;
                }
            }

            if(invalidPatientCount > 0) {
                showError.value = true;
                errorMsg.value = `${invalidPatientCount} row/s were not uploaded due to incorrect birth date values`;
            }
        };
    }
}

const resendEmail = (employee) => {
    loader.value = true;

    const payload = {
        employee : employee,
        corporate_name : corporateBooking.value.corporate_name
    }

    BookingService.resendEmail(payload)
        .then(() => {
            loader.value = false;
            showSuccess.value = true;
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

watch(
    () => csvFile.value,
    () => {
        pushEmployeesFromCsv()
    },
    { immediate: true }
);


onMounted(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        fetchCorporateBooking();
    }, 1000);
})

onIonViewWillEnter(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        fetchCorporateBooking();
    }, 1000);
})

</script>