<template>
    <admin-layout 
        title="View Rider"
        show-back
        backUrl="/contents/riders"
    >
        <div>
            <TabComponent :tabs="tabs" />
        </div>

        <div class="p-7">
            <FormSection>
                <template #title>
                    Schedule Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-12">
                        <ToggleSwitch
                            v-if="!loader"
                            :value="isWholeday"
                            v-model="isWholeday"
                            @change="(value) =>(isWholeday = value)"
                            label="Whole Day" 
                        />
                    </div>
                    <div class="col-span-full">
                        <p class="mb-1 text-sm text-gray-900">Booking Day/s</p>
                        <div class="grid grid-cols-1">
                            <div class="space-y-6">
                                <checkbox-input 
                                    id="all"
                                    label="All"
                                    :rounded="false"
                                    :is-checked="selectedDays.length == 7"
                                    text-color="text-darkgray-800"
                                    @change="addAll()"
                                />
                                <template v-for="i in days" :key="i.label">
                                    <checkbox-input 
                                        :id="i.label"
                                        :label="i.label"
                                        :rounded="false"
                                        :is-checked="getIndex(i.value) !== -1"
                                        text-color="text-darkgray-800"
                                        @change="addDay(i.value)"
                                    />
                                    <div 
                                        v-if="i.value != 'all' && getIndex(i.value) !== -1 && !isWholeday" 
                                        class="grid grid-cols-2 gap-6"
                                    >
                                        <div class="col-span-1">
                                            <TextInput
                                                type="time"
                                                :id="i.value + '_start_time'"
                                                :name="i.value + '_start_time'"
                                                label="Start Time"
                                                placeholder="Start Time"
                                                :step="60"
                                                v-model="selectedDays[getIndex(i.value)].start_time"
                                            />
                                        </div>
                                        <div class="col-span-1">
                                            <TextInput
                                                type="time"
                                                :id="i.value + '_end_time'"
                                                :name="i.value + '_end_time'"
                                                label="End Time"
                                                placeholder="End Time"
                                                :step="60"
                                                v-model="selectedDays[getIndex(i.value)].end_time"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6"></div>
                </template>

                <template #actions>
                    <ButtonComponent v-if="$hasPermission('update-package-and-rider-schedule')" @click="submit">
                        Save Changes
                    </ButtonComponent>
                </template>
            </FormSection>
        </div>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Rider Schedule"
            content="Rider Schedule updated Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from '@/components/FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import PageLoader from '@/components/PageLoader.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ToggleSwitch from '@/components/inputs/ToggleSwitch.vue';
import TextInput from '@/components/inputs/TextInput.vue';

import Rider from '@/classes/Rider';
import { daysOfTheWeek } from '@/helpers/PackageRiderHelper'
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { checkForInvalidResponse } from '@/helpers/PageHelper';
import { replaceDashWithBackslash } from '@/helpers/PackageRiderHelper';
import { Logger } from 'sass';


/**
 * ********************************
 * Interface 
 * ********************************
 */

interface selectedDaysInterface {
    day: number,
    start_time: string,
    end_time: string,
}
 

/**
 * ********************************
 * Data 
 * ********************************
 */
const route        = useRoute();
const isWholeday   = ref<boolean>(false);
const loader       = ref<boolean>(false)
const errors       = ref<object>([]);
const riderName    = ref(route.params.name);
const showSuccess  = ref<boolean>(false);
const showError    = ref<boolean>(false);
const errorMsg     = ref<boolean>(false);
const selectedDays = ref<selectedDaysInterface[]>([])

const time = ref<{
    start_time:string, 
    end_time:string
}>({
    start_time: '', 
    end_time: ''
});

const days = daysOfTheWeek

const tabs: { name: string, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        href: `/contents/riders/show/${riderName.value}`,
        allowed: true
    },
    {
        name: 'Schedule',
        href: `/contents/riders/schedule/${riderName.value}`,
        allowed: true
    },
];

/**
 * ********************************
 * Helper Methods 
 * ********************************
 */
const addDay = (val: any) => {
    const index:number = getIndex(val)

    if(index > -1) {
        selectedDays.value.splice(index, 1);
    } else {
        selectedDays.value.push({
            day: val,
            start_time: time.value?.start_time,
            end_time: time.value?.end_time,
        });
    }
}


const addAll = () => {
    if(selectedDays.value.length >= 7){
        selectedDays.value = [];
    } else {
        daysOfTheWeek.forEach(day => {
            selectedDays.value.push({
                day: day.value,
                start_time: time.value?.start_time,
                end_time: time.value?.end_time,
            });
        });
    }
}

const getIndex = (val: number|string):number => {
    return selectedDays.value.findIndex((day: any) => day.day === val) 
}


/**
 * ********************************
 * Submit Form 
 * ********************************
 */
 const submit = async () => {
    try {
        const payload = {
            rider_name: replaceDashWithBackslash(riderName.value),
            is_wholeday : isWholeday.value,
            days: selectedDays.value,
        };

        await Rider.updateRiderSchedule(payload);
        loader.value      = false;
        showSuccess.value = true;

        fetchRiderSchedule();

    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response?.data?.message;

        if (err.response?.status === 422) {
            errors.value = err.response.data.errors;
        }
    }
};
/**
 * ********************************
 * Fetch Rider Schedule 
 * ********************************
 */
const fetchRiderSchedule = async () => {
    loader.value = true;

    try {
        const { data } = await Rider.showRiderSchedule(replaceDashWithBackslash(riderName.value))

        loader.value = false;

        time.value = data.time;
        const schedule = data.schedule
        
        if(schedule){   

            isWholeday.value = schedule.is_wholeday == true
            selectedDays.value = schedule?.days || []
        }
    }
    catch(error) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = error.response?.data.message;

        if(error.response?.status == 422) {
            errors.value = error.response?.data.errors;
        }
        
        checkForInvalidResponse(error, '/contents/riders')
    }
    finally {
        loader.value = false;
    }
}

onMounted(() => fetchRiderSchedule())

</script>