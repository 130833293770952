<template>
    <admin-layout 
    title="Profile">
        <div class="p-7 border-b">
            <FormSection v-if="getLoggedAdmin.id == adminId">
                <template #title>
                    Profile Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-6">
                        <TextInput
                            v-model="profilePayload.first_name"
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            placeholder="First Name"
                        />
                    </div>
                    <div class="col-span-6">
                        <TextInput
                            v-model="profilePayload.last_name"
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            placeholder="Last Name"
                        />
                    </div>
                    <div class="col-span-6">
                        <TextInput
                            v-model="profilePayload.email"
                            id="email"
                            name="email"
                            label="Email Address"
                            placeholder="Email Address"
                        />
                    </div>
                    <div class="col-span-6">
                        <TextInput
                            v-model="profilePayload.mobile_number"
                            id="mobile_number"
                            name="mobile_number"
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            addOnLeft
                            addOnText="+63"
                            :maxlength="10"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        />
                    </div>
                </template>
                <template #actions>
                    <ButtonComponent v-if="$hasPermission('update-account-profile')" @click="updateAdmin()">
                        Save Changes
                    </ButtonComponent>
                </template>
            </FormSection>
        </div>
        <div class="p-7">
            <FormSection v-if="getLoggedAdmin.id == adminId">
                <template #title>
                    Security Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-12">
                        <password-input
                            v-model="passwordPayload.current_password"
                            id="current_password"
                            name="current_password"
                            label="Current Password"
                            placeholder="Current Password"
                        />
                    </div>
                    <div class="col-span-6">
                        <password-input
                            v-model="passwordPayload.password"
                            name="password"
                            id="password"
                            label="New Password"
                            placeholder="New Password"
                        />
                    </div>
                    <div class="col-span-6">
                        <password-input
                            v-model="passwordPayload.password_confirmation"
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm New Password"
                            placeholder="Confirm New Password"
                        />
                    </div>
                </template>
                <template #actions>
                    <ButtonComponent @click="changePassword()">
                        Save Changes
                    </ButtonComponent>
                </template>
            </FormSection>
        </div>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Profile"
            content="Profile Updated Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />
       
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import PageLoader from '@/components/PageLoader.vue';
import AdminData from '@/classes/AdminData';
import AuthService from '@/classes/AuthService';
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';


const route = useRoute();
const getLoggedAdmin = computed(() => AdminData.getAdmin()) 

// DATA
const loader      = ref(false)
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref();
const adminId     = ref(route.params.id as unknown as string);

const errors = ref([]);
const profilePayload = ref({
    first_name   : null,
    last_name    : null,
    email        : null,
    mobile_number: null
})

const passwordPayload = ref({
    email                : getLoggedAdmin.value.email,
    current_password     : null,
    password             : null,
    password_confirmation: null
})

const errorResponse = (err: any) => {
    loader.value = false

    if(err?.response.status == 401) {
        AuthService.redirectToLogin()
    }
}


const updateAdmin = async () => {
  loader.value = true;

  try {
    await AdminData.updateAdmin(adminId.value, profilePayload.value);
    loader.value      = false;
    showSuccess.value = true;

    setTimeout(() => {
      window.location.replace(`/profile/${adminId.value}`);
    }, 2000);

  } catch (error: any) {
    loader.value    = false;
    showError.value = true;
    errorMsg.value  = error.response?.data?.message;

    if (error.response?.status === 422) {
      errors.value = error.response.data.errors;
    }
    errorResponse(error);
  }
};

const changePassword = async () => {
  loader.value = true;

  try {
    await AdminData.tokenlessChangePassword(passwordPayload.value);
    loader.value      = false;
    showSuccess.value = true;

    setTimeout(() => {
      window.location.replace(`/profile/${adminId.value}`);
    }, 2000);

  } catch (error: any) {
    loader.value    = false;
    showError.value = true;
    errorMsg.value  = error.response?.data?.message;

    if(!errorMsg.value) {
        errorMsg.value  = error.response?.data?.error;
    }

    if (error.response?.status === 422) {
      errors.value = error.response.data.errors;
    }
  }
};

const validateAdmin = () => {
    if(getLoggedAdmin.value.id != adminId.value) {
        showError.value = true;
        errorMsg.value  = "Unauthorized access.";
    } else {
        fetchAdmin();
    }
}
const fetchAdmin = async() => {
    try {
        const response = await AdminData.editAdmin(adminId.value);
        Object.assign(profilePayload.value, response.data.admin);
    } catch (error: any) {
        showError.value = true;
        errorMsg.value = error.response.data.message;
    } finally {
        loader.value = false;
    }
}

onMounted(() => { validateAdmin() })
</script>