<template>
    <admin-layout 
    title="View Package"
    show-back
    backUrl="/contents/packages">
        <div>
            <TabComponent
                :tabs="tabs"
            >
            </TabComponent>
        </div>
        <div class="p-7">
            <FormSection>
                <template #title>
                    Package Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-12">
                        <TextInput 
                            id="package_name"
                            name="package_name"
                            disabled
                            label="Package Name"
                            placeholder="Package Name"
                            v-model="form.package_name"
                        />
                    </div>
                    <div class="col-span-12">
                        <TextInput 
                            id="package_type"
                            name="package_type"
                            disabled
                            label="Package Type"
                            placeholder="Package Type"
                            v-model="form.package_type"
                        />
                    </div>

                    <template v-for="package_line in form.package_line" :key="package_line">
                        <div class="col-span-12">
                            <TextInput 
                                :modelValue="package_line.items.item_cat.item_category"
                                :id="package_line.item_id + 'item_category'"
                                :name="package_line.item_id + 'item_category'"
                                disabled
                                label="Item Category"
                                placeholder="Item Category"
                            />
                        </div>
                        <div class="col-span-12">
                            <TextInput 
                                :modelValue="package_line.items.item_name"
                                :id="package_line.item_id + 'item_name'"
                                :name="package_line.item_id + 'item_name'"
                                disabled
                                label="Item"
                                placeholder="Item"
                            />
                        </div> 
                    </template>


                    <div class="col-span-12">
                        <TextInput 
                            id="rider_name"
                            name="rider_name"
                            disabled
                            label="Rider Name"
                            placeholder="Rider Name"
                            v-model="form.rider_name"
                        />
                    </div>
                    
                    <div class="col-span-6">
                        <TextInput
                            id="package_fee"
                            name="package_fee"
                            v-model="form.package_fee"
                            disabled
                            add-on-left
                            add-on-text="₱" 
                            label="Package Fee"
                            placeholder="Package Fee"
                        />
                    </div>
                </template>
            </FormSection>
        </div>
        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import PageLoader from '@/components/PageLoader.vue';

import Packages from '@/classes/Packages';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { checkForInvalidResponse } from '@/helpers/PageHelper';


/**
 * ********************************
 * Data 
 * ********************************
 */
const route       = useRoute();
const form        = ref({})
const loader      = ref(false)
const showError   = ref(false);
const errorMsg    = ref(false);
const packageName = ref(route.params.name);

const tabs: { name: string, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        href: `/contents/packages/show/${packageName.value}`,
        allowed: true
    },
    {
        name: 'Schedule',
        href: `/contents/packages/schedule/${packageName.value}`,
        allowed:
            window.$hasPermission('update-package-and-rider-schedule')
    },
];

/**
 * ********************************
 * Fetch Data 
 * ********************************
 */

const fetchPackage = async () => {
    try {
        loader.value = true;
        const response   = await Packages.showPackage(packageName.value);
        const packageFee = response.data[0].package_line.reduce((a,r) => a + parseFloat(r.item_fee), 0);

        const data = {
            package_name: response.data[0].package_name,
            package_id  : response.data[0].package_id,
            package_code: response.data[0].package_code,
            package_line: response.data[0].package_line,
            package_type: response.data[0].package_type,
            package_fee : packageFee,
        };

        Object.assign(form.value, data);

    } catch (error) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = error.response.data.message;

        checkForInvalidResponse(error, '/contents/packages')
    }
    finally{
        loader.value = false
    }
}

onMounted(() => fetchPackage())

</script>