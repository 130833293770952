<template>
    <ion-page>
        <ion-content :fullscreen="true" class="">
            <div class="flex flex-wrap items-center h-screen">
                <div class="w-1/2 text-center px-20">
                    <div class="logo-img">
                        <img src="assets/logo.png" alt="logo" class="w-full max-w-[500px]">
                    </div>
                </div>

                <div class="w-1/2">
                    <form class="w-[60%] mx-auto" @submit.prevent="">
                        <h3 class="text-lg font-bold">Forgot Password</h3>
                        <p class="text-sm text-gray-500 mb-6">Kindly input your email address</p>
                        <div class="mb-6">
                            <text-input
                                disabled
                                type="hidden"
                                v-model="form.email"
                            />
                        </div>

                        <div class="mb-4">
                            <password-input
                                    label="New Password"
                                    placeholder="New Password"
                                    v-model="form.password"
                                    :error="errorMsg"
                                />
                            </div>
                        <div class="mb-8">
                            <password-input
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                v-model="form.password_confirmation"
                            />
                        </div>
                        <button-component
                            :type="'submit'"
                            class="w-full"
                            @click="submit"
                            :disabled="loader"
                        >
                            Reset Password
                        </button-component>

                        <div class="pt-6 text-center">
                            <a class="text-sm transition duration-300 hover:text-primary-500" href="">Return to Login</a>
                        </div>
                    </form>
                </div>
            </div>
            <PageLoader v-if="loader" />

            <ErrorModal
                width="w-[548px]"
                title="Warning!"
                action-text="Close"
                cancel-text="Go back"
                :show="showError"
                @close="showError = false"
                @confirm="showError = false"
                :errors="errors"
            >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Forgot Password"
            content="Please check your email for the reset password link."
        />

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonContent } from '@ionic/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import AuthService from '@/classes/AuthService';

import { onMounted, ref } from 'vue';

const loader      = ref(false)
const showError   = ref(false)
const showSuccess = ref(false)
const errorMsg    = ref()
const errors      = ref([])

const form = ref({
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
})

const fetchParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    form.value.token = params.token ?? null
    form.value.email = params.email ?? null
}

const submit = async () => {
    try {
        loader.value = true
        await AuthService.changePassword(form.value)
        loader.value = false
        showSuccess.value = true

        setTimeout(() => {
            window.location.replace('/login');
        }, 1500);

    }catch(error) {
        loader.value = false
        loader.value = false;
        showError.value = true;
        errorMsg.value = error.response.data.message

        if(error.response.status == 422) {
            errors.value = error.response.data.errors;
        }
    }

}

onMounted(() => fetchParams())

</script>
