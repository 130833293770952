<template>
    <admin-layout 
    title="View Notification"
    show-back
    backUrl="/contents/pages/index">
        <div class="p-7">
            <FormSection>
                <template #title>
                    {{ item?.title }}
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-12">
                        <CKEditor
                            v-model="item.content"
                            label="Body"
                            id="body"
                        />
                    </div>
                </template>
                <template #actions>
                    <ButtonComponent
                        v-if="$hasPermission('update-content')" 
                        @click="showConfirmation = true"
                    >
                        Save Changes
                    </ButtonComponent>
                </template>
            </FormSection>
                    
        </div>


        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Confirm"
            title="System Notification Updated!"
            content="System Notification has been updated successfully"
        />

        <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; update() "
            cancel-text="Cancel"
            action-text="Confirm"
            :title="`Update ${item.title}?`"
            :content="`Are you sure you wish to update this ${item.title}?`"
        />
       
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FormSection from '@/components/FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import CKEditor from '@/components/inputs/CKEditor.vue';

import Pages from "@/classes/Pages";

import { PageInterface } from '@/interfaces/BaseInterface';

import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';

const route = useRoute();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

const item = ref<PageInterface>({
    id: Number(route.params.id),
    title: '',
    content: '',
});

// Methods
const edit = () => {
    loader.value = true;

    Pages.show(item.value.id)
        .then((response: any) => {
            loader.value = false;

            item.value = response.data;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const update = () => {
    loader.value = true;

    Pages.update(item.value.id, {
        content: item.value.content
    })
        .then(() => {
            loader.value = false;
            showSuccess.value = true;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted( () => {
    edit();
});

</script>