<template>
    <admin-layout 
        title="View Medical Record"
        show-back
        :backUrl="`/accounts/patients`">
        <PatientLayout :patient-id="patientId">
            <template #contents>
                <div v-if="canViewMedicalRecords" class="p-7">
                    <FormSection>
                        <template #title>
                            Medical Record Information
                        </template>

                        <template #description>
                            Relevant data and other details
                        </template>

                        <template #form>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.first_name"
                                    disabled
                                    label="First Name"
                                    placeholder="First Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.middle_name"
                                    disabled
                                    label="Middle Name"
                                    placeholder="Middle Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.last_name"
                                    disabled
                                    label="Last Name"
                                    placeholder="Last Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.patient_id"
                                    disabled
                                    label="Patient ID"
                                    placeholder="Patient ID"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.hospital_no"
                                    disabled
                                    label="Hospital ID"
                                    placeholder="Hospital ID"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.email_address"
                                    disabled
                                    label="Email Address"
                                    placeholder="Email Address"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.sex"
                                    disabled
                                    label="Sex"
                                    placeholder="Sex"
                                />
                            </div>
                            <div class="col-span-6"></div>

                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.nationality"
                                    disabled
                                    label="Nationality"
                                    placeholder="Nationality"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.civil_status"
                                    disabled
                                    label="Civil Status"
                                    placeholder="Civil Status"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.telephone_number"
                                    disabled
                                    label="Telephone Number"
                                    placeholder="Telephone Number"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.cellphone_number"
                                    disabled
                                    label="Cellphone Number"
                                    placeholder="Cellphone Number"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.province"
                                    disabled
                                    label="Province"
                                    placeholder="Province"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.city"
                                    disabled
                                    label="City/Municipality"
                                    placeholder="City/Municipality"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.barangay"
                                    disabled
                                    label="Barangay"
                                    placeholder="Barangay"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.street"
                                    disabled
                                    label="Street"
                                    placeholder="Street"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.building_number"
                                    disabled
                                    label="No./Building"
                                    placeholder="No./Building"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.zip_code"
                                    disabled
                                    label="Zip Code"
                                    placeholder="Zip Code"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.philhealth_number"
                                    disabled
                                    label="PhilHealth Number"
                                    placeholder="PhilHealth Number"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                v-model="form.hmo_id"
                                disabled
                                label="HMO"
                                placeholder="HMO"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.contact_person_name"
                                    disabled
                                    label="Contact Person Name"
                                    placeholder="Contact Person Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.contact_person_relationship"
                                    disabled
                                    label="Contact Person Relationship"
                                    placeholder="Contact Person Relationship"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.cellphone_number"
                                    disabled
                                    label="Cellphone Number"
                                    placeholder="Cellphone Number"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.contact_person_email"
                                    disabled
                                    label="Email Address"
                                    placeholder="Email Address"
                                />
                            </div>
                        </template>
                    </FormSection>
                </div>
            </template>
        </PatientLayout>
        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import PatientLayout from '../components/PatientLayout.vue';

import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PageLoader from '@/components/PageLoader.vue';

import Patient from '@/classes/Patient';
import { useRoute } from 'vue-router';
import { ref, computed, onMounted } from 'vue';

const route       = useRoute();
const loader      = ref(true)
const showError   = ref(false);
const errorMsg    = ref(false);
const form        = ref({})

const patientId = ref(route.params.id);

const canViewMedicalRecords = computed(() => {
    if(
        window.$hasPermission('view-corporate-patient-medical-records') ||
        window.$hasPermission('view-web-booking-medical-records') ||
        window.$hasPermission('view-contact-center-booking-medical-records') ||
        window.$hasPermission('view-patient-medical-records') ||
        window.$hasPermission('view-medical-records-in-offline')
    ) {
        return true;
    }

    return false;
});

onMounted(() => {
    loader.value = true;

    Patient.showPatient(patientId.value).then((response) => {
        loader.value = false;
        Object.assign(form.value, response.data)
    })
    .catch((err) => {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response.data.message;
    })
    
})

</script>