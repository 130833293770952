<template>
    <admin-layout 
    title="View Illness/Condition"
    show-back
    backUrl="/contents/conditions">
        <div>
            <TabComponent
                :tabs="tabs"
            >
            </TabComponent>
        </div>
        <div class="p-7">
            <FormSection
                :form="payload"
            >
                <ButtonComponent v-if="$hasPermission('update-content')" @click="showConfirmation = true">
                    Save Changes
                </ButtonComponent>
            </FormSection>
                
        </div>

        <PageLoader v-if="loader" />
    
        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
    
        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Confirm"
            title="Illness/Condition Updated!"
            content="Illness/Condition has been Updated successfully"
        />
    
        <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; updateIllnessCondition() "
            cancel-text="Cancel"
            action-text="Confirm"
            title="Submit Illness/Condition?"
            content="Are you sure you wish to submit this Illness/Condition?"
        />

       
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { ref, computed, onMounted } from 'vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import IllnessCondition from '@/classes/IllnessCondition';
import { useRoute } from 'vue-router';

const route = useRoute();

const illnessConditionId = route.params.id as unknown as string;

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

const payload = ref({
    id: illnessConditionId,
    name: null
});

const updateIllnessCondition = () => {
    loader.value = true;

    IllnessCondition.updateIllnessCondition(illnessConditionId, payload.value)
        .then(() => {
            loader.value      = false;
            showSuccess.value = true;

            setTimeout(() => {
                window.location.replace(`/contents/conditions`);
            }, 2000);
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const editIllnessCondition = () => {
    loader.value = true;

    IllnessCondition.editIllnessCondition(illnessConditionId)
        .then((result: any) => {
            loader.value = false;
            payload.value.name = result.data.illnessCondition.name;
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}


// const clearFields = () => {
//     payload.value.name = null;
// }

// Tabs
const tabs: { name: string, count: number, allowed: boolean }[] = computed(() => [
    {
        name: 'Information',
        count: 0,
        href: `/contents/conditions/edit/${illnessConditionId}`,
        allowed: true
    }
]);

onMounted(() => {
    editIllnessCondition()
})

</script>