<template>
    <admin-layout title="Reports">
       
        <div class="">
            <DataTable 
            :headers="headers" 
            :count="4">
                <template #body>
                    <tr>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-11/12">
                            <p>Number of New Members</p>
                        </td>
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push(`/reports/new-member`)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-11/12">
                            <p>Number of Active Members</p>
                        </td>
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push(`/reports/active-member`)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-11/12">
                            <p>Number of Total Members</p>
                        </td>
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push(`/reports/total-member`)"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-11/12">
                            <p>Number of New Bookings</p>
                        </td>
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push(`/reports/booking`)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
        </div>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';

import { useRouter } from 'vue-router';

const router = useRouter();


const headers: { text: string }[] = [
    { text: 'Label' },
];

</script>