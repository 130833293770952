<template>
    <admin-layout title="Branches">
        <div>
            <TabComponent
                :tabs="tabs"
                :button-items="true"
            >
                <template #buttons v-if="isNetworkAvailable">
                    <ButtonComponent
                        class="text-gray-900"
                        secondary
                        exportBtn
                        @click="exportData"
                        :disabled="loader"
                    >
                        Export
                    </ButtonComponent>
                    <ButtonComponent
                    createBtn
                    v-if="$hasPermission('create-branch') && $hasPermission('set-clinic-hours')"
                    @click="router.push('/branches/create')">
                        Create
                    </ButtonComponent>
                    <ButtonComponent
                    exportBtn
                    @click="downloadResources"> 
                        Sync Offline Resources
                    </ButtonComponent>
                </template>
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6" v-if="isNetworkAvailable">
            <FilterComponent
                :search="searchText"
                :text-filters="currentRoutePath !== '/branches/logs'"
                @fetchData="searchData"
                @update:filters="applyFilters"
                :custom-filters="activeTab !== 'activity_logs'"
            >
            <template #fields>
                  <div class="space-y-6">
                      <DatePicker
                        id="filterDate"
                        name="filterDate"
                        range
                        is24
                        isPresetRanges
                        v-model="filterDate"
                        placeholder="Start Date & End Date"
                        label="Start Date & End Date"
                      />
                  </div>
              </template>
            </FilterComponent>
        </div>

        <div class=""  v-if="currentRoutePath !== '/branches/logs'">
            <DataTable 
                :headers="headers" 
                :count="items?.data.length"
            >
                <template #body>
                    <tr v-for="(item, index) in items?.data" :key="index">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                            {{ item.name }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                            {{ item.formatted_created_at }}
                        </td>
                        <td class="text-center">
                            <ActionButton 
                                v-if="
                                    $hasPermission('update-branch') || 
                                    $hasPermission('update-clinic-hours') ||
                                    $hasPermission('create-blocked-date') ||
                                    $hasPermission('update-blocked-date') ||
                                    $hasPermission('archive-blocked-date') ||
                                    $hasPermission('restore-blocked-date') || 
                                    $hasPermission('create-company') ||
                                    $hasPermission('update-company') ||
                                    $hasPermission('archive-company') ||
                                    $hasPermission('restore-company') ||
                                    $hasPermission('view-company') || 
                                    $hasPermission('soft-book-dates-for-individual-patient')" 
                                edit-btn
                                @click="router.push(isNetworkAvailable ? `/branches/edit/${item.id}` : `/branches/${item.id}/bookings`)"
                            />
                            <template v-if="isNetworkAvailable">
                                <ActionButton 
                                v-if="!item.deleted_at && $hasPermission('archive-branch')"
                                delete-btn
                                @click="selectedBranch(item.id)"
                                />
                                <ActionButton 
                                v-if="item.deleted_at && $hasPermission('restore-branch')"
                                restore-btn
                                @click="restoreBranch(item.id)"
                                />
                            </template>
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexBranch"
            />
        </div>
        <div v-else>
            <ActivityLogsTable
                :logs="logs"
                @fetch-index="indexBranch"
            />
        </div>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; archiveBranch() "
        cancel-text="Cancel"
        action-text="Confirm"
        title="Archive Branch ?"
        content="Are you sure you wish to archive this Branch ?"
    />

    <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Close"
        title="Branch Export"
        content="Export is being processed. Please check your registered email."
    />

    <LoadingModal
        :show="preCacheLoader && isNetworkAvailable"
        @cancel="preCacheLoader = false"
        @confirm="preCacheLoader = false"
        :restartLoader="preCacheLoader"
        title="Downloading Resources"
        content="Resources is being download for offline use. Please wait."
    />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import LoadingModal from '@/components/modal/LoadingModal.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import ActivityLogsTable from '@/components/ActivityLogsTable.vue';

import throttle from "lodash/throttle";
import branch from "@/classes/Branch";
import { paginatePage } from '@/helpers/PageHelper';
import { useRouter } from 'vue-router';
import { onMounted, ref, computed, watch } from 'vue';
import { useNetworkStore } from '@/store/network';

/**
 * ********************************
 * Data 
 * ********************************
 */
const router          = useRouter();
const loader          = ref(false);
const showError       = ref(false);
const errorMsg        = ref();
const seletected      = ref();
const showConfirmation = ref(false);
const showSuccess     = ref(false);

const currentRoutePath = computed(() => router.currentRoute.value.path);
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

/**
 * ********************************
 * query 
 * ********************************
 */
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();
const tab   = ref('all');

/**
 * ********************************
 * Page Renderer Data 
 * ********************************
 */
const items           = ref(null);
const items_count     = ref(null);
const rows            = ref(null);
const query           = ref(null);
const selected_tab    = ref('all');
const filterDate       = ref(null);
const log_event       = ref(null);
const log_query       = ref(null);
const log_date        = ref(null);
const active_count    = ref(0);
const archived_count  = ref(0);
const log_counts      = ref(0);
const logs            = ref({});
const log_event_lists = ref(null);

// Tabs

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = computed(() => [
    {
        name: 'Active',
        count: active_count.value,
        href: '/branches',
        allowed: true
    },
    {
        name: 'Archived',
        count: archived_count.value,
        href: "/branches/archived",
        allowed: isNetworkAvailable.value
    },
    {
        name: 'Activity logs',
        count: log_counts.value,
        href: "/branches/logs",
        allowed:
            isNetworkAvailable.value &&
            window.$hasPermission('view-activity-logs')
    }
]);

// Headers
const headers: { text: string }[] = [
    { text: 'Branch' },
    { text: 'Date Created'}
];

/**
 * ********************************
 * Methods 
 * ********************************
 */
const applyFilters = () => {
    indexBranch(goToPage.value)
}

const searchData = (val: string) => {
    searchText.value = val
}

const indexBranch = async (data: string) => {
    // console.log(isNetworkAvailable.value);
    if(isNetworkAvailable.value) {
        console.log("Online - Fetching Branches...")
        try {
            loader.value = true;
            const queryString     = searchText.value ? `&query=${searchText.value}` : 'query';
            const dateQuery       = filterDate.value ? `&filterDate=${filterDate.value}` : '';
            const isArchivedRoute = currentRoutePath.value === '/branches/archived';
            const tabValue        = isArchivedRoute ? 'archived' : tab.value;
            const goToPageValue   = paginatePage(data);
            
            const response = await branch.indexBranch(`${goToPageValue}&tab=${tabValue}&${queryString}&${dateQuery}`)
            
            items.value           = response?.data.items;
            items_count.value     = response?.data.items_count
            rows.value            = response?.data.rows
            query.value           = response?.data.query
            selected_tab.value    = response?.data.selected_tab
            log_event.value       = response?.data.log_event
            log_query.value       = response?.data.log_query
            log_date.value        = response?.data.log_date
            active_count.value    = response?.data.active_count
            archived_count.value  = response?.data.archived_count
            log_counts.value      = response?.data.log_counts
            logs.value            = response?.data.logs
            log_event_lists.value = response?.data.log_event_lists
        }
        catch(error) {
            showError.value = true;
            errorMsg.value = error.response.data.message;
                
            if(error?.response?.status == 401) {
                window.location.replace('/login');
            }
        }
        finally {
            loader.value = false;
        }
    } else {
        console.log("Offline - Fetching Branches...")
        try {
            loader.value = true;
            
            const response = await branch.indexBranch(`row=999`)
            
            items.value           = response?.data.items;
        }
        catch(error) {
            showError.value = true;
            errorMsg.value = "Oppps... No available data was saved for offline use.";
        }
        finally {
            loader.value = false;
        }
    }
    
}
/**
 * ********************************
 * Archive Branch 
 * ********************************
 */
 const selectedBranch = (id: string) => {
    showConfirmation.value = true
    seletected.value = id
}

const archiveBranch = async () => {
    try {
        loader.value = true;
        await branch.deleteBranch(seletected.value);
        loader.value = false;
        window.location.replace('/branches/archived');
    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data.message;
    }
};

/**
 * ********************************
 * Restore Branch 
 * ********************************
 */

 const restoreBranch = async (id: string) => {
    try {
        loader.value = true;
        await branch.restoreBranch(id);
        loader.value = false;
        window.location.replace('/branches')
    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data.message;
    }
};

const preCacheLoader = ref(false);

const preCacheBranches = async () => {

    const cache = await caches.open('shinagawa-admin');
    const cachedResponse = await cache.match(`${process.env.VUE_APP_API_URL}branches/index?row=999`);

    if(!cachedResponse) {
        preCacheLoader.value = true;
        try {
            // Precache Branches
            await branch.indexBranch(`row=999`)
            // const total = response.data.total;
            // const pages = Math.ceil(total / 10);
    
            // for (let index = 0; index < pages; index++) {
            //     const page = index + 1;
            //     const res = await branchBooking.indexBranch(`page=${page}`)
            // }
    
        } catch(error: any){
            console.log(error.response.status)
        }
        preCacheLoader.value = false;
    }
}

/**
 * ********************************
 * Export Branch 
 * ********************************
 */
 const exportData = async () => {
    try {
        loader.value = true;
        const params = currentRoutePath.value === '/branches/archived' ? 'tab=archived' : '';

        await branch.exportBranch(params);
        showSuccess.value = true;
    } catch (error) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

const downloadResources = async () => {
    caches.delete('shinagawa-admin');
    
    // setTimeout(() => {
        preCacheBranches();
    // }, 1000);
}

watch(
    searchText,
    throttle(() => {
        indexBranch(goToPage.value)
    }, 1000)
);

watch(
    () => isNetworkAvailable.value,
    (val) => {
        console.log('Watcher - Network: ' + val);
        indexBranch(goToPage.value);
    }
);

onMounted(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        indexBranch(goToPage.value);
        preCacheBranches();
    }, 1000);
})

</script>