<template>
    <div class="relative w-64 h-screen py-6 overflow-auto bg-white border-r border-gray-200 scrollbar-style">
        <div class="px-8 py-4 mb-6">
            <div class="flex items-center justify-between min-w-0 space-x-3">
                <!-- <img
                    class="object-cover w-10 h-10 bg-gray-300 rounded-full"
                    src="//via.placeholder.com/40x40"
                /> -->
                <div class="flex flex-col flex-1 min-w-0">
                    <p class="text-sm truncate text-primary-500">{{ getLoggedAdmin.name }}</p>
                    <p class="text-sm text-gray-500 truncate">Admin</p>
                </div>
            </div>
        </div>
        <nav class="mt-3">
            <div
                class="space-y-4"
                role="group"
                aria-labelledby="desktop-main-headline"
            >
                <template v-for="item in navigation" :key="item.name">
                    <div v-if="!item.children">
                        <a
                            :href="item.href"
                            :class="[
                                currentRoutePath == item.parentUrl
                                ? 'text-primary-500 pl-0'
                                : 'text-gray-500 hover:text-black',
                                'group flex items-center py-2 text-sm font-medium rounded-md',
                            ]"
                            :aria-current="item.current ? 'page' : undefined"
                            v-if="item.allowed"
                        >
                            <span
                            class="inline-block w-1 h-6 rounded-tr rounded-br mr-7"
                            :class="currentRoutePath == item.parentUrl ? 'bg-primary-500' : ''"
                            />
                            <img
                                :src="currentRoutePath == item.parentUrl ? item.activeIcon : item.icon"
                                :class="[
                                    currentRoutePath == item.parentUrl
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-5 flex-shrink-0 h-5 w-5',
                                ]"
                            />
                            {{ item.name }}
                        </a>
                    </div>
                    <Disclosure
                        as="div"
                        v-else-if="
                            item.children &&
                            item.children.filter((e) => e.allowed).length > 0
                        "
                        class="space-y-4"
                        :defaultOpen="true"
                    >
                        <DisclosureButton
                            :class="[
                            currentRoutePath == item.parentUrl
                            ? 'text-primary-500 pl-0'
                            : 'text-gray-600 hover:text-black pl-8',
                            'group w-full flex items-center py-2 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-gray-300',
                            ]"
                        >
                            <span
                            class="inline-block w-1 h-6 rounded-tr rounded-br bg-primary-500 mr-7"
                            v-if="currentRoutePath == item.parentUrl"
                            />
                            <img
                            :src="currentRoutePath == item.parentUrl ? item.activeIcon : item.icon"
                            class="flex-shrink-0 w-5 h-5 mr-5"
                            />
                            <span class="flex-1 text-sm">
                                {{ item.name }}
                            </span>
                        </DisclosureButton>
                        <DisclosurePanel class="space-y-4">
                            <template
                                v-for="subItem in item.children"
                                :key="subItem.name"
                            >
                                <a
                                :href="subItem.href"
                                :class="[
                                    currentRoutePath == subItem.parentUrl
                                    ? 'text-primary-500 pl-0'
                                    : 'text-gray-500 hover:text-black pl-8',
                                    'group flex items-center px-0 py-2 text-sm font-medium rounded-md',
                                ]"
                                :aria-current="subItem.current ? 'page' : undefined"
                                v-if="subItem.allowed"
                                >
                                    <span
                                    class="inline-block w-1 h-6 rounded-tr rounded-br bg-primary-500 mr-7"
                                    v-if="currentRoutePath == subItem.parentUrl"
                                    />

                                    <img
                                    :src="currentRoutePath == subItem.parentUrl ? subItem.activeIcon : subItem.icon"
                                    :class="[ 'mr-6 flex-shrink-0 h-5 w-5' ]"
                                    />
                                    {{ subItem.name }}
                                </a>
                            </template>
                        </DisclosurePanel>
                    </Disclosure>
                </template>
            </div>
        </nav>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/vue";
import AdminData from '@/classes/AdminData';
import { useNetworkStore } from '@/store/network';

const router = useRouter();
const networkStore = useNetworkStore();

const currentRoutePath = router.currentRoute.value.path;
const getLoggedAdmin = computed(() => AdminData.getAdmin());
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

interface generalNavType {
    name: string,
    href: string,
    parentUrl: Array<string> | string,
    icon: any,
    activeIcon: any,
    allowed?: boolean
    children?: generalNavType[]
}

// console.log(window.$hasPermission());

const navigation: generalNavType[] = computed(() => [
    {
        name: "Dashboard",
        href: "/home",
        parentUrl: [
            "/home",
        ],
        icon: "/assets/icons/nav/ic-nav-dashboard.svg",
        activeIcon: "/assets/icons/nav/ic-nav-dashboard-active.svg",
        allowed:
            isNetworkAvailable.value &&
            window.$hasPermission('view-dashboard')
    },
    {
        name: "Branches",
        href: "/branches",
        parentUrl: [
            "/branches",
        ],
        icon: "/assets/icons/nav/ic-nav-branches.svg",
        activeIcon: "/assets/icons/nav/ic-nav-branches-active.svg",
        allowed:
            !isNetworkAvailable.value ||
            (window.$hasPermission('create-branch') ||
            window.$hasPermission('update-branch') ||
            window.$hasPermission('archive-branch') ||
            window.$hasPermission('restore-branch') ||
            window.$hasPermission('create-blocked-date') ||
            window.$hasPermission('update-blocked-date') ||
            window.$hasPermission('archive-blocked-date') ||
            window.$hasPermission('restore-blocked-date') ||
            window.$hasPermission('set-clinic-hours') ||
            window.$hasPermission('update-clinic-hours') ||
            window.$hasPermission('create-company') ||
            window.$hasPermission('update-company') ||
            window.$hasPermission('archive-company') ||
            window.$hasPermission('restore-company') ||
            window.$hasPermission('view-company') ||
            window.$hasPermission('soft-book-dates-for-individual-patient'))
    },
    {
        name: "Contents",
        href: "#",
        parentUrl: ["#"],
        icon: "/assets/icons/nav/ic-nav-contents.svg",
        activeIcon: "/assets/icons/nav/ic-nav-contents-active.svg",
        children: [
            {
                name: "Packages",
                href: '/contents/packages',
                parentUrl: "/contents/packages",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    (window.$hasPermission('create-content') ||
                    window.$hasPermission('update-content') ||
                    window.$hasPermission('archive-content') ||
                    window.$hasPermission('restore-content') ||
                    window.$hasPermission('update-package-and-rider-schedule'))
            },
            {
                name: "Riders",
                href: '/contents/riders',
                parentUrl: "/contents/riders",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    (window.$hasPermission('create-content') ||
                    window.$hasPermission('update-content') ||
                    window.$hasPermission('archive-content') ||
                    window.$hasPermission('restore-content') ||
                    window.$hasPermission('update-package-and-rider-schedule'))
            },
            {
                name: "Illness/Conditions",
                href: '/contents/conditions',
                parentUrl: "/contents/conditions",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    (window.$hasPermission('create-content') ||
                    window.$hasPermission('update-content') ||
                    window.$hasPermission('archive-content') ||
                    window.$hasPermission('restore-content'))
            },
            {
                name: "HMO Accreditations",
                href: '/contents/hmo-accreditation',
                parentUrl: "/contents/hmo-accreditation",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    window.$hasPermission('update-content')
            },
            {
                name: "Email Notifications",
                href: '/contents/email-notification',
                parentUrl: "/contents/email-notification",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    window.$hasPermission('update-content')
            },
            {
                name: "System Notifications",
                href: '/contents/system-notification',
                parentUrl: "/contents/system-notification",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    window.$hasPermission('update-content')
            },
            {
                name: "Pages",
                href: '/contents/pages/index',
                parentUrl: "/contents/pages/index",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    (window.$hasPermission('create-content') ||
                    window.$hasPermission('update-content') ||
                    window.$hasPermission('archive-content') ||
                    window.$hasPermission('restore-content'))
            },
        ],
    },
    {
        name: "Accounts",
        href: "#",
        parentUrl: [
           "#"
        ],
        icon: "/assets/icons/nav/ic-nav-accounts.svg",
        activeIcon: "/assets/icons/nav/ic-nav-accounts-active.svg",
        children: [
            {
                name: "Patients",
                href: '/accounts/patients',
                parentUrl: "/accounts/patients",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    !isNetworkAvailable.value ||
                    (window.$hasPermission('view-individual-patients') ||
                    window.$hasPermission('view-corporate-patients') ||
                    window.$hasPermission('add-medical-notes-and-results') ||
                    window.$hasPermission('view-corporate-patient-medical-records') ||
                    window.$hasPermission('view-web-booking-medical-records') ||
                    window.$hasPermission('view-contact-center-booking-medical-records') ||
                    window.$hasPermission('view-patient-medical-records') ||
                    window.$hasPermission('view-medical-records-in-offline'))
            },
            {
                name: "Admins",
                href: '/accounts/admins',
                parentUrl: "/accounts/admins",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    (window.$hasPermission('create-admin-account') ||
                    window.$hasPermission('update-admin-account') ||
                    window.$hasPermission('archive-admin-account') ||
                    window.$hasPermission('restore-admin-account') ||
                    window.$hasPermission('register-for-an-account'))
            },
            {
                name: "Roles",
                href: '/accounts/roles',
                parentUrl: "/accounts/roles",
                icon: "/assets/icons/nav/ic-nav-bullet.svg",
                activeIcon: "/assets/icons/nav/ic-nav-bullet-active.svg",
                allowed:
                    isNetworkAvailable.value &&
                    window.$hasPermission('view-role')
            },
        ],
    },
    {
        name: "Reports",
        href: "/reports",
        parentUrl: [
            "/reports",
        ],
        icon: "/assets/icons/nav/ic-nav-reports.svg",
        activeIcon: "/assets/icons/nav/ic-nav-reports-active.svg",
        allowed:
            isNetworkAvailable.value &&
            (window.$hasPermission('view-reports') ||
            window.$hasPermission('export-reports'))
    },
    {
        name: "Settings",
        href: "/settings",
        parentUrl: [
            "/settings",
        ],
        icon: "/assets/icons/nav/ic-nav-settings.svg",
        activeIcon: "/assets/icons/nav/ic-nav-settings-active.svg",
        allowed:
            isNetworkAvailable.value &&
            window.$hasPermission('manage-settings')
    },
    {
        name: "Activity Logs",
        href: "/activity-logs",
        parentUrl: [
            "/activity-logs",
        ],
        icon: "/assets/icons/nav/ic-nav-activity-logs.svg",
        activeIcon: "/assets/icons/nav/ic-nav-activity-logs-active.svg",
        allowed:
            isNetworkAvailable.value &&
            window.$hasPermission('view-activity-logs')
    },
]);



</script>