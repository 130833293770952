<template>
    <admin-layout 
    title="View Block Date"
    show-back
    :backUrl="`/branches/edit/${branchId}`">
        <BranchesLayout :branch-id="branchId">
            <template #contents>
                <div>
                    <TabComponent
                    :tabs="tabs"
                    :button-items="true"
                    >
                        <template #buttons>
                            <ButtonComponent
                                class="text-gray-900"
                                secondary
                                exportBtn
                                @click="exportData"
                                :disabled="loader"
                            >
                                Export
                            </ButtonComponent>
                            <ButtonComponent
                            v-if="$hasPermission('create-blocked-date')"
                            createBtn
                            @click="router.push(`/branches/${branchId}/blocked-dates/create`)">
                                Create
                            </ButtonComponent>
                        </template>
                    </TabComponent>
                </div>
                <!-- Filter -->
                <div class="py-3 px-6">
                    <FilterComponent
                        :search="searchText"
                        :text-filters="currentRoutePath !== `${mainRoute}/logs`"
                        @fetchData="searchData"
                        @update:filters="applyFilters"
                        :custom-filters="activeTab !== 'activity_logs'"
                    >
                    <template #fields>
                        <div class="space-y-6">
                            <DatePicker
                                id="filterDate"
                                name="filterDate"
                                range
                                is24
                                isPresetRanges
                                v-model="filterDate"
                                placeholder="Start Date & End Date"
                                label="Start Date & End Date"
                            />
                        </div>
                    </template>
                    </FilterComponent>
                </div>

                <div class="" v-if="currentRoutePath !== `${mainRoute}/logs`">
                    <DataTable 
                        :headers="headers" 
                        :count="items?.data.length">
                        <template #body>
                            <tr v-for="(item, index) in items?.data" :key="index">
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ item.id }}
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ item.reason }}
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ item.formatted_blocked_date }}
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ new Date(`2000-01-01T${item.start_time}`).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' }) }} - {{ new Date(`2000-01-01T${item.end_time}`).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' }) }}
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ item.formatted_created_at }}
                                </td>
                                <td class="text-center">
                                    <ActionButton
                                        v-if="$hasPermission('update-blocked-date')"
                                        edit-btn
                                        @click="router.push(`/branches/${branchId}/blocked-dates/edit/${item.id}`)"
                                    />
                                    <ActionButton 
                                        v-if="!item.deleted_at && $hasPermission('archive-blocked-date')"
                                        delete-btn
                                        @click="selectedBlockedDate(item.id)"
                                    />
                                    <ActionButton 
                                        v-if="item.deleted_at && $hasPermission('restore-blocked-date')"
                                        restore-btn
                                        @click="restoreBlockedDate(item.id)"
                                    />
                                </td>
                            </tr>
                        </template>
                        <template>
                            p.
                        </template>
                    </DataTable>
                    <page-pagination class="mb-6" 
                        :items="items"  
                        @nextPage="indexBlockedDate"
                    />
                </div>
                <div v-else>
                    <ActivityLogsTable
                        :logs="logs"
                        @fetch-index="indexBlockedDate"
                    />
                </div>

            </template>
        </BranchesLayout>

        <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; archiveBlockedDate() "
        cancel-text="Cancel"
        action-text="Confirm"
        title="Archive Blocked Date ?"
        content="Are you sure you wish to archive this Blocked Date ?"
    />

    <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Close"
        title="Company Export"
        content="Export is being processed. Please check your registered email."
    />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import BranchesLayout from '../components/BranchesLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ActivityLogsTable from '@/components/ActivityLogsTable.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';

import BlockedDate from "@/classes/BlockedDate";
import throttle from "lodash/throttle";

import { paginatePage } from '@/helpers/PageHelper';
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref, watch, computed } from 'vue';

const router = useRouter();
const route = useRoute();
/**
 * ********************************
 * Data 
 * ********************************
 */
const loader           = ref(false);
const seletected       = ref();
const errorMsg         = ref(null);
const showError        = ref(false);
const showConfirmation  = ref(false);
const showSuccess      = ref(false);
const branchId         = ref(route.params.id as unknown as string)
const mainRoute        = ref(`/branches/${branchId.value}/blocked-dates`);

const currentRoutePath = computed(() => router.currentRoute.value.path);

const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();
const tab        = ref('all');


const tabs: { name: string, count: number, href: string, allowed: boolean }[] = computed(() => [
    {
        name: 'Active',
        count: active_count.value,
        href: mainRoute.value,
        allowed: true
    },
    {
        name: 'Archived',
        count: archived_count.value,
        href: `${mainRoute.value}/archived`,
        allowed: true
    },
    {
        name: 'Activity logs',
        count: log_counts.value,
        href: `${mainRoute.value}/logs`,
        allowed:
            window.$hasPermission('view-activity-logs')
    }
]);

const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Reason' },
    { text: 'Blocked Date'},
    { text: 'Blocked Time'},
    { text: 'Date Created'}
];

/**
 * ********************************
 * Page Renderer Data 
 * ********************************
 */
const items           = ref(null);
const items_count     = ref(null);
const rows            = ref(null);
const query           = ref(null);
const selected_tab    = ref(null);
const filterDate       = ref(null);
const log_event       = ref(null);
const log_query       = ref(null);
const log_date        = ref(null);
const active_count    = ref(null);
const archived_count  = ref(null);
const log_counts      = ref(null);
const logs            = ref(null);
const log_event_lists = ref(null);

/**
 * ********************************
 * Methods
 * ********************************
 */
const indexBlockedDate = async (data: string) => {

    try {
        loader.value = true;
        const queryString     = searchText.value ? `&query=${searchText.value}` : 'query';
        const dateQuery       = filterDate.value ? `&filterDate=${filterDate.value}` : '';
        const isArchivedRoute = currentRoutePath.value === `${mainRoute.value}/archived`;
        const tabValue        = isArchivedRoute ? 'archived' : tab.value;
        const goToPageValue   = paginatePage(data);

        const response = await BlockedDate.indexBlockedDate(branchId.value, `${goToPageValue}&tab=${tabValue}&${queryString}&${dateQuery}`)

        loader.value = false;

        items.value           = response.data.items;
        items_count.value     = response.data.items_count
        rows.value            = response.data.rows
        query.value           = response.data.query
        selected_tab.value    = response.data.selected_tab
        filterDate.value       = response.data.filterDate
        log_event.value       = response.data.log_event
        log_query.value       = response.data.log_query
        log_date.value        = response.data.log_date
        active_count.value    = response.data.active_count
        archived_count.value  = response.data.archived_count
        log_counts.value      = response.data.logs.total
        logs.value            = response.data.logs
        log_event_lists.value = response.data.log_event_lists

    }catch(error){
        loader.value = false;
        showError.value = true;
        errorMsg.value = error.response.data.message;
        
        if(error.response.status == 401) {
            window.location.replace('/login');
        }
    }

}

/**
 * ********************************
 * Methods 
 * ********************************
 */
const applyFilters = () => {
    indexBlockedDate(goToPage.value)
}

const searchData = (val: string) => {
    searchText.value = val
}

/**
 * ********************************
 * Archive
 * ********************************
 */
 const selectedBlockedDate = (id: string) => {
    showConfirmation.value = true
    seletected.value = id
}

const archiveBlockedDate = async () => {
    try {
        loader.value = true;
        await BlockedDate.deleteBlockedDate(seletected.value);
        loader.value = false;
        window.location.replace(`${mainRoute.value}/archived`);
    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data.message;
    }
};

/**
 * ********************************
 * Restore 
 * ********************************
 */
 const restoreBlockedDate = async (id: string) => {
    try {
        loader.value = true;
        await BlockedDate.restoreBlockedDate(id);
        loader.value = false;
        window.location.replace(mainRoute.value)
    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data.message;
    }
};

/**
 * ********************************
 * Export 
 * ********************************
 */
 const exportData = async () => {
    try {
        loader.value = true;
        const queryString = searchText.value ? `&query=${searchText.value}` : '';
        const dateQuery   = filterDate.value ? `&filterDate=${filterDate.value}` : '';
        const params      = currentRoutePath.value == `${mainRoute.value}/archived` ? 'tab=archived' : 'tab=all';

        await BlockedDate.exportBlockedDate(`${params}&branch_id=${branchId.value}${queryString}${dateQuery}`);

        showSuccess.value = true;
    } catch (error) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

watch(
      searchText,
      throttle(() => {
        indexBlockedDate(goToPage.value)
      }, 1000)
    );

onMounted( () => indexBlockedDate(goToPage.value));

</script>