<template>
    <TransitionRoot as="template" :show="dialog">
          <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('onClose')">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                   <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                      <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                   </TransitionChild>
 
                   <!-- This element is to trick the browser into centering the modal contents. -->
                   <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                   <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div class="sm:flex sm:items-start">
                               <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-[#F3F4F6] sm:mx-0 sm:h-10 sm:w-10">
                                  <PlusIcon class="h-6 w-6 text-primary--800" aria-hidden="true" />
                               </div>
                               <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                  <DialogTitle as="h3" class="text-lg leading-6 font-bold text-primary--800">
                                        Import {{ title }}
                                  </DialogTitle>
                                  <div class="mt-2">
                                        <p class="text-sm text-gray-500">
                                           Are your sure you wish to import this file?
                                        </p>
                                  </div>
                               </div>
                            </div>
                            <div class="mt-5">
                                <div class="img-upload__wrap rounded-md">
                                    <div class="upload-wrap">
                                        <img class="block mx-auto mb-4" src="/assets/images/img-placeholder.svg">
                                        <h5>Select a file <span class="text-gray-400">or drag and drop</span></h5>
                                        <label for="addon-upload">
                                            <span class="text-gray-400 text-sm" v-for="(fn,index) in file" :key="index">
                                                {{fn.name}} 
                                                <span v-if="index != file.length - 1">, </span>
                                            </span>

                                            <span class="text-xs text-gray-500" v-if="file.length === 0">
                                                Max File Size: 10MB | File Types: XLSX
                                            </span>

                                            <input type="file" @change="handleFileUpload" :name="'file_upload'" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5"></div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button 
                                    type="button" 
                                    :disabled="loader"
                                    class="bg-primary-500 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 font-medium sm:ml-3 sm:w-auto sm:text-sm border-primary-500 disabled:opacity-25 text-white focus:ring-1 focus:ring-offset-1" 
                                    @click="submitFile"
                                >
                                    Import
                                </button>
                                <button 
                                    type="button" 
                                    class="mt-3 w-full inline-flex justify-center bg rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm" 
                                    @click="$emit('onClose')" ref="cancelButtonRef"
                                >
                                    Cancel
                                </button>
                                <button
                                    v-if="manifestFileUrl"
                                    type="button" 
                                    class="mt-3 mr-3 w-full inline-flex justify-center bg rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm" 
                                    @click="downloadSample"
                                >
                                    Download Manifest
                                </button>
                            </div>
                      </div>
                   </TransitionChild>
                </div>
          </Dialog>
    </TransitionRoot>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Close"
        :title="`${title} Import`"
        content="Import success!"
    />
 </template>
 
 <script setup lang="ts">
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import http from '@/axios'
import { ref } from "vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

defineEmits<{
    ( e: 'onClose', val: any): void
}>()

const props = defineProps({
    title: {
        default: '',
        type: String
    },
    dialog: {
        default: false,
        type: Boolean
    },
    importRoute: {
        default: null,
        type: String
    },
    manifestFileUrl: {
        default: null,
        type: String
    },
});

const file         = ref([]);
const loader      = ref(false);
const showError   = ref(false);
const errorMsg    = ref(null);
const showSuccess = ref(false);

const handleFileUpload = (event: any) : void => {
    file.value = event.target.files
}

const submitFile = async () => {
    loader.value = true;

    const formData = new FormData()
    formData.append('import_file', file.value[0])

    try {
        await http.post(props.importRoute, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        loader.value = false;
        showSuccess.value = true

        setTimeout(() => window.location.reload(), 2000);

    } catch ({response: {status, data: {message}} = {}}) {
        loader.value = false;
        showError.value = true;
        errorMsg.value = message;

        if(status === 401) {
            window.location.replace('/login');
        }
    }
}

const downloadSample = () => {
    const filePath = props.manifestFileUrl;
    // Use window.open() to initiate the file download
    window.open(filePath, '_blank');
}
 </script>

<style lang="scss" scoped>
.img-upload__wrap {
    border: 2px dashed #BFC7D1;
    padding: 2rem 1.375rem;
    box-sizing: border-box;
    max-width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    .upload-wrap {
        text-align: center;
        label {
            font-size: 12px;
            line-height: 16px;
            color: #6E8197;
            input {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .image-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        .uploaded-image {
            max-width: 100%;
            height: auto;
            margin: auto;
        }
        .remove-image {
            cursor: pointer;
            color: #cc0000;
        }
    }
}
</style>
 
 