<template>
    <admin-layout title="Activity Logs">
        <!-- Filter -->
        <div class="py-3 px-6">
            <FilterComponent
                :text-filters="false"
                @update:searchText="(value) => (searchText = value)"
                @update:filters="applyFilters"
                :custom-filters="activeTab !== 'activity_logs'"
            >
                <template #fields>
                    <div class="space-y-6">
                        <DatePicker
                            id="filterDate"
                            name="filterDate"
                            range
                            is24
                            isPresetRanges
                            v-model="filterDate"
                            placeholder="Start Date & End Date"
                            label="Start Date & End Date"
                        />
                    </div>
                </template>
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
                noAction
                :headers="headers" 
                :count="items?.data.length"
            >
                <template #body>
                    <tr v-for="(item, key) in items?.data" :key="key">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            <p>{{ item.id }}</p>
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.event }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-6/12">
                            {{ item.description }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.causer_name }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.created_at }}
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexActivityLogs"
            />
        </div>
        <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import PagePagination from '@/components/PagePagination.vue';
import PageLoader from "@/components/PageLoader.vue";
import http from '@/axios'

import { paginatePage } from '@/helpers/PageHelper';
import { onMounted, ref } from 'vue';

/**
 * ********************************
 * Data 
 * ********************************
 */
const loader      = ref(false);
const goToPage    = ref();
const filterDate   = ref(null);
const items       = ref(null);
const items_count = ref(null);

const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Subject' },
    { text: 'Description' },
    { text: 'Caused By' },
    { text: 'Date Created'}
];

/**
 * ********************************
 * Methods
 * ********************************
 */

const applyFilters = () => {
    indexActivityLogs(goToPage.value)
}

 const indexActivityLogs = async (data: string) => {
    loader.value = true;
    
    try  {
        const dateQuery     = filterDate.value ? `&filterDate=${filterDate.value}` : '';
        const goToPageValue = paginatePage(data);

        const response = await http.get(`activity-logs/index?${goToPageValue}&${dateQuery}`)

        loader.value          = false;
        items.value           = response.data.items;
        items_count.value     = response.data.items_count

    }catch(error) {
        loader.value = false;
        
        if(error.response.status == 401) {
            window.location.replace('/login');
        }
    }
}

onMounted(() => indexActivityLogs(goToPage.value))

</script>