<template>
    <admin-layout 
    title="View HMO Accreditation"
    show-back
    backUrl="/contents/hmo-accreditation">
        <div>
            <TabComponent :tabs="tabs" />
        </div>
        <div class="p-7">
            <FormSection :form="form" />
        </div>
       
        <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from './FormSection.vue';
import PageLoader from '@/components/PageLoader.vue';

import HmoAccreditations from '@/classes/HmoAccreditations'
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';

const route  = useRoute();
const loader = ref(false)
const form   = ref({})
const hmoId  = ref(route.params.id);

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        count: 0,
        href: '/contents/hmo-accreditation/show/' + hmoId.value,
        allowed: true
    },
];

onMounted(() => {
    loader.value = true;

    HmoAccreditations.showHmo(hmoId.value).then((response) => {
        loader.value = false;

        Object.assign(form.value, response.data[0])
    })
    .catch( () => loader.value = false)
})

</script>