<template>
    <admin-layout 
    title="View Role"
    show-back
    backUrl="/accounts/roles">
        <div>
            <TabComponent
                :tabs="tabs"
            >
            </TabComponent>
        </div>
        <div class="p-7">
            <FormSection :role="role"></FormSection>
        </div>
       
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from './FormSection.vue';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import RolePermissionService from '@/classes/RolePermissionService';

const route     = useRoute();
const loader    = ref(false)
const showError = ref(false)
const errorMsg  = ref(null)

const roleId = route.params.id as unknown as string;

const tabs: { name: string, count: number, href?: string, allowed: boolean }[] = [
    {
        name : 'Information',
        count: 0,
        href : '',
        allowed: true
    },
    {
        name : 'Permissions',
        count: 0,
        href : `/accounts/roles/permission/${roleId}`,
        allowed: true
    },
];

const role = ref({});

const fetchRole = () => {
    loader.value = true;

    RolePermissionService.showRole(roleId)
        .then((result: any) => {
            loader.value = false;
            role.value   = result.data.role;
        })
        .catch((error: any) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted(() => { fetchRole() })
</script>