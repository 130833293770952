<template>
    <admin-layout title="Settings">
       
        <div class="">
            <DataTable 
            :headers="headers" 
            :count="4">
                <template #body>
                    <tr>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-11/12">
                            <p>Reservation Capacity Per Course</p>
                        </td>
                        <td class="text-center">
                            <ActionButton
                            v-if="$hasPermission('manage-settings')"
                            edit-btn
                            @click="router.push(`/settings/edit/${item.id}`)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
        </div>

    <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import PageLoader from "@/components/PageLoader.vue";

import Setting from "@/classes/Setting";

import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';

const router = useRouter();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

const item = ref(null);

// Methods
const indexSetting = () => {
    loader.value = true;

    Setting.indexSetting()
        .then((response: any) => {
            loader.value = false;

            item.value = response.data.reservationCapacity;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const headers: { text: string }[] = [
    { text: 'Label' },
];

onMounted( () => {
    indexSetting();
});

</script>