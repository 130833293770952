<template>
    <FormSection v-if="appointment">
        <template #title>
            Booking Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="First Name"
                    placeholder="First Name"
                    v-model="appointment.patient.first_name"
                    id="first_name"
                    name="first_name"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Middle Name"
                    placeholder="Middle Name"
                    v-model="appointment.patient.middle_name"
                    id="middle_name"
                    name="middle_name"
                />
            </div>
            
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Last Name"
                    placeholder="Last Name"
                    v-model="appointment.patient.last_name"
                    id="last_name"
                    name="last_name"
                />
            </div>

            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Patient ID"
                    placeholder="Patient ID"
                    v-model="appointment.patient.patient_id"
                    id="patient_id"
                    name="patient_id"
                />
            </div>

            <div class="col-span-6">
                <TextInput
                    disabled
                    label="Email Address"
                    placeholder="Email Address"
                    v-model="appointment.patient.email_address"
                    id="email_address"
                    name="email_address"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Branch"
                    placeholder="Branch"
                    v-model="appointment.branch.name"
                    id="branch_name"
                    name="branch_name"
                />
            </div>
            <div class="col-span-6">
                <DatePicker
                    v-model="selectedDate"
                    id="selectedDate"
                    name="selectedDate"
                    label="Booking Date"
                    placeholder="MM/DD/YYYY"
                    :min-date="minDate"
                    :fully-booked-dates="fullyBookedDates"
                    hide-offset-dates
                    highlight-disabled-days
                    @updateMonth="updateMonth"
                    :disabled="!props.isNetworkAvailable"
                />
            </div>

            <div class="col-span-6">
                <MultiselectInput
                    v-model="selectedTime"
                    id="selectedTime"
                    name="selectedTime"
                    label="Booking Time"
                    placeholder="Booking Time"
                    :options="timeSlots"
                    :disabled="!props.isNetworkAvailable"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Package"
                    placeholder="Package"
                    v-model="appointment.package_name"
                    id="package_name"
                    name="package_name"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Rider/s"
                    placeholder="Rider/s"
                    v-model="riders"
                    id="riders"
                    name="riders"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    add-on-left
                    add-on-text="₱"
                    label="Package Fee"
                    placeholder="Package Fee"
                    v-model="appointment.package_fee"
                    id="package_fee"
                    name="package_fee"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    add-on-left
                    add-on-text="₱"
                    label="Miscellaneous Fee"
                    placeholder="Miscellaneous Fee"
                    v-model="appointment.other_charges_fee"
                    id="other_charges_fee"
                    name="other_charges_fee"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    add-on-left
                    add-on-text="₱"
                    label="Total Fee"
                    placeholder="Total Fee"
                    v-model="appointment.total_fee"
                    id="total_fee"
                    name="total_fee"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    disabled
                    label="Booking Type"
                    placeholder="Booking Type"
                    v-model="appointment.booking_type"
                    id="booking_type"
                    name="booking_type"
                />
            </div>
        </template>

        <template #actions v-if="props.isNetworkAvailable">
            <ButtonComponent v-if="$hasPermission('soft-book-dates-for-individual-patient')" :disabled="loader" @click="showConfirmation = true">
                Update
            </ButtonComponent>
            <slot />
        </template>
    </FormSection>

    <PageLoader v-if="loader" />

    <ErrorModal
    width="w-[548px]"
    title="Error"
    action-text="Close"
    cancel-text="Go back"
    :show="showError"
    @close="showError = false"
    @confirm="showError = false"
    :errors="errors"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <SuccessModal
    :show="showSuccess"
    @cancel="showSuccess = false"
    @confirm="showSuccess = false"
    action-text="Confirm"
    title="Booking Submitted!"
    content="Booking has been updated successfully"
    />

    <ConfirmationModal
    :show="showConfirmation"
    @cancel="showConfirmation = false"
    @confirm="showConfirmation = false; updateSoftBooking() "
    cancel-text="Cancel"
    action-text="Confirm"
    title="Submit Booking?"
    content="Are you sure you wish to update this booking?"
    />

</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { useRoute } from 'vue-router';
import { ref, watch, onMounted, computed } from 'vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import BookingService from '@/classes/BookingService';

const route = useRoute();

const props = defineProps({
    isNetworkAvailable: {
        type: Boolean,
        default: true,
    },
});


const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

// const selectedDate = ref(null)
const selectedTime = ref(null)
const fullyBookedDates = ref([]);
const unformattedTimeSlots = ref([]);
const timeSlots = ref([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const timezoneOffset = 8; // Philippines time zone is UTC+8
const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000 + timezoneOffset * 60 * 60 * 1000); // For 3 days advance booking

const minDate = computed(() => threeDaysLater.toISOString().slice(0, 10));
const from = ref(null);
const to = ref(null);

const selectedDate = ref(minDate.value)

const appointment = ref(null);
const riders = computed(() => {
    let items = '';
    appointment.value.other_charges_line.forEach(element => {
        items += element.item_name ? element.item_name + ', ' : '';
    });
    return items;
});

const payload = ref({
    id: null,
    branch_id: null,
    booking_date: null,
    booking_start_time: null,
    booking_end_time: null,
});

const fetchSoftBooking = () => {
    loader.value = true

    BookingService.fetchSoftBooking(route.params.bookingId)
        .then((result: any) => {
            loader.value = false;
            if(route.query.booking_type == 'Soft' || route.query.booking_type == 'soft') {
                appointment.value = result.data;
            } else {
                appointment.value = result.data.response;
            }

            console.log(appointment.value.branch_id);
            // Fill up payloads
            payload.value.id = appointment.value.id;
            payload.value.branch_id = appointment.value.branch_id;

            selectedDate.value = appointment.value.booking_date;
            if(appointment.value.booking_start_time && appointment.value.booking_end_time) {
                selectedTime.value = `${formatTime(appointment.value.booking_start_time)}-${formatTime(appointment.value.booking_end_time)}`
            }

            fetchDateAndTime();
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            console.log(error)

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  const suffix = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // handle midnight (00:00:00) as 12 AM
  return `${formattedHours}:${minutes} ${suffix}`;
};

const updateSoftBooking = () => {

    loader.value = true;


    BookingService.updateSoftBooking(payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                showSuccess.value = true;
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const formatTimeSlots = () => {
    timeSlots.value = [];
    for(const unformattedTimeSlot in unformattedTimeSlots.value) {
        unformattedTimeSlots.value[unformattedTimeSlot].forEach(slot => {
            if(slot.available_slots != 0) {
                if(!slot.is_blocked) {
                    timeSlots.value.push(slot);
                }
            }
        });

    }
}

const fetchDateAndTime = () => {
    loader.value = true

    const data = {
        branch_id: payload.value.branch_id,
        selected_date: payload.value.booking_date,
        from: from.value,
        to: to.value,
    }

    BookingService.fetchDateAndTime(data)
        .then((result: any) => {
            loader.value = false;

            fullyBookedDates.value = result.data.response.fully_booked_dates;         
            unformattedTimeSlots.value = result.data.response.time_slots;         
            
            if(selectedDate.value) {
                formatTimeSlots();
            }
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const updateMonth = (val = null) => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch fetchDateAndTime for Active Month
    if(payload.value.branch_id) {
        fetchDateAndTime();
    }
}

const firstDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

watch(
    () => selectedTime.value,
    (val) => {
        if(val)
        selectTimeSlot(val);
    },
    { immediate: true }
);

const selectTimeSlot = (val) => {
    if(val) {
        payload.value.booking_start_time = val.split('-')[0];
        payload.value.booking_end_time = val.split('-')[1];
    }
}

watch(
    () => selectedDate.value,
    (val) => {
        if(val)
            // selectedTime.value = null;
            payload.value.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];
            updateMonth();
    },
    { immediate: true }
);

onMounted(() => {
    fetchSoftBooking();
})

</script>