<template>
    <admin-layout title="HMO Accreditations">
        <div>
            <TabComponent
                :tabs="tabs"
                :button-items="true"
            >
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6">
            <FilterComponent
                :sidebar-filters="false"
                :text-filters="false"
                :search="searchText"
                @update:searchText="(value) => (searchText = value)"
                @update:filters="applyFilters"
                :custom-filters="activeTab !== 'activity_logs'"
            >
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
            :headers="headers" 
            :count="items?.total">
                <template #body>
                    <tr v-for="item in items?.data" :key="item">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-8/12">
                            {{ item.hmo_name }}
                        </td>
                        <td class="text-center">
                            <ActionButton 
                                edit-btn
                                @click="router.push(`/contents/hmo-accreditation/show/${item.hmo_id}`)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexHmo"
            />
        </div>
        <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import PageLoader from '@/components/PageLoader.vue';
import PagePagination from '@/components/PagePagination.vue';

import HmoAccreditations from '@/classes/HmoAccreditations'
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';

const router = useRouter();
// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

// Query
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();

// Page Renderer Data
const items       = ref(null);
const items_count = ref(0);
const query       = ref(null);


const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Active',
        count: 0,
        href: '/contents/hmo-accreditation',
        allowed: true
    }
];

const headers: { text: string }[] = [
    { text: 'HMO Accreditation' },
    // { text: 'Date Created'}
];


const indexHmo = (data: string) => {
    loader.value = true;

    const pageNum = data?.match(/\d+/)[0];
    const queryString =  searchText.value ? `&query=${searchText.value}` : ''

    goToPage.value = data ? `page=${pageNum}` : 'page=1';

    HmoAccreditations.indexHmo(goToPage.value + queryString)
        .then((response: any) => {
            loader.value          = false;
            items.value           = response.data;
            items_count.value     = response.data.total
            query.value           = response.data.query
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted(() => indexHmo(goToPage.value) )

</script>