<template>
    <admin-layout 
    title="Bookings"
    show-back
    :backUrl="`/branches`">
        <BranchesLayout :branch-id="branchId"> 
            <template #contents>
                <div>
                    <TabComponent
                    :tabs="tabs"
                    :button-items="true"
                    >
                        <template #buttons v-if="isNetworkAvailable">
                            <ButtonComponent
                                class="text-gray-900"
                                secondary
                                exportBtn
                                @click="exportData"
                                :disabled="loader"
                            >
                                Export
                            </ButtonComponent>
                            <ButtonComponent
                            exportBtn
                            @click="downloadResources"> 
                                Sync Offline Resources
                            </ButtonComponent>

                            <ButtonComponent
                                v-if="$hasPermission('soft-book-dates-for-individual-patient')"
                                createBtn
                                @click="router.push(`/branches/${branchId}/bookings/create`)"
                            > 
                                    Create
                            </ButtonComponent>
                        </template>
                    </TabComponent>
                </div>
                <!-- Filter -->
                <div class="px-6 py-3" v-if="isNetworkAvailable">
                    <FilterComponent
                        :search="searchText"
                        @fetchData="searchData"
                        @update:filters="applyFilters"
                        :textFilters="false"
                    >
                        <template #fields>
                            <div class="space-y-6">
                                <DatePicker
                                    id="filterCreatedDate"
                                    name="filterCreatedDate"
                                    range
                                    is24
                                    isPresetRanges
                                    v-model="filterCreatedDate"
                                    placeholder="Created Start and End Date"
                                    label="Created Start and End Date"
                                />
                                <DatePicker
                                    id="filterDate"
                                    name="filterDate"
                                    range
                                    is24
                                    isPresetRanges
                                    v-model="filterDate"
                                    placeholder="Booking Start and End Date"
                                    label="Booking Start and End Date"
                                />
                                <MultiselectInput
                                    v-model="bookingType"
                                    id="bookingType"
                                    name="bookingType"
                                    label="Booking Type"
                                    placeholder="Booking Type"
                                    :options="bookingTypes"
                                />
                            </div>
                        </template>
                    </FilterComponent>
                </div>

                <div class="">
                    <DataTable 
                    :headers="headers" 
                    :count="items_count">
                        <template #body>
                            <tr v-for="(item, index) in items.data" :key="index">
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.booking_type != 'Soft' ? item.booking_id : item.id }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.patient?.first_name }} {{ item.patient?.last_name  }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.booking_type == 'Individual' || item.booking_type == 'Soft' ? item.booking_type : 'Corporate' }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.booking_type }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.branch_name }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    <template v-if="item.corporate_name">
                                        {{ item.corporate_name }}
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.package_name }}
                                </td>
                                <!-- <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.other_charges_details }}
                                </td> -->
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ formateTimeSlot(item.booking_start_time, item.booking_end_time) }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ formatter.format(item.total_fee) }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    <StatusPill
                                    :color="getStatusColor(item.status)"
                                    :text="item.status"/>
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ new Date(item.booking_date).toISOString().split('T')[0] }}
                                </td>
                                <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                    {{ new Date(item.booking_created_date ? item.booking_created_date : item.created_at).toISOString().split('T')[0] }}
                                </td>
                                <td class="text-center">
                                    <ActionButton
                                    v-if="
                                        $hasPermission('set-individual-patient-appointment-time') ||
                                        $hasPermission('update-individual-patient-appointment-time') ||
                                        $hasPermission('soft-book-dates-for-individual-patient') ||
                                        $hasPermission('update-corporate-patient') ||
                                        $hasPermission('update-participating-employees')"
                                    edit-btn
                                    @click="router.push(`/branches/${branchId}/bookings/edit/${item.booking_type == 'Individual' ? item.booking_id : item.booking_type == 'Corporate' ? item.corporate_booking_id : item.id}?booking_type=${ item.booking_type == 'Individual' || item.booking_type == 'Soft' ? item.booking_type : 'Corporate' }`)"/>
                                </td>
                            </tr>
                        </template>
                    </DataTable>    
                </div>

                <page-pagination class="mb-6" 
                    :items="items"  
                    @nextPage="indexBranchBooking"
                />
            </template>
        </BranchesLayout>

        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Bookings Export"
            content="Export is being processed. Please check your registered email."
        />

        <LoadingModal
            :show="preCacheLoader && isNetworkAvailable"
            @cancel="preCacheLoader = false"
            @confirm="preCacheLoader = false"
            :restartLoader="preCacheLoader"
            title="Downloading Resources"
            content="Resources is being download for offline use. Please wait."
        />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import BranchesLayout from '../components/BranchesLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StatusPill from '@/components/StatusPill.vue';
import PagePagination from '@/components/PagePagination.vue'
import DatePicker from '@/components/inputs/DatePicker.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import LoadingModal from '@/components/modal/LoadingModal.vue';

import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref, computed, watch } from 'vue';
import branchBooking from "@/classes/BranchBooking";
import BookingService from '@/classes/BookingService';
import { paginatePage } from '@/helpers/PageHelper';
import throttle from "lodash/throttle";
import { useNetworkStore } from '@/store/network';
import AuthService from "@/classes/AuthService";

import { formateTimeSlot } from "@/helpers/TimeFormatter"

import { formatter } from '@/helpers/NumberHelper'

const router = useRouter();
const route = useRoute();

// Data
const loader      = ref(false);
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref(null);
const branchId    = ref(route.params.id)
const status    = ref(route.query.status)

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

// Query
const searchText = ref();
const filterDate = ref(null);
const filterCreatedDate = ref(null);
const log_counts = ref(0);
const bookingType = ref(null);
const goToPage   = ref();

const applyFilters = () => {
    indexBranchBooking(goToPage.value);
}

const searchData = (val: string) => {
    searchText.value = val
}

// Page Renderer Data
const items = ref([]);
const items_count = ref(0);


// Methods
const indexBranchBooking = (data: string) => {
    loader.value = true;

    // console.log(isNetworkAvailable.value)

    if(isNetworkAvailable.value) {
        console.log("Online - Fetching Bookings...")

        const goToPageValue    = paginatePage(data);
        const queryString      = searchText.value ? `&query=${searchText.value}` : 'query';
        const dateQuery        = filterDate.value ? `&filterDate=${filterDate.value}` : '';
        const dateCreatedQuery = filterCreatedDate.value ? `&filterCreatedDate=${filterCreatedDate.value}` : '';
        const branchId         = route.params.id ? `branchId=${route.params.id}` : '';
        const status           = route.query.status ? `status=${route.query.status}` : '';
        const type             = bookingType.value ? `bookingType=${bookingType.value}` : '';
        
        branchBooking.indexBranchBooking(`${branchId}&${goToPageValue}&${queryString}&${dateQuery}&${dateCreatedQuery}&${status}&${type}&requested_at=${new Date().toISOString()}`)
            .then((response: any) => {
                loader.value = false;
                items.value = response.data;
                items_count.value = response.data.total;

            })
            .catch((err) => {
                loader.value = false;
                showError.value = true;
                errorMsg.value = err.response.data.message;
                
                if(err.response.status == 401) {
                    window.location.replace('/login');
                }
            })
    } else {
        console.log("Offline - Fetching Bookings...")

        const goToPageValue   = paginatePage(data);
        const currentDate = new Date();
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() + 30);

        const dateQuery = `filterDate=${currentDate.toISOString().split("T")[0]},${date.toISOString().split("T")[0]}`;
        const branchId  = route.params.id ? `branchId=${route.params.id}` : '';
        
        branchBooking.indexBranchBooking(`${branchId}&${dateQuery}&${goToPageValue}&offline=true`)
            .then((response: any) => {
                loader.value = false;
                items.value = response.data;
                items_count.value = response.data.total;

            })
            .catch(() => {
                loader.value = false;
                showError.value = true;
                errorMsg.value = "Oppps... No available data was saved for offline use.";
            })
    }
        
}

const preCacheSelectedBooking = async (booking: any) => {
    try {
        // Precache Selected Booking
        if(booking.booking_type == 'Individual') {
            await BookingService.fetchIndividualBooking(booking.booking_id)
        } else {
            await BookingService.fetchCorporateBooking(booking.corporate_booking_id)
        }
    } catch(error: any){
        console.log(error.response.status)
    }
}

const preCacheSelectedBookingScheduleLogs = async (booking: any) => {
    try {
        // Precache Selected Booking Schedule Logs
        if(booking.booking_type == 'Individual') {
            await BookingService.fetchScheduleLogs(booking.booking_type, booking.booking_id)
        } else {
            await BookingService.fetchScheduleLogs(booking.booking_type, booking.corporate_booking_id)
        }
    } catch(error: any){
        console.log(error.response.status)
    }
}

const preCacheLoader = ref(false);
const isLastBookingIndexCached = ref(false);
const isLastSelectedBookingCached = ref(false);

const preCacheBookings = async () => {
    const currentDate = new Date();
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 30);
    
    const dateQuery       = `filterDate=${currentDate.toISOString().split("T")[0]},${date.toISOString().split("T")[0]}`;
    const branchId        = `branchId=${route.params.id}`;
    
    const cache = await caches.open('shinagawa-admin');
    const cachedResponse = await cache.match(`${process.env.VUE_APP_API_URL}branch-bookings/index?${AuthService.getHybrainTokenQueryString()}&${branchId}&${dateQuery}&offline=true`);

    if(!cachedResponse) {
        preCacheLoader.value = true;
        try {
            // Precache Bookings
            const response = await branchBooking.indexBranchBooking(`${branchId}&${dateQuery}&offline=true`)
            const total = response.data.total;
            const pages = Math.ceil(total / 10);
    
            for (let index = 0; index < pages; index++) {
                const page = index + 1;
                const res = await branchBooking.indexBranchBooking(`${branchId}&${dateQuery}&page=${page}&offline=true`)
    
                if(typeof res.data.data == 'object') {
                    let booking = null;
                    for (let i = 0; i < Object.values(res.data.data).length; i++) { 
                        booking = Object.values(res.data.data)[i];
                        preCacheSelectedBooking(booking);
                        preCacheSelectedBookingScheduleLogs(booking);
                        if (i === Object.values(res.data.data).length - 1) {
                            isLastSelectedBookingCached.value = true;
                            console.log("Last iteration for selected booking");
                        }
                    }
                } else {
                    let booking = null;
                    for (let i = 0; i < res.data.data.length; i++) {
                        booking = res.data.data[i];
                        preCacheSelectedBooking(booking);
                        preCacheSelectedBookingScheduleLogs(booking);

                        if (i === res.data.data.length - 1) {
                            isLastSelectedBookingCached.value = true;
                            console.log("Last iteration for selected patient");
                        }
                    }
                }

                if (index === pages - 1) {
                    isLastBookingIndexCached.value = true;
                    console.log("Last iteration for bookings index");
                }
            }

        } catch(error: any){
            console.log(error.response.status)
        }
        // preCacheLoader.value = false;
    } else {
        isLastBookingIndexCached.value = true;
        isLastSelectedBookingCached.value = true;
    }
}

// Tabs
const tabs: { name: string, count: number, allowed: boolean }[] = computed(() => [
    {
        name: 'All',
        count: !status.value ? items_count.value : 0,
        href: `/branches/${route.params.id}/bookings`,
        allowed: true
    },
    {
        name: 'Soft',
        count: status.value == 'Soft' ? items_count.value : 0,
        href: `/branches/${route.params.id}/bookings?status=Soft`,
        allowed: isNetworkAvailable.value
    },
    {
        name: 'Booked',
        count: status.value == 'Booked' ? items_count.value : 0,
        href: `/branches/${route.params.id}/bookings?status=Booked`,
        allowed: isNetworkAvailable.value
    },
    {
        name: 'Done',
        count: status.value == 'Done' ? items_count.value : 0,
        href: `/branches/${route.params.id}/bookings?status=Done`,
        allowed: isNetworkAvailable.value
    },
    {
        name: 'No Show/Cancelled',
        count: status.value == 'Cancelled/No-Show' ? items_count.value : 0,
        href: `/branches/${route.params.id}/bookings?status=Cancelled/No-Show`,
        allowed: isNetworkAvailable.value
    },
    {
        name: 'Activity logs',
        count: log_counts.value,
        href: "/branches/${route.params.id}/bookings/logs",
        allowed: true
    }
]);

// Headers
const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Patient' },
    { text: 'Patient Type' },
    { text: 'Booking Type' },
    { text: 'Branch' },
    { text: 'Company' },
    { text: 'Package' },
    // { text: 'Rider' },
    { text: 'Timeslot' },
    { text: 'Total Fee' },
    { text: 'Status' },
    { text: 'Booking Date' },
    { text: 'Created Date' },
];

const bookingTypes = [
    { id: '1', label: 'Individual', value: 'Individual' },
    { id: '2', label: 'Corporate', value: 'Corporate' },
];

/**
 * ********************************
 * Export Company 
 * ********************************
 */
 const exportData = async () => {
    try {
        loader.value = true;
        const dateQuery   = filterDate.value ? `&filterDate=${filterDate.value}` : '';
        const params      = `branchId=${branchId.value}&tab=all`;

        await BookingService.exportBooking(`${params}&${dateQuery}`);

        showSuccess.value = true;
    } catch (error) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

const getStatusColor = (status: string) => {
    if(status) {
        let color = null;
        switch (status) {
            case 'Booked':
                color = 'blue';
                break;
        
            case 'Cancelled/No-Show':
            case 'Cancelled':
                color = 'red';
                break;
        
            case 'Done':
                color = 'green';
                break;
            
            default:
                color = 'yellow'
                break;
        }

        return color;
    }

    return 'yellow';
}

const loadingInterval = () => {
    const checker = setInterval(() => {
        if(isLastBookingIndexCached.value && isLastSelectedBookingCached.value) {
            console.log("All resources has ben cached")
            setTimeout(() => {
                preCacheLoader.value = false;
            }, 3000);
            clearInterval(checker);
        } else {
            console.log("Caching... plase wait")
        }
    }, 1000)
}

const downloadResources = async () => {
    isLastBookingIndexCached.value = false;
    isLastSelectedBookingCached.value  = false;
    caches.delete('shinagawa-admin');

    // setTimeout(() => {
        preCacheBookings();
        loadingInterval();
    // }, 1000);
}

watch(
    searchText,
    throttle(() => {
        indexBranchBooking(goToPage.value)
    }, 1000)
);

watch(
    () => isNetworkAvailable.value,
    (val) => {
        console.log('Watcher - Network: ' + val);
        indexBranchBooking(goToPage.value);
    }
);

onMounted( () => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        indexBranchBooking(goToPage.value);
        preCacheBookings();
    }, 1000);
    
    // Set Internal for Resources Downloader
    loadingInterval();
});

</script>