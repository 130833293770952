<template>
    <admin-layout 
    title="Create Booking"
    show-back
    :backUrl="`/branches/${branchId}/blocked-dates`">
        <div class="p-7 pb-[200px]">
            <FormSection
                :branch-id="branchId"
            >
            </FormSection>
        </div>
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FormSection from './FormSection.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const branchId = route.params.id;
</script>