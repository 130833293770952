<template>
    <admin-layout 
    title="Create Role"
    show-back
    backUrl="/accounts/roles">
        <div>
            <TabComponent
                :tabs="tabs"
            >
            </TabComponent>
        </div>
        <div class="p-7">
            <FormSection>
                <ButtonComponent @click="submit">
                    Save Changes
                </ButtonComponent>
            </FormSection>
                
        </div>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

const tabs: { name: string, count: number, href?: string, allowed: boolean }[] = [
    {
        name: 'Information',
        count: 0,
        allowed: true
    },
    {
        name: 'Permissions',
        count: 0,
        href: '/accounts/roles/permission',
        allowed: true
    },
];
</script>