<template>
    <admin-layout title="Illnesses/Conditions">
        <div>
            <TabComponent
            :tabs="tabs"
            :button-items="true"
            >
                <template #buttons>
                    <ButtonComponent
                        class="text-gray-900"
                        secondary
                        exportBtn
                        @click="exportData"
                        :disabled="loader"
                    >
                        Export
                    </ButtonComponent>
                    <ButtonComponent
                        @click="openModal"
                        class="text-gray-900"
                        secondary
                        importBtn
                    >
                        Import
                    </ButtonComponent>
                    <ButtonComponent
                    v-if="$hasPermission('create-content')"
                    createBtn
                    @click="router.push(`/contents/conditions/create`)"> 
                        Create
                    </ButtonComponent>
                </template>
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6">
            <FilterComponent
                :search="searchText"
                @fetchData="searchData"
                :text-filters="currentRoutePath !== '/contents/conditions/logs'"
                @update:filters="applyFilters"
                :custom-filters="activeTab !== 'activity_logs'"
            >
                <template #fields>
                    <div class="space-y-6">
                        <DatePicker
                            id="filterDate"
                            name="filterDate"
                            range
                            is24
                            isPresetRanges
                            v-model="filterDate"
                            placeholder="Start Date & End Date"
                            label="Start Date & End Date"
                        />
                    </div>
                </template>
            </FilterComponent>
        </div>

        <div class="" v-if="currentRoutePath !== '/contents/conditions/logs'">
            <table v-if="items?.data" class="min-w-full divide-y divide-gray-200 table-striped">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col"
                            class="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ILLNESS/CONDITION
                        </th>
                        <th scope="col"
                            class="relative px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date Created
                        </th>
                        <th scope="col"
                            class="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                </thead>
                <draggable
                    v-model="items.data" 
                    tag="tbody"
                    item-key="name"
                    class="bg-white divide-y divide-gray-200"
                    @update:modelValue="reorder($event)"
                >
                    <template #item="{ element }">
                        <tr>
                            <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-8/12">
                                {{ element.name }}
                            </td>
                            <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                                {{ element.formatted_created_at }}
                            </td>
                            <td class="text-center">
                                <ActionButton
                                v-if="$hasPermission('update-content')"
                                edit-btn
                                @click="router.push(`/contents/conditions/edit/${element.id}`)"
                                />
                                <ActionButton
                                v-if="!element.deleted_at && $hasPermission('archive-content')"
                                delete-btn
                                @click="selectedCondition(element.id)"
                                />
                                <ActionButton
                                v-if="element.deleted_at && $hasPermission('restore-content')"
                                restore-btn
                                @click="restoreCondtion(element.id)"
                                />
                            </td>
                        </tr>
                    </template>
                </draggable>
                <template v-if="items?.data.length == 0">
                    <tr>
                        <td
                            class="whitespace-nowrap text-center"
                            :colspan="
                                headers.length + (!noAction ? 1 : 0)
                            "
                        >
                            <div class="text-sm text-gray-400 py-4">
                                No data to display.
                            </div>
                        </td>
                    </tr>
                </template>
            </table>

            <!-- <DataTable 
                :headers="headers" 
                :count="items?.data.length"
            >
                <template #body>
                    <tr v-for="(item, index) in items?.data" :key="index">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-8/12">
                            {{ item.name }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.formatted_created_at }}
                        </td>
                        <td class="text-center">
                            <ActionButton
                            v-if="$hasPermission('update-content')"
                            edit-btn
                            @click="router.push(`/contents/conditions/edit/${item.id}`)"
                            />
                            <ActionButton
                            v-if="!item.deleted_at && $hasPermission('archive-content')"
                            delete-btn
                            @click="selectedCondition(item.id)"
                            />
                            <ActionButton
                            v-if="item.deleted_at && $hasPermission('restore-content')"
                            restore-btn
                            @click="restoreCondtion(item.id)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable> -->
            
            <div class="ml-auto p-6 w-1/4" v-if="items?.data.length > 0">
                <MultiselectInput
                    id="records_per_page"
                    label="Records Per Page"
                    v-model="recordsPerPage"
                    placeholder="Records Per Page"
                    :options="records"
                />
            </div>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexIllnessCondition"
            />
        </div>
        <div v-else>
            <ActivityLogsTable
                :logs="logs"
                @fetch-index="indexIllnessCondition"
            />
        </div>
        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; archiveCondition()"
            cancel-text="Cancel"
            action-text="Confirm"
            title="Archive Illnesses/Conditions ?"
            content="Are you sure you wish to archive this Illness/Condition ?"
        />

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Illnesses/Conditions Export"
            content="Export is being processed. Please check your registered email."
        />

        <FileImportModal 
            title="Illnesses/Conditions"
            :dialog="dialog"
            :import-route="`illness-conditions/import`"
            @onClose="dialog = false"
            manifest-file-url="/assets/files/illnesses-conditions.xlsx"
        />   
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
// import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import FileImportModal from '@/components/modal/FileImportModal.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import ActivityLogsTable from '@/components/ActivityLogsTable.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';

import draggable from "vuedraggable";
import throttle from "lodash/throttle";
import IllnessCondition from "@/classes/IllnessCondition";

import { paginatePage } from '@/helpers/PageHelper';
import { useRouter } from 'vue-router';
import { onMounted, ref, watch, computed } from 'vue';

/**
 * ********************************
 * Data 
 * ********************************
 */
 const router           = useRouter();
 const loader           = ref(false);
 const showError        = ref(false);
 const errorMsg         = ref(null);
 const dialog           = ref(false)
 const seletected       = ref();
 const showConfirmation = ref(false);
 const showSuccess      = ref(false);
//  const illnessId        = ref(route.params.id)
 const mainRoute        = ref(`/contents/conditions`);
 const currentRoutePath = computed(() => router.currentRoute.value.path);

// Query
 const searchText = ref();
 const activeTab  = ref();
 const goToPage   = ref();
 const tab        = ref('all');

/**
 * ********************************
 * Page Renderer Data 
 * ********************************
 */
const items           = ref(null);
const items_count     = ref(null);
const rows            = ref(null);
const query           = ref(null);
const selected_tab    = ref('all');
const filterDate       = ref(null);
const log_event       = ref(null);
const log_query       = ref(null);
const log_date        = ref(null);
const active_count    = ref(null);
const archived_count  = ref(null);
const log_counts      = ref(null);
const logs            = ref(null);
const log_event_lists = ref(null);
// Tabs
const tabs: { name: string, count: number, allowed: boolean }[] = computed(() => [
    {
        name: 'Active',
        count: active_count.value,
        href: mainRoute.value,
        allowed: true
    },
    {
        name: 'Archived',
        count: archived_count.value,
        href: `${mainRoute.value}/archived`,
        allowed: true
    },
    {
        name: 'Activity Logs',
        count: log_counts.value,
        href: `${mainRoute.value}/logs`,
        allowed:
            window.$hasPermission('view-activity-logs')
    }
]);

// Headers
const headers: { text: string }[] = [
    { text: 'ILLNESS/CONDITION' },
    { text: 'Date Created'}
];

/**
 * ********************************
 * Methods
 * ********************************
 */
const indexIllnessCondition = async (data: string) => {
    loader.value = true;

    const queryString     = searchText.value ? `&query=${searchText.value}` : 'query';
    const dateQuery       = filterDate.value ? `&filterDate=${filterDate.value}` : '';
    const isArchivedRoute = currentRoutePath.value === `${mainRoute.value}/archived`;
    const tabValue        = isArchivedRoute ? 'archived' : tab.value;
    const goToPageValue   = paginatePage(data);
    const records  =    `&rows=${recordsPerPage.value}`;
    
    try  {
        const response = await IllnessCondition.indexIllnessCondition(`${goToPageValue}&tab=${tabValue}&${queryString}&${dateQuery}&${records}`)

        loader.value          = false;
        items.value           = response.data.items;
        items_count.value     = response.data.items_count
        rows.value            = response.data.rows
        query.value           = response.data.query
        selected_tab.value    = response.data.selected_tab
        filterDate.value       = response.data.filterDate
        log_event.value       = response.data.log_event
        log_query.value       = response.data.log_query
        log_date.value        = response.data.log_date
        active_count.value    = response.data.active_count
        archived_count.value  = response.data.archived_count
        log_counts.value      = response.data.log_counts
        logs.value            = response.data.logs
        log_event_lists.value = response.data.log_event_lists

    }catch(error) {
        loader.value = false;
        showError.value = true;
        errorMsg.value = error.response.data.message;
        
        if(error.response.status == 401) {
            window.location.replace('/login');
        }
    }
}

const applyFilters = () => {
    indexIllnessCondition(goToPage.value)
}

const searchData = (val: string) => {
    searchText.value = val
}

const openModal = () => {
    dialog.value = true;
}

const recordsPerPage = ref(10);
const records = ref([
    { id: '10', label: '10', value: '10' },
    { id: '25', label: '25', value: '25' },
    { id: '50', label: '50', value: '50' },
    { id: '100', label: '100', value: '100' },
    { id: '500', label: '500', value: '500' },
])

watch(
    () => recordsPerPage.value,
    () => {
        indexIllnessCondition(goToPage.value)
    }
);

/**
 * ********************************
 * Archive 
 * ********************************
 */
 const selectedCondition = (id: string) => {
    showConfirmation.value = true
    seletected.value = id
}

const archiveCondition = async () => {
    try {
        loader.value = true;
        await IllnessCondition.deleteIllnessCondition(seletected.value);
        loader.value = false;
        window.location.replace('/contents/conditions/archived');
    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data.message;
    }
};

/**
 * ********************************
 * Restore 
 * ********************************
 */
 const restoreCondtion = async (id: string) => {
    try {
        loader.value = true;
        await IllnessCondition.restoreIllnessCondition(id);
        loader.value = false;
        window.location.replace('/contents/conditions')
    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data.message;
    }
};

/**
 * ********************************
 * Export Data 
 * ********************************
 */
 const exportData = async () => {
    try {
        loader.value = true;
        const params = currentRoutePath.value === `${mainRoute.value}/archived` ? 'tab=archived' : '';

        await IllnessCondition.exportIllnessCondition(params);
        showSuccess.value = true;
    } catch (error) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

const reorder = (order) => {
    loader.value = true;

    IllnessCondition.reorderIllnessCondition(order)
        .then(() => {
            loader.value      = false;
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

watch(
      searchText,
      throttle(() => {
        indexIllnessCondition(goToPage.value)
      }, 1000)
    );


onMounted( () => indexIllnessCondition(goToPage.value));

</script>