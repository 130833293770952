<template>
    <admin-layout 
    title="View Role"
    show-back
    backUrl="/accounts/roles">
        <div>
            <TabComponent
                :tabs="tabs"
            >
            </TabComponent>
        </div>
        <div class="p-7 pb-0 border-b">
            <FormSection v-for="(permissionGroup, permissionGroupIndex) in permissionGroups" :key="permissionGroupIndex">
                <template #title>
                    {{ permissionGroup.module }}
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div v-for="(permission, permissionIndex) in permissionGroup.permissions" :key="permissionIndex" class="col-span-12">
                        <DetailSwitch 
                        :title="permission.label"
                        :description="permission.description"
                        :id="permission.name"
                        :name="permission.name"
                        :checked="savedPermissions[permission.id]" 
                        disabled/>
                    </div>
                </template>
            </FormSection>
        </div>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from '@/components/FormSection.vue';
import DetailSwitch from '@/components/inputs/DetailSwitch.vue';
import { ref, onMounted } from 'vue';
import RolePermissionService from '@/classes/RolePermissionService';
import { useRoute } from 'vue-router';

const route = useRoute();

const loader    = ref(false)
const showError = ref(false)
const errorMsg  = ref(null)

const roleId           = route.params.roleId as unknown as string;
const savedPermissions = ref({});
const permissionGroups = ref({});

const tabs: { name: string, count: number, href?: string, allowed: boolean }[] = [
    {
        name : 'Information',
        count: 0,
        href : `/accounts/roles/show/${roleId}`,
        allowed: true
    },
    {
        name : 'Permissions',
        count: 0,
        href : '',
        allowed: true
    },
];

const fetchPermissions = () => {
    loader.value = true;

    RolePermissionService.showPermissions(roleId)
        .then((result: any) => {
            loader.value           = false;
            savedPermissions.value = result.data.savedPermissions;
            permissionGroups.value = result.data.permissionGroups;
        })
        .catch((error: any) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted(() => { fetchPermissions() })
</script>