<template>
    <div class="">
        <DataTable 
        :headers="headers" 
        :count="items.length"
        no-action
        >
            <template #body>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                        {{ item.rescheduled_booking_id }}
                    </td>
                    <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                        {{ formatRescheduledDateText(item) }}
                    </td>
                    <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                        {{ item.description }}
                    </td>
                </tr>
            </template>
        </DataTable>    
    </div>

    <page-pagination class="mb-6" 
        :items="items"  
        @nextPage="fetchScheduleLogs"
    />

    <PageLoader v-if="loader" />

    <ErrorModal
    width="w-[548px]"
    title="Error"
    action-text="Close"
    cancel-text="Go back"
    :show="showError"
    @close="showError = false"
    @confirm="showError = false"
    :errors="errors"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

</template>

<script setup lang="ts">
import DataTable from '@/components/DataTable.vue';
import PagePagination from '@/components/PagePagination.vue'

import { ref, onMounted } from 'vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import BookingService from '@/classes/BookingService';
import { format } from 'date-fns';

import { useRoute } from 'vue-router';
import { onIonViewWillEnter } from '@ionic/vue';

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])

const props = defineProps ({
    bookingId: {
        type: String,
        default: null
    }
});

const route = useRoute();

const booking_type = ref(route.query.booking_type);
const items = ref([]);

const fetchScheduleLogs = () => {
    loader.value = true

    BookingService.fetchScheduleLogs(booking_type.value, props.bookingId)
        .then((result: any) => {
            loader.value = false;
            
            if(result.data.result_status == 200 && result.data.message == 'Success') {
                items.value = result.data.response;
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }

        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const formatDate = (date: string | Date, longFormat = true) => {
    if(date) {
        if(longFormat) {
            return format(new Date(date), 'LLLL dd, yyyy');
        } else {
            return format(new Date(date), 'LLL dd, yyyy');
        }
    }
    return 'TBA';
}

const formatTime = (time : string) => {
    return time ? format(new Date(time), 'h:mm a') : 'TBA';
}

const formatRescheduledDateText = (scheduleLog: any) => {
    let textDisplay = "N/A";
    
    if(scheduleLog.new_booking_start_time && scheduleLog.new_booking_end_time) {
        textDisplay = `This appointment has been rescheduled to ${formatDate(scheduleLog.new_booking_date)} at ${formatTime(scheduleLog.new_booking_start_time)} - ${formatTime(scheduleLog.new_booking_end_time)} by the Admin.`;
    } else {
        textDisplay = `This appointment has been rescheduled to ${formatDate(scheduleLog.new_booking_date)} by the Admin.`;
    }

    return textDisplay;
}

// Headers
const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Description' },
    { text: 'Reason' },
];

onMounted(() => {
    loader.value = true;
    fetchScheduleLogs()
})

onIonViewWillEnter(() => {
    loader.value = true;
    fetchScheduleLogs();
})

</script>