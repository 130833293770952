<template>
    <admin-layout title="Roles">
        <div>
            <TabComponent
                :tabs="tabs"
                :button-items="true"
            ></TabComponent>
        </div>        

        <div class="">
            <DataTable 
            :headers="headers" 
            :count="items?.data.length">
                <template #body>
                    <tr v-for="(item, index) in items?.data" :key="index">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-9/12">
                            {{ item.name }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.created_at }}
                        </td>
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push(`/accounts/roles/show/${item.id}`)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexRoles"
            />
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';
import { paginatePage } from '@/helpers/PageHelper';
import RolePermissionService from '@/classes/RolePermissionService';

import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';

const router = useRouter();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

// const activeTab  = ref();
const goToPage   = ref();

// Page Renderer Data
const items           = ref(null);
const items_count     = ref(null);
const rows            = ref(null);
const query           = ref(null);
const filterDate       = ref(null);
const active_count    = ref(0);


const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Active',
        href: '/accounts/roles',
        count: active_count.value,
        allowed: true
    }
];

const headers: { text: string }[] = [
    { text: 'Roles' },
    { text: 'Date Created'}
];

// Methods
const indexRoles = (data: string) => {
    loader.value = true;

    goToPage.value = paginatePage(data)

    RolePermissionService.indexRole(goToPage.value)
        .then((response: any) => {
            loader.value          = false;
            items.value           = response.data.items;
            items_count.value     = response.data.items_count
            rows.value            = response.data.rows
            query.value           = response.data.query
            filterDate.value       = response.data.filterDate
            active_count.value    = response.data.active_count
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

// const sampleData: { id: number, role: string, dateCreated: string }[] = [
//     {
//         id: 1,
//         role: 'Super Admin',
//         dateCreated: 'Jan 5, 2023 (00:00:00)',
//     },
//     {
//         id: 2,
//         role: 'Contact Center Admin',
//         dateCreated: 'Jan 5, 2023 (00:00:00)',
//     },
//     {
//         id: 3,
//         role: 'Web Booking Admin',
//         dateCreated: 'Jan 5, 2023 (00:00:00)',
//     },
//     {
//         id: 4,
//         role: 'Corporate Account Admin',
//         dateCreated: 'Jan 5, 2023 (00:00:00)',
//     },
//     {
//         id: 5,
//         role: 'CMS Admin',
//         dateCreated: 'Jan 5, 2023 (00:00:00)',
//     },
// ];

onMounted(() => { indexRoles(goToPage.value) })
</script>