<template>
    <admin-layout 
        title="View Admin"
        show-back
        backUrl="/accounts/admins"
    >
        <div>
            <TabComponent :tabs="tabs" />
        </div>
        <div class="p-7">
            <FormSection 
                :form="form" 
                :errors="errors"
            >
                <ButtonComponent 
                    v-if="getLoggedAdmin.id != adminId && ($hasPermission('update-admin-account') || $hasPermission('register-for-an-account'))"
                    @click="submit"
                    :disabled="loader"
                >
                    Save Changes
                </ButtonComponent>
            </FormSection>
        </div>
       
        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Admin"
            content="Admin Updated Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import PageLoader from '@/components/PageLoader.vue';
import AdminData from '@/classes/AdminData';
import AuthService from '@/classes/AuthService';

import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const getLoggedAdmin = computed(() => AdminData.getAdmin()) 

// DATA
const loader      = ref(false)
const show        = ref(false);
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref();
const adminId     = ref(route.params.id as unknown as string);

const errors = ref([]);
const form = ref({
    first_name : null,
    last_name : null,
    email     : null,
    role_id   : null,
})

const tabs: { name: string, count: number, href: string, allowed: true }[] = [
    {
        name: 'Information',
        count: 0,
        href: `/accounts/admins/show/${adminId.value}`,
        allowed: true
    },
];


const submit = async () => {
  loader.value = true;

  try {
    await AdminData.updateAdmin(adminId.value, form.value);
    loader.value      = false;
    show.value        = false;
    showSuccess.value = true;

    setTimeout(() => {
      window.location.replace('/accounts/admins');
    }, 2000);

  } catch (error: any) {
    loader.value    = false;
    show.value      = false;
    showError.value = true;
    errorMsg.value  = error.response?.data?.message;

    if (error.response?.status === 422) {
      errors.value = error.response.data.errors;
    }
    errorResponse(error);
  }
};

const errorResponse = (err: any) => {
    loader.value = false

    if(err?.response.status == 401) {
        AuthService.redirectToLogin()
    }
}

onMounted(async () => {
    loader.value = true;

    try {
        const response = await AdminData.editAdmin(adminId.value);
        Object.assign(form.value, response.data.admin);
    } catch (err: any) {
        showError.value = true;
        errorMsg.value = err.response.data.message;
    } finally {
        loader.value = false;
    }
});

</script>