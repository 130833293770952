<template>
    <admin-layout 
    title="Number of Total Members"
    show-back
    backUrl="/reports">
        <div>
            <TabComponent
            :button-items="true"
            >
                <template #buttons>
                    <div class="py-3">
                        <ButtonComponent
                        @click="exportTotalMembers()"
                        class="text-gray-900"
                        secondary
                        exportBtn>
                            Export
                        </ButtonComponent>
                    </div>
                </template>
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6">
            <FilterComponent
                :text-filters="false"
                @update:filters="applyFilters"
            >
            <template #fields>
                <div class="space-y-6">
                    <DatePicker
                        id="dateRange"
                        name="dateRange"
                        range
                        is24
                        isPresetRanges
                        v-model="dateRange"
                        placeholder="Start Date & End Date"
                        label="Start Date & End Date"
                    />
                </div>
            </template>
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
            :headers="headers" 
            :noAction="true"
            :count="items?.total">
                <template #body>
                    <tr v-for="(totalMember, key) in items?.data" :key="key">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-8/12">
                            {{ totalMember.first_name + ' ' + totalMember.last_name }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ totalMember.hospital_no }}
                        </td>
                        <!-- <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            Jan 19, 2023 (00:00:00)
                        </td> -->
                    </tr>
                </template>
            </DataTable>
            <PagePagination class="mb-6" 
                :items="items"  
                @nextPage="fetchTotalMembers"
            />
        </div>

        <PageLoader v-if="loader" />

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Total Members Export"
            content="Export is being processed. Please check your registered email."
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import { ref, onMounted } from 'vue';
import { paginatePage } from '@/helpers/PageHelper';
import ReportService from '@/classes/ReportService';
import { startOfYear, endOfYear } from 'date-fns';

interface totalMember {
    first_name: string,
    last_name: string,
    hospital_no: string,
}

interface Item {
    data: totalMember[]
    total: number
}

const loader      = ref(false);
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref();
const goToPage    = ref();

const items = ref<Item>({
    data: [],
    total: 0
});
const dateRange = ref([
    startOfYear(new Date).toISOString(),
    endOfYear(new Date).toISOString(),
]);

const headers: { text: string }[] = [
    { text: 'Patient Name' },
    { text: 'Hospital Number' }
];

const applyFilters = () => {
    fetchTotalMembers(goToPage.value);
}

const fetchTotalMembers = async (data: string) => {
    try {
        loader.value = true;

        const startDate = dateRange.value ? `&startDate=${dateRange.value[0]}` : '';
        const endDate   = dateRange.value ? `&endDate=${dateRange.value[1]}` : '';
        
        goToPage.value = paginatePage(data)

        const response   = await ReportService.fetchTotalMembers(`${goToPage.value}${startDate}${endDate}`);
        loader.value = false;
        
        items.value = response.data;
    } catch (error: any) {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
    }
    finally{
        loader.value = false
    }
}

const exportTotalMembers = async () => {
    try {
        loader.value = true;
        const startDate = dateRange.value ? `?startDate=${dateRange.value[0]}` : '';
        const endDate   = dateRange.value ? `&endDate=${dateRange.value[1]}` : '';

        await ReportService.exportTotalMembers(`${startDate}${endDate}`);

        showSuccess.value = true;
    } catch (error: any) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

onMounted(() => { fetchTotalMembers(goToPage.value); })
</script>