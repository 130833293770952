<template>
    <admin-layout 
    title="Create Booking"
    show-back
    :backUrl="`/branches/${branchId}/bookings`">
        <div class="p-7 pb-[200px]">
            <FormSection
                :branch-id="branchId"
            >
                <!-- <ButtonComponent @click="submit">
                    Submit
                </ButtonComponent> -->
            </FormSection>
        </div>
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FormSection from './FormSection.vue';
import { useRoute } from 'vue-router';

// import ButtonComponent from '@/components/ButtonComponent.vue';
// import { useRouter } from 'vue-router';
// import { ref } from 'vue';

// const router = useRouter();

const route = useRoute();

const branchId = route.params.id;
</script>