<template>
    <admin-layout title="Bookings">
        <div class="px-6 py-3">
            <FilterComponent
                :search="searchText"
                @fetchData="searchData"
                @update:filters="indexBookings"
                :modelValue="searchText"
                :sidebarFilters="false"
                :custom-filters="activeTab !== 'activity_logs'"
            >
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
                :headers="headers" 
                :count="items?.total"
            >
                <template #body>
                    <tr v-for="item in items?.data" :key="item.id">
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.booking_type != 'Soft' ? item.booking_id : item.id }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.patient?.first_name }} {{ item.patient?.last_name  }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.booking_type == 'Individual' || item.booking_type == 'Soft' ? item.booking_type : 'Corporate' }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            New
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            Bonifacio Global City
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            <template v-if="item.corporate_name">
                                {{ item.corporate_name }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.package_name }}
                        </td>
                        <!-- <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            Chest X-ray
                        </td> -->
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ formateTimeSlot(item.booking_start_time, item.booking_end_time) }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ formatter.format(item.total_fee) }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            <StatusPill
                            :color="getStatusColor(item.status)"
                            :text="item.status"/>
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ new Date(item.booking_date).toISOString().split('T')[0] }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ new Date(item.booking_created_date ? item.booking_created_date : item.created_at).toISOString().split('T')[0] }}
                        </td>
                        <td class="text-center">
                            <ActionButton
                            v-if="
                                $hasPermission('set-individual-patient-appointment-time') ||
                                $hasPermission('update-individual-patient-appointment-time') ||
                                $hasPermission('soft-book-dates-for-individual-patient') ||
                                $hasPermission('update-corporate-patient') ||
                                $hasPermission('update-participating-employees')"
                            edit-btn
                            @click="router.push(`/branches/${item.branch.branch_id}/bookings/edit/${item.booking_type == 'Individual' ? item.booking_id : item.booking_type == 'Corporate' ? item.corporate_booking_id : item.id}?booking_type=${ item.booking_type == 'Individual' || item.booking_type == 'Soft' ? item.booking_type : 'Corporate' }`)"/>
                        </td>
                    </tr>
                </template>
            </DataTable>

            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="applyFilters"
            />
        </div>
        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import PageLoader from '@/components/PageLoader.vue';
import PagePagination from '@/components/PagePagination.vue';
import StatusPill from '@/components/StatusPill.vue';

import throttle from "lodash/throttle";

import { paginatePage } from '@/helpers/PageHelper';

import Dashboard from "@/classes/Dashboard";
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import { format } from 'date-fns'
import { formatter } from '@/helpers/NumberHelper'
import { formateTimeSlot } from "@/helpers/TimeFormatter"

/**
 * ********************************
 * Data 
 * ********************************
 */
const router    = useRouter();
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);
const route = useRoute();
/**
 * ********************************
 * query 
 * ********************************
 */
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();
const pageNum   = ref<number>(1);
const date   = ref(route.params.date);

// Page Renderer Data
const items       = ref({
    totol: 0,
    data: []
});

const items_count = ref(0);
const query       = ref(null);

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'All',
        count: items_count.value,
        href: '/contents/packages',
        allowed: true
    }
];

// Headers
const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Patient' },
    { text: 'Patient Type' },
    { text: 'Booking Type' },
    { text: 'Branch' },
    { text: 'Company' },
    { text: 'Package' },
    // { text: 'Rider' },
    { text: 'Timeslot' },
    { text: 'Total Fee' },
    { text: 'Status' },
    { text: 'Booking Date' },
    { text: 'Created Date' },
];

const bookingTypes = [
    { id: '1', label: 'Individual', value: 'Individual' },
    { id: '2', label: 'Corporate', value: 'Corporate' },
];

/**
 * ********************************
 * Methods 
 * ********************************
 */
const searchData = (val: string) => {
    searchText.value = val
}

const applyFilters = (link:string) => {    
    if(link){
        pageNum.value = Number(link.replace('/?page=', ''))
    } else{
        pageNum.value = 1;
    }

    indexBookings();
}

const indexBookings = async (data:string|null = null) => {
    try {
        loader.value = true;
        
        const queryString = searchText.value ? `&keyword=${searchText.value}` : '';
        const goToPage    = `page=${pageNum.value}`;

        const response = await Dashboard.fetchDateBookings(goToPage + queryString + `&booking_date=${date.value}`);

        loader.value      = false;
        items.value       = response.data;
        items_count.value = items.value.total;
        
        query.value       = response.data.query;

    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response?.data?.message ?? 'An error occurred';
    }
};


const getStatusColor = (status: string) => {
    if(status) {
        let color = null;
        switch (status) {
            case 'Booked':
                color = 'blue';
                break;
        
            case 'Cancelled/No-Show':
            case 'Cancelled':
                color = 'red';
                break;
        
            case 'Done':
                color = 'green';
                break;
            
            default:
                color = 'yellow'
                break;
        }

        return color;
    }

    return 'yellow';
}

/**
 * ********************************
 * Wathchers 
 * ********************************
 */
watch(
      searchText,
      throttle(() => {
        indexBookings(goToPage.value)
      }, 1000)
    );

onMounted(() => indexBookings(goToPage.value))

</script>