<template>
    <admin-layout title="Dashboard">
       
        <div class="p-7">
            <div class="flex mb-4 space-x-3 latest_bookings-center">
                <DatePicker 
                    range
                    is24
                    isPresetRanges
                    v-model="filterCreatedDate"
                    id="create_date"
                    name="create_date"
                    placeholder="Created Start and End Date"
                    label="Created Start and End Date"
                />
                <DatePicker 
                    range
                    is24
                    isPresetRanges
                    v-model="filterDate"
                    id="booking_date"
                    name="booking_date"
                    placeholder="Booking Start and End Date"
                    label="Booking Start and End Date"
                />
                <ButtonComponent
                class="mt-auto"
                type="button"
                @click="applyFilter">
                    Apply Filter
                </ButtonComponent>
            </div>
            <div class="grid grid-cols-3 mb-6 gap-y-6 gap-x-4">
                <div class="px-4 py-6 border rounded-lg">
                    <img class="mb-4" src="/assets/icons/nav/ic-people.svg" alt="">
                    <p class="mb-2 text-sm text-gray-500">Total Users</p>
                    <p class="text-[#23A2B4] text-3xl font-bold">{{ total_users }}</p>
                </div>
                <div class="px-4 py-6 border rounded-lg">
                    <img class="mb-4" src="/assets/icons/nav/ic-people.svg" alt="">
                    <p class="mb-2 text-sm text-gray-500">New Users</p>
                    <p class="text-[#23A2B4] text-3xl font-bold">{{ new_users }}</p>
                </div>
                <div class="px-4 py-6 border rounded-lg">
                    <img class="mb-4" src="/assets/icons/nav/ic-note.svg" alt="">
                    <p class="mb-2 text-sm text-gray-500">Bookings</p>
                    <p class="text-[#23A2B4] text-3xl font-bold">{{ latest_bookings_count }}</p>
                </div>
            </div>
            <div class="w-full py-6 mt-5">
                <p class="mb-2 text-lg text-gray-500">Latest Bookings</p>
                <DataTable 
                :headers="headers" 
                :count="latest_bookings_count">
                    <template #body>
                        <tr v-for="(item, index) in latest_bookings.data" :key="index">
                            <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                {{ index + 1 }}
                            </td>
                            <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                <template v-if="item.booking_type == 'Individual'">
                                    {{ item.patient?.first_name }} {{ item.patient?.last_name  }}
                                </template>
                                <template v-else>
                                    {{ item.corporate_name }}
                                </template>
                            </td>
                            <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                {{ item.booking_type }}
                            </td>
                            <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                <StatusPill
                                :color="getStatusColor(item.status)"
                                :text="item.status"/>
                            </td>
                            <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                {{ new Date(item.booking_date).toISOString().split('T')[0] }}
                            </td>
                            <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                                {{ new Date(item.booking_created_date).toISOString().split('T')[0] }}
                            </td>
                            <td class="text-center">
                                <!-- <ActionButton 
                                edit-btn
                                @click="router.push(`branches/${item.branch.branch_id}/bookings/edit/${item.booking_id}?booking_type=${item.booking_type}`)" -->
                                <!-- /> -->

                                <ActionButton
                                    v-if="$hasPermission('set-individual-patient-appointment-time') ||
                                        $hasPermission('update-individual-patient-appointment-time') ||
                                        $hasPermission('soft-book-dates-for-individual-patient') ||
                                        $hasPermission('update-corporate-patient') ||
                                        $hasPermission('update-participating-employees')"
                                edit-btn
                                @click="router.push(`/branches/${item.branch.branch_id}/bookings/edit/${item.booking_type == 'Individual' ? item.booking_id : item.booking_type == 'Corporate' ? item.corporate_booking_id : item.id}?booking_type=${item.booking_type == 'Individual' || item.booking_type == 'Soft' ? item.booking_type : 'Corporate'}`)"/>
                            </td>
                        </tr>
                    </template>
                </DataTable>
            </div>

            <page-pagination class="mb-6" 
                :items="latest_bookings"  
                @nextPage="fetchLatestBookings"
            />

            <div class="w-full py-6 mt-5">
                <p class="mb-2 text-lg text-gray-500">Booking Calendar</p>
                <CalendarPicker
                    :markers="formatted_booking_calendar"
                    :fully-booked-dates="fully_booked_dates"
                    highlight-disabled-days
                    @updateMonth="updateMonth"
                    @dateUpdate="viewCalendarBookings"
                />
            </div>
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import StatusPill from '@/components/StatusPill.vue';
import CalendarPicker from '@/components/CalendarPicker.vue';
import PagePagination from '@/components/PagePagination.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import ButtonComponent from "@/components/ButtonComponent.vue";

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import Dashboard from "@/classes/Dashboard";
import { paginatePage } from '@/helpers/PageHelper';
import { format } from 'date-fns'

const router = useRouter();

// Data
const loader = ref(false);
const showError = ref(false);
const errorMsg = ref(null);

// Query
const filterDate = ref(null);
const filterCreatedDate = ref(null);
const goToPage   = ref();

// const booking_start_date = ref(null);
// const booking_end_date = ref(null);

// Page Renderer Data
const total_users = ref(0);
const new_users = ref(0);
const latest_bookings = ref([]);
const latest_bookings_count = ref(0);
const booking_calendar = ref([]);
const fully_booked_dates = ref([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const from = ref(null);
const to = ref(null);

const formatted_booking_calendar = ref([]);

// Methods
const fetchTotalUsers = () => {
    loader.value = true;

    const dateCreatedQuery = filterCreatedDate.value ? `&filterCreatedDate=${filterCreatedDate.value}` : '';

    Dashboard.fetchTotalUsers(`${dateCreatedQuery}`)
        .then((response: any) => {
            loader.value = false;
            total_users.value = response.data.total_users.response.total_patients;
            new_users.value = response.data.total_users.response.new_patients;

        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const fetchLatestBookings = (data: string) => {
    loader.value = true;

    const goToPageValue   = paginatePage(data);
    const dateQuery       = filterDate.value ? `&filterDate=${filterDate.value}` : '';
    const dateCreatedQuery = filterCreatedDate.value ? `&filterCreatedDate=${filterCreatedDate.value}` : '';

    Dashboard.fetchLatestBookings(`${goToPageValue}&${dateQuery}&${dateCreatedQuery}`)
        .then((response: any) => {
            loader.value = false;
            latest_bookings.value = response.data.latest_bookings;
            latest_bookings_count.value = response.data.total_bookings;

        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const fetchBookingCalendar = () => {
    loader.value = true;

    const payload = {
        booking_start_date: from.value,
        booking_end_date: to.value,
    }

    Dashboard.fetchBookingCalendar(payload)
        .then((response: any) => {
            loader.value = false;
            booking_calendar.value = response.data.booking_calendar.response;
            fully_booked_dates.value = response.data.fully_booked_dates

            formatBookingCalendar()
        })
        .catch((err) => {
            console.log(err);
            // loader.value = false;
            // showError.value = true;
            // errorMsg.value = err.response.data.message;
            
            // if(err.response.status == 401) {
            //     window.location.replace('/login');
            // }
        })
}

const formatBookingCalendar = () => {
    
    booking_calendar.value.forEach((booking:any) => {
        const marker:{
            date: string,
            type: string,
            tooltip: [{
                text: string
            }],
        } = {
            date: '',
            type: 'dot',
            tooltip: [{
                text: ''
            }], //[{ text: 'Dot with tooltip' }],
        }
        if(
            booking.confirmed_appointments_count > 0 ||
            booking.cancelled_appointments_count > 0 ||
            booking.completed_appointments_count > 0 
        ){     
            const options:any = { timeZone: 'Asia/Manila', year: 'numeric', month: '2-digit', day: '2-digit' };
            const convertedDate = new Date(booking.appointment_day).toLocaleDateString('en-US', options);
            const [month, day, year] = convertedDate.split('/');
            
            marker.date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            marker.tooltip = [{
                text: `Confirmed: ${booking.confirmed_appointments_count} 
                    Cancelled: ${booking.cancelled_appointments_count}, 
                    Completed:${booking.completed_appointments_count}`
            }];
    
            formatted_booking_calendar.value.push(marker) 
        }
    });
}

const viewCalendarBookings = (date:string) => {
    date = format(new Date(date), 'yyyy-MM-dd')
    console.log(date);
    router.push(`/home/booking/${date}`)
}

const updateMonth = (val = null) => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch Monthly Data
    fetchBookingCalendar();
}

const firstDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

const applyFilter = () => {
    fetchTotalUsers();
    fetchLatestBookings(goToPage.value);
}

// Headers
const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Patient/Company' },
    { text: 'Booking Type' },
    { text: 'Status' },
    { text: 'Booking Date' },
    { text: 'Date Created'}
];

const getStatusColor = (status: string) => {
    if(status) {
        let color = null;
        switch (status) {
            case 'Booked':
                color = 'blue';
                break;
        
            case 'Cancelled/No-Show':
            case 'Cancelled':
                color = 'red';
                break;
        
            case 'Done':
                color = 'green';
                break;
            
            default:
                color = 'yellow'
                break;
        }

        return color;
    }

    return 'yellow';
}

onMounted( () => {
    fetchTotalUsers();
    fetchLatestBookings(goToPage.value);
    updateMonth();
});

</script>