<template>
    <admin-layout 
    title="Edit Booking"
    show-back
    :backUrl="`/branches/${branchId}/blocked-dates`">
        <div>
            <TabComponent
                :tabs="tabs"
            ></TabComponent>
        </div>
        <div class="p-7">
            <EditFormSection
                :branch-id="branchId"
                :blocked-date-id="blockedDateId"
            ></EditFormSection>
        </div>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import EditFormSection from './EditFormSection.vue';
import TabComponent from '@/components/TabComponent.vue';

import { useRoute } from 'vue-router';

const route = useRoute();

const branchId = route.params.id as unknown as string;
const blockedDateId = route.params.blockedDateId as unknown as string;

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        href: `/branches/${branchId}/blocked-dates/edit/${blockedDateId}`,
        count: 0,
        allowed: true
    },
];

</script>