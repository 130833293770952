<template>
    <TransitionRoot appear :show="show" as="template">
        <Dialog as="div" @close="$emit('close')" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                        :class="width"
                        class="transform overflow-hidden rounded-lg bg-white py-6 px-10 min-w-[480px] text-center align-middle transition-all"
                        >
                            <div class="w-12 h-12 mx-auto mb-6 rounded-full bg-gray-200 flex justify-center items-center flex-shrink-0">
                                <!-- <CheckIcon class="w-5 h-5 text-gray-900 mx-auto"/> -->
                                <ion-spinner name="circles"></ion-spinner>
                            </div>
                            <DialogTitle
                                as="p"
                                class="text-xl font-bold text-gray-900"
                            >
                                {{ title }}
                            </DialogTitle>
                            <div class="text-gray-500 leading-7">
                                <p v-if="content">{{ content }}</p>
                                <slot name="content" />
                            </div>

                            <div class="mt-6">
                                <ion-progress-bar :buffer="buffer" :value="progress"></ion-progress-bar>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
// import ButtonComponent from '@/components/ButtonComponent.vue';
// import { CheckIcon } from '@heroicons/vue/24/outline'
import { IonSpinner } from '@ionic/vue';
import { IonProgressBar } from '@ionic/vue';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    show: {
        type: Boolean
    },
    title: {
        type: String,
    },
    content: {
        type: String,
    },
    actionText: {
        type: String,
    },
    cancelText: {
        type: String,
    },
    width: {
        type: String,
    },
    checkIcon: {
        type: Boolean,
        default: false
    },
    restartLoader: {
        type: Boolean,
        default: false
    }
})

const buffer = ref(0.06);
const progress = ref(0);

const loadingInterval = () => {
    const checker = setInterval(() => {
        buffer.value += 0.06;
        progress.value += 0.06;

        // Stop the internal if reach 100%
        if (progress.value > 1) {
          setTimeout(() => {
            clearInterval(checker);
          }, 1000);
        }
    }, 1000);
}

watch(() => props.restartLoader, () => {
    // Restart Loader
    buffer.value = 0.06;
    progress.value = 0;
    loadingInterval();
})

onMounted(() => {
    loadingInterval()
})
</script>