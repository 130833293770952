<template>
    <div>

        <TabComponent
            :tabs="tabs"
        >
        </TabComponent>

        <!-- Content -->
		<slot name="contents" />
    </div>
</template>

<script setup lang="ts">
import TabComponent from '@/components/TabComponent.vue';

const props = defineProps({
    patientId: {
        type: String || Number,
        required: true
    }
})

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        count: 0,
        href: '/accounts/patients/show/' + props.patientId,
        allowed: true
    },
    {
        name: 'Bookings',
        count: 0,
        href: '/accounts/patients/bookings/' + props.patientId,
        allowed: true
    },
    {
        name: 'Medical Records',
        count: 0,
        href: '/accounts/patients/medical-record/show/' + props.patientId,
        allowed: 
            window.$hasPermission('view-corporate-patient-medical-records') ||
            window.$hasPermission('view-web-booking-medical-records') ||
            window.$hasPermission('view-contact-center-booking-medical-records') ||
            window.$hasPermission('view-patient-medical-records') ||
            window.$hasPermission('view-medical-records-in-offline')
    },
    // {
    //     name: 'Blocked Dates',
    //     count: 0,
    //     href: ''
    // }
];

</script>