<template>
    <admin-layout 
    title="Edit Booking"
    show-back
    :backUrl="`/branches/${branch_id}/bookings`">
        <div>
            <TabComponent
                :tabs="tabs"
                :button-items="true"
            >
                <template #buttons v-if="isNetworkAvailable">
                    <MenuComponent
                    v-if="booking_type != 'Soft' && ($hasPermission('update-individual-patient-booking-status') || $hasPermission('update-company-booking-status')) && !schedule_logs"
                    :menu="menu"
                    @click-menu="(name) => { 
                        name == 'Mark as Cancelled/No Show' 
                        ? cancelBooking = true 
                        : name == 'Reschedule'
                        ? rescheduleAppointment = true
                        : noteAndResult = true
                    }">
                    </MenuComponent>
                </template>
            </TabComponent>
        </div>
        <div class="p-7">
            <template v-if="!schedule_logs">
                <template v-if="booking_type == 'Individual' || booking_type == 'individual'">
                    <EditFormSection
                        @fetched-item="fetchedItem"
                        :isNetworkAvailable="isNetworkAvailable"
                    ></EditFormSection>
                </template>
                <template v-if="booking_type == 'Corporate' || booking_type == 'corporate'">
                    <CorporateEditFormSection
                        @fetched-item="fetchedItem"
                        :isNetworkAvailable="isNetworkAvailable"
                    ></CorporateEditFormSection>
                </template>
                <template v-if="booking_type == 'Soft' || booking_type == 'soft'">
                    <EditSoftFormSection
                        :isNetworkAvailable="isNetworkAvailable"
                    ></EditSoftFormSection>
                </template>
            </template>

            <template v-if="schedule_logs">
                <ScheduleLogs
                    :booking-id="booking_id"
                ></ScheduleLogs>
            </template>
        </div>
        
        <RescheduleModal
            v-if="rescheduleAppointment"
            :show="rescheduleAppointment"
            :bookingId="booking_id"
            :branchId="branch_id"
            :bookingDate="''"
            :bookingType="booking_type"
            :package="package_name"
            :riders="riders"
            @close="rescheduleAppointment = false"
            @success="success"
        />

        <ConfirmationModal
            :show="cancelBooking"
            @cancel="cancelBooking = false"
            @confirm="submitCancelBooking"
            cancel-text="Cancel"
            action-text="Submit"
            title="Cancel/No Show Booking?"
            content="Are you sure you wish to mark this booking as cancelled/no show?">
                <template #fields>
                    <p class="text-left font-bold text-xs -mt-2 mb-4 text-red-600">WARNING: Canceling/Rescheduling a Company Booking will affect <br/> all bookings associated with it.</p>
                    <div class="grid grid-cols-2 gap-5 text-left">
                        <div class="col-span-2">
                            <TextInput 
                            v-model="cancelPayload.remarks"
                            label="Reason"
                            placeholder="Reason"/>
                        </div>
                    </div>
                </template>
        </ConfirmationModal>

        <ConfirmationModal
        :show="noteAndResult"
        @cancel="noteAndResult = false"
        @confirm="uploadNoteAndResult"
        cancel-text="Cancel"
        action-text="Submit"
        title="Upload Doctor's Note / Test Result?"
        content="Are you sure you wish to update this appointment?">
            <template #fields>
                <div class="grid grid-cols-2 gap-5 text-left">
                    <div class="col-span-2">
                        <TextInput
                        v-model="uploadNoteAndResultPayload.doctors_note"
                        label="Doctor's Note"
                        placeholder="Doctor's Note"/>
                    </div>
                    <div class="col-span-2">
                        <DropzoneInput
                        label="Test Result"
                        description="PDF file"
                        v-model="rawTestResult"
                        />
                    </div>

                </div>
            </template>
        </ConfirmationModal>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="redirectToBookingIndex()"
            action-text="Confirm"
            title="Appointment Updated!"
            :content="successMsg"
        />

        <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import EditFormSection from './EditFormSection.vue';
import CorporateEditFormSection from './CorporateEditFormSection.vue';
import EditSoftFormSection from './EditSoftFormSection.vue';
import ScheduleLogs from './ScheduleLogs.vue';
import TabComponent from '@/components/TabComponent.vue';
import MenuComponent from '@/components/MenuComponent.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import BookingService from '@/classes/BookingService';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import DropzoneInput from '@/components/inputs/DropzoneInput.vue';

import TextInput from '@/components/inputs/TextInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';

import RescheduleModal from './RescheduleModal.vue';

import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import { add } from 'date-fns';
import { useNetworkStore } from '@/store/network';

interface CancelPayload {
    booking_id: number,
    remarks   : string | null,
}

interface Time {
    hours  : number | null,
    minutes: number | null,
    seconds: number | null,
}

interface ReschedulePayload {
    booking_id        : number,
    booking_date      : string | Date,
    booking_start_time: Time | null,
    booking_end_time  : Time | null,
    remarks           : string | null,

    branch_id         : string | null,
    branch_name       : string | null,
    package_id        : string | null,
    package_name      : string | null,
    riders            : string | null,
}

const rescheduleAppointment = ref(false)
const cancelBooking         = ref(false)
const noteAndResult = ref(false)

const loader           = ref(false)
const showError        = ref(false)
const errorMsg         = ref()
const errors      = ref([])
const showSuccess      = ref(false)
const successMsg       = ref()

const route = useRoute();

const booking_type = ref(route.query.booking_type);
const schedule_logs = ref(route.query.schedule_logs);
const booking_id   = ref(Number(route.params.bookingId) as unknown as number);
const branch_id    = ref(Number(route.params.branchId) as unknown as number);

const branch_name = ref(null);
const package_id = ref(null);
const package_name = ref(null);
const riders = ref(null);

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const cancelPayload = ref<CancelPayload>({
    booking_id: booking_id.value,
    remarks   : null,
});

const reschedulePayload = ref<ReschedulePayload>({
    booking_id        : booking_id.value,
    booking_date      : add(new Date, {days: 3}),
    booking_start_time: null,
    booking_end_time  : null,
    remarks           : null,

    branch_id         : null,
    branch_name       : null,
    package_id        : null,
    package_name      : null,
    riders            : null,
});

const rawTestResult = ref(null)
const uploadNoteAndResultPayload = ref({
    booking_id: booking_id.value,
    doctors_note: null,
    lab_result_base_64: null,
    is_password_protected: null,
    pdf_password: null,
})

const selectedTime = ref(null)
const fullyBookedDates = ref([]);
const unformattedTimeSlots = ref([]);
const timeSlots = ref([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const timezoneOffset = 8; // Philippines time zone is UTC+8
const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000 + timezoneOffset * 60 * 60 * 1000); // For 3 days advance booking

const minDate = computed(() => threeDaysLater.toISOString().slice(0, 10));
const from = ref(null);
const to = ref(null);

const selectedDate = ref(minDate.value)

const formatTimeSlots = () => {
    timeSlots.value = [];
    for(const unformattedTimeSlot in unformattedTimeSlots.value) {
        unformattedTimeSlots.value[unformattedTimeSlot].forEach(slot => {
            if(slot.available_slots != 0) {
                if(!slot.is_blocked) {
                    timeSlots.value.push(slot);
                }
            }
        });

    }
}

const fetchDateAndTime = () => {
    loader.value = true

    const data = {
        branch_id: branch_id.value,
        selected_date: selectedDate.value,
        from: from.value,
        to: to.value,
    }

    BookingService.fetchDateAndTime(data)
        .then((result: any) => {
            loader.value = false;

            fullyBookedDates.value = result.data.response.fully_booked_dates;         
            unformattedTimeSlots.value = result.data.response.time_slots;         
            
            if(selectedDate.value) {
                formatTimeSlots();
            }
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const updateMonth = (val = null) => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch fetchDateAndTime for Active Month
    if(isNetworkAvailable.value) {
        fetchDateAndTime();
    }
}

const firstDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

// const timeTextHelper = computed(() => {
//     if(booking_type.value == 'Individual' || booking_type.value == 'individual') {
//         return 'E.g., 12:00 PM, 8:00 AM';
//     } else if (booking_type.value == 'Corporate' || booking_type.value == 'corporate'){
//         return 'E.g., 14:00:00, 08:00:00';
//     } else {
//         return null;
//     }
// })

const redirectToBookingIndex = () =>{
    window.location.assign(`/branches/${branch_id.value}/bookings/`)
}

const buildCancelPayload = () => {
    if(booking_type.value == 'Individual' || booking_type.value == 'individual') {
        return {
            booking_id: booking_id.value,
            remarks   : cancelPayload.value.remarks
        };
    } else if (booking_type.value == 'Corporate' || booking_type.value == 'corporate'){
        return {
            corporate_booking_id: booking_id.value,
            remarks             : cancelPayload.value.remarks
        };
    }

    return {};
}

const submitCancelBooking = () => {
    loader.value = true;

    BookingService.submitCancelBooking(booking_type.value, buildCancelPayload())
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                cancelBooking.value = false;
                successMsg.value    = "Appointment has been successfully marked as cancelled/no show";
                showSuccess.value   = true;
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}


const buildReschedulePayload = () => {
    if(booking_type.value == 'Individual' || booking_type.value == 'individual') {
        return {
            booking_id        : booking_id.value,
            booking_date      : new Date(reschedulePayload.value.booking_date).toISOString().split('T')[0],
            booking_start_time: reschedulePayload.value.booking_start_time,
            booking_end_time  : reschedulePayload.value.booking_end_time,
            remarks           : reschedulePayload.value.remarks,

            branch_id         : branch_id.value,
            branch_name       : branch_name.value,
            package_id        : package_id.value,
            package_name      : package_name.value,
            riders            : riders.value,
        };
    } else if (booking_type.value == 'Corporate' || booking_type.value == 'corporate'){
        return {
            corporate_booking_id: booking_id.value,
            new_booking_date    : new Date(reschedulePayload.value.booking_date).toISOString().split('T')[0],
            new_booking_time    : reschedulePayload.value.booking_start_time,
            new_booking_end_time: reschedulePayload.value.booking_end_time,
            remarks             : reschedulePayload.value.remarks,
            
            branch_id         : branch_id.value,
            branch_name       : branch_name.value,
            package_id        : package_id.value,
            package_name      : package_name.value,
            riders            : riders.value,
            booking_date    : new Date(reschedulePayload.value.booking_date).toISOString().split('T')[0],
        };
    }

    return {};
}

const rescheduleBooking = () => {
    caches.delete('shinagawa-admin');
    
    loader.value = true;

    BookingService.rescheduleBooking(booking_type.value, buildReschedulePayload())
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                rescheduleAppointment.value = false;
                successMsg.value            = "Appointment has been successfully rescheduled";
                showSuccess.value           = true;
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const fetchedItem = (val) => {
    if(booking_type.value == 'Individual' || booking_type.value == 'individual') {
        branch_name.value = val.branch_name;
        package_id.value = val.package_id;
        package_name.value = val.package_name;
        const rdrs = [];
        if(val.other_charges_details.length > 0) {
            val.other_charges_details[0].trans_line.forEach(element => {
                if(element.items) {
                    rdrs.push(element.items.description);
                }
            });
        }
        riders.value = rdrs;

    } else {
        branch_name.value = val.branch_name;
        package_id.value = val.package_id;
        package_name.value = val.package_name;

        const rdrs = [];
        if(val.other_charges_details.length > 0) {
            val.other_charges_details.forEach(element => {
                    rdrs.push(element.item_name);
            });
        }
        riders.value = rdrs;

        if(timeSlots.value.length > 0) {
            reschedulePayload.value.booking_start_time = new Date(`${new Date(reschedulePayload.value.booking_date).toISOString().split('T')[0]} ${timeSlots.value[0].start_time}`).toLocaleTimeString('en-US', {hour12: false});
            reschedulePayload.value.booking_end_time = new Date(`${new Date(reschedulePayload.value.booking_date).toISOString().split('T')[0]} ${timeSlots.value[timeSlots.value.length - 1].end_time}`).toLocaleTimeString('en-US', {hour12: false});
        }
    }
}

const selectTimeSlot = (val) => {
    if(val) {
        reschedulePayload.value.booking_start_time = val.split('-')[0];
        reschedulePayload.value.booking_end_time = val.split('-')[1];
    }
}

const uploadNoteAndResult = () => {
    loader.value = true;

    BookingService.uploadNoteAndResult(uploadNoteAndResultPayload.value)
        .then((result: any) => {
            loader.value = false;

            noteAndResult.value = false;
            successMsg.value    = "Appointment has been successfully uploaded Doctor's Note / Test Tesult";
            showSuccess.value   = true;

            if(result.data.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const success = ():void => {
    rescheduleAppointment.value = false;
    successMsg.value            = "Appointment has been successfully rescheduled";
    showSuccess.value           = true;
}
const tabs: { name: string, count: number, allowed: boolean }[] = computed(() => [
    {
        name: 'Information',
        count: 0,
        href: `/branches/${branch_id.value}/bookings/edit/${booking_id.value}?booking_type=${booking_type.value}`,
        allowed: true
    },
    {
        name: 'Schedule Logs',
        count: 0,
        href: `/branches/${branch_id.value}/bookings/edit/${booking_id.value}/schedule-logs/?booking_type=${booking_type.value}&schedule_logs=true`,
        allowed:
            (window.$hasPermission('view-individual-patient-schedule-logs') ||
            window.$hasPermission('view-corporate-patient-schedule-logs'))
    },
]);

const menu: { name: string, allowed: boolean }[] = [
    { 
        name: 'Reschedule', 
        allowed: 
            window.$hasPermission('update-individual-patient-appointment-time') || 
            window.$hasPermission('update-company-booking-date')
    },
    { 
        name: 'Mark as Cancelled/No Show',
        allowed:
            window.$hasPermission('update-individual-patient-booking-status') || 
            window.$hasPermission('update-company-booking-status')
    },
    // TODO : IF NEEDED
    // { 
    //     name: "Upload Doctor's Note and Test Result",
    //     allowed:
    //         true
    // },
];

watch(
    () => selectedDate.value,
    (val) => {
        if(val)
            selectedTime.value = null;
            reschedulePayload.value.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];
            updateMonth();
    }
);

watch(
    () => selectedTime.value,
    (val) => {
        if(val)
        selectTimeSlot(val);
    }
);

</script>