<template>
    <admin-layout 
    title="Create Admin"
    show-back
    backUrl="/accounts/admins">
        <div class="p-7">
            <FormSection 
                :form="form" 
                :errors="errors"
            >
                <ButtonComponent
                    v-if="$hasPermission('create-admin-account') || $hasPermission('register-for-an-account')"
                    @click="submit"
                    :disabled="loader"
                >
                    Save Changes
                </ButtonComponent>
            </FormSection>
                
        </div>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Admin"
            content="Admin Created Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
       
        <PageLoader v-if="loader"/>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import PageLoader from '@/components/PageLoader.vue';

import AuthService from '@/classes/AuthService';
import AdminData from '@/classes/AdminData';
import { ref } from 'vue';

// DATA
const loader      = ref(false)
const show        = ref(false);
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref(false);

const form = ref({
    first_name : null,
    last_name : null,
    email     : null,
    role_id   : null,
})
const errors = ref([]);


const submit = async () => {
  loader.value = true;

  try {
    await AdminData.storeAdmin(form.value);
    loader.value      = false;
    show.value        = false;
    showSuccess.value = true;

    setTimeout(() => {
      window.location.replace('/accounts/admins');
    }, 2000);

  } catch (error) {
    loader.value    = false;
    show.value      = false;
    showError.value = true;
    errorMsg.value  = error.response?.data?.message;

    if (error.response?.status === 422) {
      errors.value = error.response.data.errors;
    }
    errorResponse(error);
  }
};

const errorResponse = (err: object) => {
    loader.value = false
    if(err?.response.status == 401) AuthService.redirectToLogin()
}

</script>