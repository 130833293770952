<template>
    <admin-layout 
        title="View Rider"
        show-back
        backUrl="/contents/riders"
    >
        <div>
            <TabComponent :tabs="tabs" />
        </div>
        <div class="p-7">
            <FormSection>
                <template #title>
                    Rider Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <!-- <div class="col-span-12">
                        <TextInput 
                            v-model="form.name"
                            disabled
                            label="Rider Category"
                            placeholder="Rider Category"
                        />
                    </div>
                    <template v-for="(item, key) in form?.items" :key="key">
                        <div class="col-span-12">
                            <TextInput 
                                v-model="item.item_name"
                                disabled
                                label="Rider Name"
                                placeholder="Rider Name"
                            />
                        </div>
                    </template> -->
                    <div class="col-span-12">
                        <TextInput 
                            v-model="riderName"
                            id="riderName"
                            name="riderName"
                            disabled
                            label="Rider Name"
                            placeholder="Rider Name"
                        />
                    </div>
                    <div class="col-span-12">
                        <TextInput 
                            v-model="riderType"
                            id="riderType"
                            name="riderType"
                            disabled
                            label="Rider Type"
                            placeholder="Rider Type"
                        />
                    </div>
                </template>
            </FormSection>
        </div>

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import PageLoader from '@/components/PageLoader.vue';
import Rider from '@/classes/Rider';

import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { replaceDashWithBackslash } from '@/helpers/PackageRiderHelper';

const route     = useRoute();
const loader    = ref(false)
const showError = ref(false);
const errorMsg  = ref(false);
const form      = ref({})
const riderName = ref(route.params.name);
const riderType = ref(route.params.name);

const tabs: { name: string, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        href: `/contents/riders/show/${riderName.value}`,
        allowed: true
    },
    {
        name: 'Schedule',
        href: `/contents/riders/schedule/${riderName.value}`,
        allowed:
            window.$hasPermission('update-package-and-rider-schedule')
    },
];

const fetchRider = async () => {
    try {
        loader.value = true;
        const formttedValue = replaceDashWithBackslash(riderName.value);

        const response   = await Rider.showRider(replaceDashWithBackslash(formttedValue));
        loader.value = false;

        Object.assign(form.value, response.data[0])

    } catch (err) {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = err.response.data.message;

            if (err.response.status === 422) {
                // setTimeout(() => {
                //     window.location.assign('/contents/riders');
                // }, 1500);
            }
    }
    finally{
        loader.value = false
    }
}

// onMounted(() => fetchRider())

</script>