<template>
        <div>
            <DataTable 
                noAction
                :headers="headers" 
                :count="logs?.data?.length"
            >
                <template #body>
                    <tr v-for="(item, key) in logs?.data" :key="key">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            <p>{{ item.id }}</p>
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.event }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-6/12">
                            {{ item.description }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.causer_name }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.created_at }}
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="logs"  
                @nextPage="fetchIndex"
            />
        </div>
</template>

<script setup lang="ts">
import DataTable from '@/components/DataTable.vue';
import PagePagination from '@/components/PagePagination.vue';

defineProps ({
    logs: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits<{
    ( e: 'fetchIndex') : Promise<any>
}>()

const fetchIndex = () => {
    emit("fetchIndex");
}

const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Subject' },
    { text: 'Description' },
    { text: 'Caused By' },
    { text: 'Date Created'}
];

</script>