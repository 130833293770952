<template>
    <FormSection>
        <template #title>
            HMO Accreditation Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-12">
                <TextInput 
                    disabled
                    v-model="item.hmo_name"
                    id="hmo_name"
                    name="hmo_name"
                    label="HMO Accreditation Name"
                    placeholder="HMO Accreditation Name"
                />
            </div>
        </template>
        <template #actions>
            <slot />
        </template>
    </FormSection>
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import { ref } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
})

const item = ref(props.form)

</script>