<template>
    <div class="border-b border-gray-200 flex justify-between">
        <TabGroup>
            <TabList class="flex space-x-2 px-7">
                <div v-for="tab in tabs" :key="tab.name">
                    <Tab
                    v-if="tab.allowed"
                    as="template"
                    @click="visitPage(tab.href)"
                    >
                        <button
                        :class="[
                            currentRoutePath == tab.href
                                ? 'text-primary-600'
                                : 'text-gray-500 hover:text-gray-700 hover:border-gray-200',
                            'whitespace-nowrap flex py-4 px-1 font-medium text-sm cursor-pointer relative ring-0 outline-none',
                        ]"
                        >
                            {{ tab.name }}
                            <span
                                v-if="tab.count"
                                :class="[
                                    'ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium inline-block bg-gray-100 text-gray-900',
                                ]"
                                >{{ tab.count }}</span
                            >
                            <span
                                class="
                                    bg-primary-500
                                    w-full
                                    inline-block
                                    h-1
                                    rounded-tl rounded-tr
                                    absolute
                                    bottom-0
                                    left-0
                                "
                                v-if="currentRoutePath == tab.href"
                            />
                        </button>

                        
                    </Tab>
                </div>
            </TabList>

            <TabPanels class="mt-2">
                <TabPanel>
                    
                </TabPanel>
            </TabPanels>
        </TabGroup>

        <div class="px-7 flex items-center space-x-2">
            <template v-if="buttonItems">
                <slot name="buttons" />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
// import { ref } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { useRouter } from 'vue-router';
import { useNetworkStore } from '@/store/network';
import { computed } from 'vue';

defineProps({
    tabs: {
        type: Object,
        default: () => ({})
    },
    buttonItems: {
        type: Boolean,
        default: false,
    },
    href: {
        type: String,
        default: null,
    }
});

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const router = useRouter();
const currentRoutePath = router.currentRoute.value.path;

const visitPage = (href: string) => {
    if(isNetworkAvailable.value) {
        window.location.assign(href)
    } else {
        router.push(href);
    }
}
</script>
