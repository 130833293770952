<template>
    <div>
        <div class="flex justify-between">
            <div class="flex justify-start items-center">
                <template v-if="customFilters">
                <ButtonComponent
                    v-if="sidebarFilters"
                    type="button"
                    secondary
                    class="mr-2 h-10"
                    @click="open = true"
                >
                    <FunnelIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                    Filter
                </ButtonComponent>
                <template v-if="buttonItems">
                    <slot name="buttons" />
                </template>
                </template>
                <ButtonComponent type="button" secondary class="h-10" @click="refresh">
                    <ArrowPathIcon class="-ml-0.5 h-4 w-4" aria-hidden="true" />
                </ButtonComponent>
            </div>
            <div class="flex items-center gap-3">
                <template v-if="textFilters">
                <div>
                    <label for="search" class="sr-only">Search</label>
                    <div class="relative rounded-lg">
                    <div
                        class="
                        absolute
                        inset-y-0
                        left-0
                        pl-3
                        flex
                        items-center
                        pointer-events-none
                        "
                        aria-hidden="true"
                    >
                        <MagnifyingGlassIcon
                        class="mr-3 h-5 w-5 text-gray-600"
                        aria-hidden="true"
                        />
                    </div>
                    <input
                        v-model="searchText"
                        type="text"
                        name="search"
                        id="search"
                        class="
                        focus:ring-primary-300 focus:border-primary-300
                        block
                        w-full
                        pl-10
                        text-sm
                        border-gray-300
                        rounded-lg
                        h-11
                        "
                        placeholder="Search"
                        @input="updateSearchText($event.target.value)"
                    />
                    <button
                        v-if="searchText"
                        @click="updateSearchText(null)"
                        class="absolute inset-y-0 right-0 pr-3 flex items-center z-10"
                    >
                        <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                    </div>
                </div>
                </template>

                <template v-if="rightButtonItems">
                    <slot name="rightButtons" />
                </template>
            </div>
        </div>

        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="relative z-10" @close="open = false">
                <div class="fixed inset-0" />

                <div class="fixed inset-0 overflow-hidden">
                    <div class="absolute inset-0 overflow-hidden">
                        <div
                        class="
                            pointer-events-none
                            fixed
                            inset-y-0
                            right-0
                            flex
                            max-w-full
                            pl-10
                        "
                        >
                            <TransitionChild
                                as="template"
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enter-from="translate-x-full"
                                enter-to="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leave-from="translate-x-0"
                                leave-to="translate-x-full"
                            >
                                <DialogPanel class="pointer-events-auto w-screen max-w-sm">
                                    <div
                                        class="
                                        flex
                                        h-full
                                        flex-col
                                        overflow-y-scroll
                                        bg-white
                                        py-6
                                        shadow-xl
                                        "
                                    >
                                        <div class="px-4 sm:px-6">
                                            <div class="flex items-start justify-between">
                                                <DialogTitle class="text-lg font-medium text-gray-900">
                                                    Filter
                                                </DialogTitle>
                                            </div>
                                        </div>
                                        <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div class="h-full flex flex-col">
                                                <div class="w-full">
                                                    <slot name="fields" />
                                                </div>

                                                <div class="mt-auto w-full">
                                                    <div class="flex justify-end">
                                                        <ButtonComponent
                                                        secondary
                                                        class="mr-2"
                                                        @click="open = false"
                                                        >
                                                            Cancel
                                                        </ButtonComponent>
                                                        <ButtonComponent type="button" @click="applyFilter">
                                                            Apply Filter
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {
    XMarkIcon,
    FunnelIcon,
    ArrowPathIcon,
    MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import ButtonComponent from "@/components/ButtonComponent.vue";

import { ref } from 'vue'

const props = defineProps({
    textFilters: {
        type: Boolean,
        default: true,
    },
    rightButtonItems: {
        type: Boolean,
        default: false,
    },
    customFilters: {
        type: Boolean,
        default: true,
    },
    sidebarFilters: {
        type: Boolean,
        default: true,
    },
    buttonItems: {
        type: Boolean,
        default: false,
    },
    search: {
        type: String,
        default: null,
    },
});

const open = ref(false);
const searchText = ref(props.search);

const emit = defineEmits<{
    ( e: 'fetchData', val: object) : void
    ( e: 'update:filters') : void
}>()

const updateSearchText = (value) => {
    searchText.value = value;
    emit("fetchData", value);
}

const applyFilter = () => {
    open.value = false
    emit("update:filters");
}

const refresh = () => {
    location.reload()
}
</script>