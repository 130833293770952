<template>
    <FormSection>
        <template #title>
            Blocked Date Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-6">
                <ToggleSwitch
                :key="isWholeDay"
                :value="isWholeDay"
                @change="
                (value) =>
                    (isWholeDay = value)
                "
                label="Whole Day" />
            </div>

            <div class="col-span-12">
                <DatePicker
                    :key="selectedDate"
                    v-model="selectedDate"
                    id="selectedDate"
                    name="selectedDate"
                    label="Date"
                    placeholder="MM/DD/YYYY"
                />
            </div>

            <div class="col-span-12">
                <TextInput 
                    v-model="payload.reason"
                    id="reason"
                    name="reason"
                    label="Reason"
                    placeholder="Reason"
                />
            </div>

            <template v-if="!isWholeDay">
                <div class="col-span-6">
                    <TextInput
                        type="time"
                        v-model="payload.start_time"
                        id="start_time"
                        name="start_time"
                        label="Start Time"
                        placeholder="Start Time"
                        :step="60"
                        helper="Do not include minutes"
                    />
                </div>
                
                <div class="col-span-6">
                    <TextInput
                        type="time"
                        v-model="payload.end_time"
                        id="end_time"
                        name="end_time"
                        label="End Time"
                        placeholder="End Time"
                        :step="60"
                        helper="Do not include minutes"
                    />
                </div>
            </template>

        </template>

        <template #actions>
            <ButtonComponent @click="showConfirmation = true">
                Submit
            </ButtonComponent>
            <slot />
        </template>

        
    </FormSection>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        :errors="errors"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Confirm"
        title="Blocked Date Updated!"
        content="Blocked Date has been updated successfully"
    />

    <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; updateBlockedDate() "
        cancel-text="Cancel"
        action-text="Confirm"
        title="Submit Blocked Date?"
        content="Are you sure you wish to submit this blocked date?"
    />
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import ToggleSwitch from '@/components/inputs/ToggleSwitch.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { ref, watch, onMounted } from 'vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import BlockedDate from '@/classes/BlockedDate';

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

const selectedDate = ref(null)
const isWholeDay = ref(null)

const props = defineProps ({
    branchId: {
        type: String,
        default: null
    },
    blockedDateId: {
        type: String,
        default: null
    }
});

const payload = ref({
    branch_id: props.branchId,
    is_wholeday: true,
    date: null,
    reason: null,
    start_time: null,
    end_time: null,
});

const updateBlockedDate = () => {
    loader.value = true;

    BlockedDate.updateBlockedDate(props.blockedDateId, payload.value)
        .then((result: any) => {
            loader.value      = false;
            showSuccess.value = true;
            payload.value = result.data.blockedDate;

            setTimeout(() => {
                window.location.replace(`/branches/${props.branchId}/blocked-dates`)
            }, 2000);
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const editBlockedDate = () => {
    loader.value = true;

    BlockedDate.editBlockedDate(props.blockedDateId)
        .then((result: any) => {
            loader.value = false;
            payload.value = result.data.blockedDate;
            selectedDate.value = payload.value.date;
            isWholeDay.value = payload.value.is_wholeday == 1 ? true : false;
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

watch(
    () => selectedDate.value,
    (val) => {
        if(val)
            payload.value.date = new Date(selectedDate.value).toISOString().split('T')[0];
    },
    { immediate: true }
);

watch(
    () => isWholeDay.value,
    (val) => {
        if(val) {
            payload.value.start_time = null;
            payload.value.end_time = null;
        }
        payload.value.is_wholeday = val;
    },
    { immediate: true }
);

onMounted(() => {
    editBlockedDate();
})

</script>