<template>
    <div class="flex items-center ml-auto relative">
        <button 
        @click="decrement()" 
        class="flex border border-solid border-[#23A2B4] rounded-lg w-10 h-10 bg-white items-center justify-center">
            <MinusIcon 
            class="w-6 h-6 text-[#23A2B4]"/>
        </button>

        <input
            type="text"
            class="w-10 border-0 text-center p-0 focus:ring-0 bg-transparent" 
            v-model="count"
            :id="id"
            :name="name"
        >

        <button 
        @click="increment()" 
        class="flex border border-solid border-[#23A2B4] rounded-lg w-10 h-10 bg-[#23A2B4] items-center justify-center">
            <PlusIcon 
            class="w-6 h-6 text-white"/>
        </button>
    </div>
</template>
<script setup lang="ts">
import { MinusIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ref, watch } from 'vue';

const props = defineProps ({
    modelValue: {
        default: null,
        required: true,
    },
    value: {
        default: 0,
        required: true,
    },
    id: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
});
const emit = defineEmits(['update:modelValue'])
const count = ref<number>(props.value)

const increment = () => {
    count.value++
}

const decrement = () => {
    if(count.value > 1) {
        count.value--
    }
}

watch(
    () => count.value,
    (val) => {
        emit('update:modelValue', val)
    },
    { immediate: true }
);

</script>
