<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-padding">
            <div class="w-full">
                <h1 class="font-bold text-primary mb-4">Stylesheet</h1>
                <h2 class="font-bold text-gray-700 mb-4">Colors</h2>
                <h4 class="mb-4 text-gray-600 font-medium">Primary Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in primaryColors" :key="i"
                    :style="{ width: 100 / primaryColors.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in secondaryColors" :key="i"
                    :style="{ width: 100 / secondaryColors.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Gray Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in gray" :key="i"
                    :style="{ width: 100 / gray.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Neutral Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in neutral" :key="i"
                    :style="{ width: 100 / neutral.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Red Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in red" :key="i"
                    :style="{ width: 100 / red.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Orange Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in orange" :key="i"
                    :style="{ width: 100 / orange.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Yellow Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in yellow" :key="i"
                    :style="{ width: 100 / yellow.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Green Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in green" :key="i"
                    :style="{ width: 100 / green.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Blue Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in blue" :key="i"
                    :style="{ width: 100 / blue.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>

                <h4 class="mb-4 text-gray-600 font-medium">Violet Colors</h4>
                <div class="flex space-x-2 mb-8">
                    <div class="flex flex-col" v-for="i in violet" :key="i"
                    :style="{ width: 100 / violet.length + '%' }">
                        <div class="w-full mb-2">
                            <p class="text-xs text-slate-500">{{ i.hex }}</p>
                            <p class="text-xs text-slate-500">{{ i.color }}</p>
                        </div>
                        <div class="w-full h-10 rounded-xl" :class="i.color"></div>
                    </div>
                </div>
            </div>
            <div class="w-full mb-6">
                <h2 class="font-bold text-gray-700 mb-4">Typography</h2>
                <div class="mb-8">
                    <h1 class="text-3xl	font-bold mb-2">Nunito</h1>
                    <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                    <p>abcdefghijklmnopqrstuvwxyz</p>
                    <p>0123456789</p>
                </div>


                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-xs m-0">text-xs</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 0.75rem; /* 12px */</p>
                        <p class="text-sm text-slate-500">line-height: 1rem; /* 16px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-sm m-0">text-sm</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 0.875rem; /* 14px */</p>
                        <p class="text-sm text-slate-500">line-height: 1.25rem; /* 20px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-base m-0">text-base</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 1rem; /* 16px */</p>
                        <p class="text-sm text-slate-500">line-height: 1.5rem; /* 24px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-lg m-0">text-lg</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 1.125rem; /* 18px */</p>
                        <p class="text-sm text-slate-500">line-height: 1.75rem; /* 28px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-xl m-0">text-xl</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 1.25rem; /* 20px */</p>
                        <p class="text-sm text-slate-500">line-height: 1.75rem; /* 28px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-2xl m-0">text-2xl</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 1.5rem; /* 24px */</p>
                        <p class="text-sm text-slate-500">line-height: 2rem; /* 32px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-3xl m-0">text-3xl	</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 1.875rem; /* 30px */</p>
                        <p class="text-sm text-slate-500">line-height: 2.25rem; /* 36px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-4xl m-0">text-4xl	</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 2.25rem; /* 36px */</p>
                        <p class="text-sm text-slate-500">line-height: 2.5rem; /* 40px */</p>
                    </div>
                </div>
                <div class="flex justify-between items-center py-2 border-b">
                    <p class="text-5xl m-0">text-5xl</p>
                    <div class="w-1/2">
                        <p class="text-sm text-slate-500">font-size: 3rem; /* 48px */</p>
                        <p class="text-sm text-slate-500">line-height: 1;</p>
                    </div>
                </div>
            </div>

            <div class="w-full mb-6">
                <h2 class="font-bold text-gray-700 mb-4">Buttons</h2>

                <div class="mb-4">
                    <button-component
                        size="small"
                        color="primary"
                        class="mr-2"
                    >
                        <img class="mr-2" width="24" src="/assets/icons/icon.png">
                        Button Small
                    </button-component>

                    <button-component
                        size="small"
                        color="primary"
                        :disabled="true"
                        class="mr-2"
                    >
                        Button Small
                    </button-component>

                    <button-component
                        size="small"
                        color="primary"
                    >
                        Button Large
                    </button-component>
                </div>
            </div>

            <div class="w-full mb-6">
                <h2 class="font-bold text-gray-700 mb-4">Forms</h2>

                <div class="mb-4">
                    <text-input
                        type="text"
                        label="Email"
                        placeholder="Enter phone or email"
                        helper="9123456789 or sample@email.com"
                    />
                </div>
                <div class="mb-4">
                    <text-input
                        label="Price"
                        placeholder="Price"
                        id="price"
                        :add-on-left="true"
                        add-on-text="₱"
                        type="number"
                    />
                </div>
                <div class="mb-4">
                    <text-input
                        error="error"
                        label="Height"
                        placeholder="Height"
                        id="height"
                        :add-on-right="true"
                        add-on-text="cm"
                        type="number"
                    />
                </div>
                <div class="mb-4">
                    <select-options
                        :options="sampleOptions"
                        label="Sample Selector"
                        id="selector-id"
                        name="selector-name"
                        placeholder="Sample Selector"
                    />
                </div>

                <div class="mb-4">
                    <text-input
                        textarea
                        name="textarea-input"
                        id="textarea-input"
                        placeholder="Sample Placeholder"
                        label="Sample Label"
                    />
                </div>

                <div class="mb-4">
                    <checkbox-input
                        id="terms"
                        v-model="agree"
                        label="I have read and agree to the above terms & conditions"
                    />
                    <checkbox-input
                        id="agree"
                        v-model="agree"
                        :default-slot="false"
                    >
                        <div class="ml-3">
                            <label for="agree" class="text-sm block leading-4">
                                I agree to the <a href="" class="text-blue-500 text-sm">privacy policy</a> and <a href="" class="text-blue-500 text-sm">terms & conditions</a>
                            </label>
                        </div>
                    </checkbox-input>
                </div>

                <div class="mb-4 space-y-1">
                    <radio-group
                        :options="sampleOptions"
                        name="options"
                    />
                </div>

                <div class="mb-4">
                    <birthday-picker
                        label="Birthday"
                    />
                </div>

                <div class="mb-4">
                    <GenderSelection />
                </div>

                <div class="mb-4">
                    <mobile-number 
                    label="Mobile Number"
                    placeholder="Mobile Number"/>
                </div>

                <div class="mb-4">
                    <password-input
                        id="password"
                        label="Password"
                        placeholder="Password"
                    />
                </div>

                <div class="mb-4">
                    <badge-component
                    text="Badge"
                    color="bg-primary-900"
                    textColor="text-white"
                    forwardIcon
                    />
                </div>

                <div>
                    <QuantitySelector />
                </div>
            </div>

            <!-- </ion-grid> -->
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonPage,
    IonContent,
} from "@ionic/vue";
import ButtonComponent from '@/components/ButtonComponent.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import SelectOptions from "@/components/inputs/SelectOptions.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import BirthdayPicker from "@/components/inputs/DatePicker.vue";
import GenderSelection from "@/components/inputs/GenderSelection.vue";
import MobileNumber from "@/components/inputs/MobileNumber.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import BadgeComponent from "@/components/BadgeComponent.vue";
import QuantitySelector from "@/components/inputs/QuantitySelector.vue";

import colors from 'tailwindcss/colors'

const sampleOptions = [
    { id: '1', label: 'Colors', value: 'Colors'},
    { id: '2', label: 'Typography', value: 'Typography'},
    { id: '3', label: 'Forms', value: 'Forms'},
    { id: '4', label: 'Buttons', value: 'Buttons'},
    { id: '5', label: 'Cards', value: 'Cards'}
]

const primaryColors = [
    { hex: '#f6e8fc', color: 'bg-primary-200' },
    { hex: '#f2ddfb', color: 'bg-primary-300' },
    { hex: '#e4b8f6', color: 'bg-primary-400' },
    { hex: '#a81ae2', color: 'bg-primary-500' },
    { hex: '#7e14aa', color: 'bg-primary-600' },
    { hex: '#3b094f', color: 'bg-primary-700' },
];

const secondaryColors = [
    { hex: '#FFF8E6', color: 'bg-secondary-200' },
    { hex: '#FFF5D9', color: 'bg-secondary-300' },
    { hex: '#FFEAB0', color: 'bg-secondary-400' },
    { hex: '#FFBC00', color: 'bg-secondary-500' },
    { hex: '#BF8D00', color: 'bg-secondary-600' },
    { hex: '#594200', color: 'bg-secondary-700' },
];

const gray = [
    { hex: colors.gray[50], color: 'bg-gray-50' },
    { hex: colors.gray[100], color: 'bg-gray-100' },
    { hex: colors.gray[200], color: 'bg-gray-200' },
    { hex: colors.gray[300], color: 'bg-gray-300' },
    { hex: colors.gray[400], color: 'bg-gray-400' },
    { hex: colors.gray[500], color: 'bg-gray-500' },
    { hex: colors.gray[600], color: 'bg-gray-600' },
    { hex: colors.gray[700], color: 'bg-gray-700' },
    { hex: colors.gray[800], color: 'bg-gray-800' },
    { hex: colors.gray[900], color: 'bg-gray-900' },
]

const neutral = [
    { hex: colors.neutral[50], color: 'bg-neutral-50' },
    { hex: colors.neutral[100], color: 'bg-neutral-100' },
    { hex: colors.neutral[200], color: 'bg-neutral-200' },
    { hex: colors.neutral[300], color: 'bg-neutral-300' },
    { hex: colors.neutral[400], color: 'bg-neutral-400' },
    { hex: colors.neutral[500], color: 'bg-neutral-500' },
    { hex: colors.neutral[600], color: 'bg-neutral-600' },
    { hex: colors.neutral[700], color: 'bg-neutral-700' },
    { hex: colors.neutral[800], color: 'bg-neutral-800' },
    { hex: colors.neutral[900], color: 'bg-neutral-900' },
]

const red = [
    { hex: colors.red[50], color: 'bg-red-50' },
    { hex: colors.red[100], color: 'bg-red-100' },
    { hex: colors.red[200], color: 'bg-red-200' },
    { hex: colors.red[300], color: 'bg-red-300' },
    { hex: colors.red[400], color: 'bg-red-400' },
    { hex: colors.red[500], color: 'bg-red-500' },
    { hex: colors.red[600], color: 'bg-red-600' },
    { hex: colors.red[700], color: 'bg-red-700' },
    { hex: colors.red[800], color: 'bg-red-800' },
    { hex: colors.red[900], color: 'bg-red-900' },
]

const orange = [
    { hex: colors.orange[50], color: 'bg-orange-50' },
    { hex: colors.orange[100], color: 'bg-orange-100' },
    { hex: colors.orange[200], color: 'bg-orange-200' },
    { hex: colors.orange[300], color: 'bg-orange-300' },
    { hex: colors.orange[400], color: 'bg-orange-400' },
    { hex: colors.orange[500], color: 'bg-orange-500' },
    { hex: colors.orange[600], color: 'bg-orange-600' },
    { hex: colors.orange[700], color: 'bg-orange-700' },
    { hex: colors.orange[800], color: 'bg-orange-800' },
    { hex: colors.orange[900], color: 'bg-orange-900' },
]

const yellow = [
    { hex: colors.yellow[50], color: 'bg-yellow-50' },
    { hex: colors.yellow[100], color: 'bg-yellow-100' },
    { hex: colors.yellow[200], color: 'bg-yellow-200' },
    { hex: colors.yellow[300], color: 'bg-yellow-300' },
    { hex: colors.yellow[400], color: 'bg-yellow-400' },
    { hex: colors.yellow[500], color: 'bg-yellow-500' },
    { hex: colors.yellow[600], color: 'bg-yellow-600' },
    { hex: colors.yellow[700], color: 'bg-yellow-700' },
    { hex: colors.yellow[800], color: 'bg-yellow-800' },
    { hex: colors.yellow[900], color: 'bg-yellow-900' },
]

const green = [
    { hex: colors.green[50], color: 'bg-green-50' },
    { hex: colors.green[100], color: 'bg-green-100' },
    { hex: colors.green[200], color: 'bg-green-200' },
    { hex: colors.green[300], color: 'bg-green-300' },
    { hex: colors.green[400], color: 'bg-green-400' },
    { hex: colors.green[500], color: 'bg-green-500' },
    { hex: colors.green[600], color: 'bg-green-600' },
    { hex: colors.green[700], color: 'bg-green-700' },
    { hex: colors.green[800], color: 'bg-green-800' },
    { hex: colors.green[900], color: 'bg-green-900' },
]

const blue = [
    { hex: colors.blue[50], color: 'bg-blue-50' },
    { hex: colors.blue[100], color: 'bg-blue-100' },
    { hex: colors.blue[200], color: 'bg-blue-200' },
    { hex: colors.blue[300], color: 'bg-blue-300' },
    { hex: colors.blue[400], color: 'bg-blue-400' },
    { hex: colors.blue[500], color: 'bg-blue-500' },
    { hex: colors.blue[600], color: 'bg-blue-600' },
    { hex: colors.blue[700], color: 'bg-blue-700' },
    { hex: colors.blue[800], color: 'bg-blue-800' },
    { hex: colors.blue[900], color: 'bg-blue-900' },
]

const violet = [
    { hex: colors.violet[50], color: 'bg-violet-50' },
    { hex: colors.violet[100], color: 'bg-violet-100' },
    { hex: colors.violet[200], color: 'bg-violet-200' },
    { hex: colors.violet[300], color: 'bg-violet-300' },
    { hex: colors.violet[400], color: 'bg-violet-400' },
    { hex: colors.violet[500], color: 'bg-violet-500' },
    { hex: colors.violet[600], color: 'bg-violet-600' },
    { hex: colors.violet[700], color: 'bg-violet-700' },
    { hex: colors.violet[800], color: 'bg-violet-800' },
    { hex: colors.violet[900], color: 'bg-violet-900' },
]
</script>
