<template>
    <div>

        <TabComponent v-if="!isHidden"
            :tabs="tabs"
        >
        </TabComponent>

        <!-- Content -->
		<slot name="contents" />
    </div>
</template>

<script setup lang="ts">
import TabComponent from '@/components/TabComponent.vue';
import { computed } from 'vue';
import { useNetworkStore } from '@/store/network';

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const props = defineProps ({
    branchId: {
        type: String,
        default: null
    },
    isHidden: {
        type: Boolean,
        default: false
    }
});

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = computed(() =>[
    {
        name: 'Information',
        count: 0,
        href: '/branches/edit/' + props.branchId,
        allowed:
            isNetworkAvailable.value &&
            (window.$hasPermission('update-branch') ||
            window.$hasPermission('update-clinic-hours') ||
            window.$hasPermission('create-company') ||
            window.$hasPermission('update-company') ||
            window.$hasPermission('archive-company') ||
            window.$hasPermission('restore-company') ||
            window.$hasPermission('view-company') ||
            window.$hasPermission('view-individual-patient') ||
            window.$hasPermission('view-corporate-patient') ||
            window.$hasPermission('view-individual-patient-schedule') ||
            window.$hasPermission('view-bookings-in-offline') ||
            window.$hasPermission('create-blocked-date') ||
            window.$hasPermission('update-blocked-date') ||
            window.$hasPermission('archive-blocked-date') ||
            window.$hasPermission('restore-blocked-date') ||
            window.$hasPermission('soft-book-dates-for-individual-patient'))
    },
    {
        name: 'Companies', 
        count: 0,
        href: `/branches/${props.branchId}/companies/`,
        allowed:
            isNetworkAvailable.value &&
            (window.$hasPermission('create-company') ||
            window.$hasPermission('update-company') ||
            window.$hasPermission('archive-company') ||
            window.$hasPermission('restore-company') ||
            window.$hasPermission('view-company') ||
            window.$hasPermission('add-employees-to-a-company') || 
            window.$hasPermission('update-corporate-patient'))
    },
    {
        name: 'Bookings',
        count: 0,
        href: `/branches/${props.branchId}/bookings/`,
        allowed:
            !!isNetworkAvailable.value ||
            (window.$hasPermission('view-individual-patient') ||
            window.$hasPermission('view-corporate-patient') ||
            window.$hasPermission('view-individual-patient-schedule') ||
            window.$hasPermission('view-bookings-in-offline') ||
            window.$hasPermission('soft-book-dates-for-individual-patient'))
    },
    {
        name: 'Blocked Dates',
        count: 0,
        href: `/branches/${props.branchId}/blocked-dates/`,
        allowed:
            isNetworkAvailable.value &&
            (window.$hasPermission('create-blocked-date') ||
            window.$hasPermission('update-blocked-date') ||
            window.$hasPermission('archive-blocked-date') ||
            window.$hasPermission('restore-blocked-date'))
    }
]);

</script>