<template>
    <admin-layout 
        title="Bookings"
        show-back
        :backUrl="`/accounts/patients`">
        <PatientLayout :patient-id="patientId">
            <template #contents>
                <!-- @TODO: Filters -->
                <div>
                    <TabComponent
                        :tabs="tabs"
                        :button-items="true"
                    >
                        <template #buttons v-if="isNetworkAvailable">
                            <ButtonComponent
                            class="text-gray-900"
                            secondary
                            exportBtn>
                                Export
                            </ButtonComponent>
                        </template>
                    </TabComponent>
                </div>
                <!-- Filter -->
                <div class="py-3 px-6" v-if="isNetworkAvailable">
                    <FilterComponent
                        :search="searchText"
                        @update:searchText="(value) => (searchText = value)"
                        :sidebar-filters="false"
                        :custom-filters="activeTab !== 'activity_logs'"
                    >
                    </FilterComponent>
                </div>

                <div class="">
                    <DataTable 
                        :headers="headers" 
                        :count="items?.total"
                    >
                        <template #body >
                            <tr v-for="(item, key) in items?.data" :key="key">
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ item.booking_id }}
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ item.patient?.first_name }} {{ item.patient?.last_name }}
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    <StatusPill
                                    :color="getStatusColor(item.status)"
                                    :text="item.status"/>
                                </td>
                                <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                                    {{ format(new Date(item.booking_created_date), 'MMM dd, yyy (h:mm a)') }}
                                </td>
                                <td class="text-center">
                                    <ActionButton 
                                        edit-btn
                                        @click="router.push(`/accounts/patients/${patientId}/bookings/show/` + item.booking_id)"
                                    />
                                </td>
                            </tr>
                        </template>
                    </DataTable>
                    <page-pagination class="mb-6" 
                        :items="items"  
                        @nextPage="indexBookings"
                    />
                </div>
            </template>
        </PatientLayout>
        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import PatientLayout from '../components/PatientLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StatusPill from '@/components/StatusPill.vue';
import PageLoader from '@/components/PageLoader.vue';
import PagePagination from '@/components/PagePagination.vue';
import Patient from '@/classes/Patient'; 

import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref, computed, watch } from 'vue';
import { format } from 'date-fns';
import { useNetworkStore } from '@/store/network';
import { onIonViewWillEnter } from '@ionic/vue';

const router = useRouter();
const route = useRoute();
const patientId = ref(route.params.id);

// Data
const loader    = ref(true);
const showError = ref(false);
const errorMsg  = ref(null);

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

// Query
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();

// Page Renderer Data
const items       = ref(null);
const items_count = ref(0);
const query       = ref(null);

const tabs: { name: string, count: number, href: string, allowed: true }[] = [
    {
        name: 'Active',
        count: 0,
        href: '/accounts/patients/bookings/' + patientId.value,
        allowed: true
    }
];

const headers: { text: string }[] = [
    { text: 'ID' },
    { text: 'Patient' },
    { text: 'Status' },
    { text: 'Date Created'}
];

const indexBookings = (data: string) => {
    loader.value = true;

    // console.log(isNetworkAvailable.value)

    if(isNetworkAvailable.value) {
        console.log("Online - Fetching Patient Bookings...")

        const pageNum = data?.match(/\d+/)[0] ? data?.match(/\d+/)[0] : 1;
        const queryString =  searchText.value ? `&query=${searchText.value}` : ''
        const additionalparam = `&page_number=${pageNum}&per_page=10&${queryString}&requested_at=${new Date().toISOString()}`
    
        goToPage.value = data ? `page=${pageNum}` : 'page=1';
    
        Patient.showPatientBookings(patientId.value, goToPage.value + additionalparam)
            .then((response: any) => {
                loader.value      = false;
                items.value       = response.data;
                items_count.value = response.data.total
                query.value       = response.data.query
            })
            .catch((err) => {
                loader.value    = false;
                showError.value = true;
                errorMsg.value  = err.response.data.message;
                
                if(err.response.status == 401) {
                    window.location.replace('/login');
                }
            })
    } else {
        console.log("Offline - Fetching Patient Bookings...")

        const pageNum = data?.match(/\d+/)[0] ? data?.match(/\d+/)[0] : 1;

        Patient.showPatientBookings(patientId.value, `page=${pageNum}&page_number=${pageNum}&per_page=10`)
        .then((response: any) => {
            loader.value      = false;
            items.value       = response.data;
            items_count.value = response.data.total
            query.value       = response.data.query
        })
        .catch(() => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value = "Oppps... No available data was saved for offline use.";
        })
    }
}

const getStatusColor = (status: string) => {
    if(status) {
        let color = null;
        switch (status) {
            case 'Booked':
                color = 'blue';
                break;
        
            case 'Cancelled/No-Show':
            case 'Cancelled':
                color = 'red';
                break;
        
            case 'Done':
                color = 'green';
                break;
            
            default:
                color = 'yellow'
                break;
        }

        return color;
    }

    return 'yellow';
}

watch(
    () => isNetworkAvailable.value,
    () => {
        indexBookings(goToPage.value);
    }
);

onMounted(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        indexBookings(goToPage.value);
    }, 1000);
})

onIonViewWillEnter(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        indexBookings(goToPage.value);
    }, 1000);
})

</script>