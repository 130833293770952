<template>
    <div class="flex items-center cursor-pointer" @click="$emit('change', !value)">
        <Switch
            v-model="switchValue"
            :class="switchValue ? 'bg-primary-500' : 'bg-gray-300'"
            class="relative inline-flex h-[20px] w-[32px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
            <span
                aria-hidden="true"
                :class="switchValue ? 'translate-x-4' : 'translate-x-1'"
                class="pointer-events-none inline-block mt-1 h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
            />
        </Switch>
        <p class="ml-3 text-sm text-gray-900">{{ label }}</p>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Switch } from '@headlessui/vue'

const props = defineProps({
    value: {
        type: Boolean,
        default: null,
        required: true,
    },
    label: {
        type: String,
    },
});

defineEmits<{
    ( e: 'change', val: boolean): void
}>()

const switchValue = ref(props.value)

// const switchValue = computed<object>({
//     get() {
//         return props.value;
//     },
//     set(value) {
//         emit('change', value);
//     }
// })
</script>