<template>
    <div @click="check()" class="space-y-2" :class="disabled ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'">
        <div class="col-span-1 flex py-5 px-4 rounded-lg items-center gap-10 justify-between"
            :class="isChecked ? 'bg-[#F9FAFB]': ''"
        >
            <div>
                <p class="text-sm font-bold">{{ title }}</p>
                <p class="text-sm text-gray-500">{{ description }}</p>
            </div>
            <div>
                <label :for="id" class="inline-flex relative items-center cursor-pointer">
                    <input type="checkbox" value="" :id="id" :name="name" class="sr-only peer" :checked="isChecked" @click="check()">
                    <div class="w-8 h-5 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-sd-info peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:top-1/2 after:-translate-y-1/2 after:transition-all focus:outline-none dark:border-gray-600 peer-checked:bg-primary-700"></div>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const props = defineProps({
    id: {
        type: String,
        default: null
    },
    name: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    checked: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})
const isChecked = ref(props.checked);
const check = () => {
    isChecked.value = !isChecked.value
}

</script>