<template>
    <admin-layout 
        title="View Company"
        show-back
        :backUrl="`/branches/${branchId}/companies`">
        <div>
            <TabComponent :tabs="tabs" />
        </div>
        <div class="p-7">
            <FormSection>
                <template #title>
                    Booking Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-12">
                        <MultiselectInput
                            v-model="selectedPackage"
                            id="packages"
                            name="packages"
                            label="Package"
                            placeholder="Package"
                            :options="packages"
                        />
                    </div>
                    <div class="col-span-12">
                        <MultiselectInput
                            v-model="selectedRiders"
                            id="selectedRiders"
                            name="selectedRiders"
                            label="Rider/s"
                            placeholder="Rider/s"
                            mode="tags"
                            :close-on-select="false"
                            searchable
                            :options="riders"
                        />
                    </div>
                    <div class="col-span-6">
                        <DatePicker
                            ref="selectedDateRef"
                            v-model="selectedDate"
                            id="selectedDate"
                            name="selectedDate"
                            label="Booking Date"
                            placeholder="MM/DD/YYYY"
                            :min-date="currentDate"
                            :fully-booked-dates="fullyBookedDates"
                            hide-offset-dates
                            highlight-disabled-days
                            :disabledWeekDays="getDisabledDates"
                        />
                    </div>
                    <div class="col-span-6">
                        <MultiselectInput
                            v-model="selectedSlot"
                            id="selectedSlot"
                            name="selectedSlot"
                            label="Time Slots"
                            placeholder="Time Slots"
                            searchable
                            :options="timeSlots"
                            @update:modelValue="setStartEndTime"
                        />  
                    </div>
                    <div class="col-span-6">
                        <TextInput
                            v-model="employeeCount"
                            id="employeeCount"
                            name="employeeCount"
                            label="Number of Employees"
                            placeholder="Number of Employees"
                            disabled
                        />
                    </div>
                    
                    <div class="col-span-12">
                        <DropzoneInput
                            v-model:file="csvFile"
                            label="Participating Corporate Employees"
                            description="CSV, EXCEL file"
                            accept=".csv"
                        />

                        <a
                            href="/assets/files/shinagawa-patients.csv"
                            download
                            class="inline-block mt-3 text-blue-500"
                        >
                            Download Corporate Patient List Manifest
                        </a>
                    </div>
                </template>
            </FormSection>
            
        </div>

        <div class="grid grid-cols-4 gap-6 pt-6 mb-6 border-t mx-7">
            <div>
                <TextInput
                    v-model="employee.first_name"
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    placeholder="First Name"
                />
            </div>
            <div>
                <TextInput
                    v-model="employee.middle_name"
                    id="middle_name"
                    name="middle_name"
                    label="Middle Name"
                    placeholder="Middle Name"
                />
            </div>
            <div>
                <TextInput 
                    v-model="employee.last_name"
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                />
            </div>
            <div>
                <TextInput 
                    v-model="employee.suffix"
                    id="suffix"
                    name="suffix"
                    label="Suffix"
                    placeholder="Suffix"
                />
            </div>
            <div>
                <TextInput
                    v-model="employee.email_address"
                    id="email_address"
                    name="email_address"
                    label="Email"
                    placeholder="Email"
                />
            </div>
            <div>
                <MultiselectInput
                    v-model="employee.sex"
                    id="sex"
                    name="sex"
                    label="Sex"
                    placeholder="Sex"
                    :options="sexes"
                />
            </div>
            <div>
                <DatePicker
                    v-model="employee.birthdate"
                    id="birthdate"
                    name="birthdate"
                    label="Birthday"
                    placeholder="MM/DD/YYYY"
                />
            </div>
        </div>

        <div class="mb-6 text-right px-7">
            <ButtonComponent
                v-if="$hasPermission('add-employees-to-a-company') || $hasPermission('update-corporate-patient')"
                @click="validateEmployee()"
            >
                Add Employee
            </ButtonComponent>
        </div>

        <div class="">
            <DataTable 
            :headers="headers" 
            :no-action="noAction"
            :count="employeeCount">
                <template #body>
                    <tr v-for="(employee, index) in payload.employee_list" :key="employee.id">
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.first_name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.middle_name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.last_name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.suffix }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            <TextInput
                            v-model="payload.employee_list[index].email_address"
                            placeholder="Email"
                            />
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ employee.sex }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{  format(new Date(employee.birthdate), 'LLL dd, yyyy') }}
                        </td>
                        <td 
                        v-if="!noAction && ($hasPermission('add-employees-to-a-company') || $hasPermission('update-corporate-patient'))"
                        @click="removeEmployee(index)"
                        class="text-center">
                            <ActionButton close-btn/>
                        </td>
                    </tr>
                </template>
            </DataTable>
        </div>

        <div class="text-right p-7">
            <ButtonComponent v-if="$hasPermission('add-employees-to-a-company') || $hasPermission('update-corporate-patient')" @click="showConfirmation = true">
                Submit
            </ButtonComponent>
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="redirectToCompanyIndex()"
        action-text="Confirm"
        title="Corporate Booking Submitted!"
        content="Corporate booking has been submitted successfully"
        />

        <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; submitBooking()"
        cancel-text="Cancel"
        action-text="Confirm"
        title="Submit Corporate Booking?"
        content="Are you sure you wish to submit this corporate booking?"
        />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DropzoneInput from '@/components/inputs/DropzoneInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';

import { ref, watch, onMounted, computed } from 'vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import BookingService from '@/classes/BookingService';
import BranchService from "@/classes/Branch";
import CompanyService from '@/classes/Company';
import { formatter } from "@/helpers/NumberHelper";
import { format } from 'date-fns';
import { useRouter, useRoute } from 'vue-router';
import { daysOfTheWeek } from '@/helpers/PackageRiderHelper'
import { 
    Employee,
    OtherChargesLinePayload,
    CreateCorporateBookingPayload,
    formattedPackage,
    unformattedPackage,
    formattedRider,
    riderGroup,
    filteredRider,
    medicalPackage,
    medicalRider,
} from '@/interfaces/CorporateBookingInterface'

const router    = useRouter();
const route     = useRoute();
const branchId  = ref(Number(route.params.branchId) as unknown as number);
const companyId = ref(Number(route.params.companyId) as unknown as number);

const noAction         = ref(false)
const loader           = ref(false)
const showError        = ref(false)
const errorMsg         = ref()
const errors      = ref([])
const showSuccess      = ref(false)
const showConfirmation = ref(false)
const csvFile          = ref();

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        count: 0,
        href: `branches/${branchId.value}/companies/edit/${companyId.value}`,
        allowed: true
    },
    {
        name: 'Booking',
        count: 0,
        href: `branches/${branchId.value}/companies/booking/${companyId.value}`,
        allowed: true
    }
];

const headers: { text: string }[] = [
    { text: 'First Name' },
    { text: 'Middle Name' },
    { text: 'Last Name' },
    { text: 'Suffix' },
    { text: 'Email' },
    { text: 'Gender' },
    { text: 'Birthday' },
];

const sexes = [
    { id: '1', label: 'Male', value: 'Male' },
    { id: '2', label: 'Female', value: 'Female' },
    // { id: '3', label: 'Prefer not to Say', value: 'Prefer not to Say' },
];

const employeeDefaultValues = {
    cellphone_number : "TBA",
    telephone_number : "TBA",
    civil_status     : "TBA",
    nationality      : "TBA",
    religion         : "TBA",
    job_title        : "TBA",
    birthplace       : "TBA",
    building_number  : "TBA",
    street           : "TBA",
    barangay         : "TBA",
    city             : "TBA",
    province         : "TBA",
    region           : "TBA",
    zip_code         : "TBA",
    philhealth_number: "000000000"
};

const packages               = ref<medicalPackage[]>([]);
const riders                 = ref<medicalRider[]>([]);
const selectedRiders         = ref([]);
const selectedPackage        = ref(null);
const selectedSlot           = ref(null);
const riderGroups            = ref<riderGroup[]>([]);
const unformattedPackages    = ref<unformattedPackage[]>([]);
const employeeCount          = ref(0);
const rider_names            = ref([]);
const timeSlots              = ref([]);

// const unformattedBookingDate = ref(add(new Date, {days: 3}));


const fullyBookedDates = ref([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const timezoneOffset = 8; // Philippines time zone is UTC+8
const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000 + timezoneOffset * 60 * 60 * 1000); // For 3 days advance booking

const minDate = computed(() => threeDaysLater.toISOString().slice(0, 10));
const from = ref(null);
const to = ref(null);

const selectedDateRef = ref<any>(null)
const selectedDate = ref('')

const employee = ref<Employee>({
    suffix       : null,
    first_name   : null,
    middle_name  : null,
    last_name    : null,
    email_address: null,
    sex          : null,
    birthdate    : null,
})

const payload = ref<CreateCorporateBookingPayload>({
    corporate_id      : companyId.value,
    branch_id         : branchId.value,
    branch_name       : null,
    branch_address    : null,
    corporate_name    : null,
    package_id        : null,
    package_fee       : 0,
    other_charges_line: [],
    other_charges_fee : null,
    booking_date      : null,
    booking_time_start: null,
    booking_time_end  : "11:59:59.000000",
        // Or other known as the corporate patients
    employee_list: [],
    riders: [],
    package_name: null
});

const formatPackages = () => {
    unformattedPackages.value.forEach(unformattedPackage => {
        const formattedPackage: formattedPackage = {
            id   : null,
            label: null,
            value: null,
            disabledDates: null,
        };

        formattedPackage.id    = unformattedPackage.id;
        formattedPackage.value = unformattedPackage.id;
        formattedPackage.label = unformattedPackage.name + ' - ' + formatter.format(unformattedPackage.price);
        formattedPackage.disabledDates = unformattedPackage.disabledDates;
        packages.value.push(formattedPackage);
    });
}

const formatRiders = () => {
    riderGroups.value.forEach(riderGroup => {
        riderGroup.items.forEach(unformattedRider => {
            const formattedRider: formattedRider = {
                id   : null,
                label: null,
                value: null,
                disabledDates: null,
                name: null,
            };
    
            formattedRider.id    = unformattedRider.item_id;
            formattedRider.value = unformattedRider.item_id;
            formattedRider.label = unformattedRider.item_name + ' - ' + formatter.format(unformattedRider.item_price);
            formattedRider.name = unformattedRider.item_name;
            formattedRider.disabledDates = unformattedRider?.disabledDates || [];

            riders.value.push(formattedRider);
        });
    });
}

const isEmailValid = (email: string) => {
    const reg = /[^\s@]+@[^\s@]+\.[^\s@]+/;

    if(email) {
        return reg.test(email) ? true : false;
    }
    
    return false;
}

const validateEmployee = () => {
    if(
        employee.value.first_name    == null ||
        employee.value.last_name     == null ||
        employee.value.email_address == null ||
        employee.value.sex           == null ||
        employee.value.birthdate     == null
    ) {
        showError.value = true;
        errorMsg.value  = "Please fill up all the the patient fields.";
    } else if(!isEmailValid(employee.value.email_address)) {
        showError.value = true;
        errorMsg.value  = "Please input a valid email address";
    } else {
        addEmployee();
    }
}

const addEmployee = () => {
    const birthdate = new Date(employee.value.birthdate).toISOString().split('T')[0];
    const employeeDetails = {
        suffix       : employee.value.suffix,
        first_name   : employee.value.first_name,
        middle_name  : employee.value.middle_name,
        last_name    : employee.value.last_name,
        email_address: employee.value.email_address,
        sex          : employee.value.sex,
        birthdate    : birthdate,
    };
    
    payload.value.employee_list.push(Object.assign(employeeDetails, employeeDefaultValues));

    employeeCount.value++;
    
    resetEmployeeFields();
}

const removeEmployee = (index: number) => {
    payload.value.employee_list.splice(index, 1);
    employeeCount.value--;
};

const resetEmployeeFields = () => {
    const keys = Object.keys(employee.value);
    keys.forEach(key => employee.value[key] = null);
}

const fetchPackagesAndRiders = () => {
    loader.value = true

    BookingService.getPackagesAndRiders()
        .then((result: any) => {
            loader.value = false;
            unformattedPackages.value = result.data.packageResponse;

            const services = result.data.serviceResponse;
            const allergyTests = result.data.allergyTestResponse;
            riderGroups.value = services.concat(allergyTests);

            formatPackages();
            formatRiders();
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const fetchBranch = async () => {
    try {
        loader.value = true;
        const response = await BranchService.editBranch(branchId.value)
        loader.value = false;
        const branchResponse = response.data.branch;

        if(branchResponse) {
            payload.value.branch_name = branchResponse.name;
            payload.value.branch_address = branchResponse.address_line;
        }
    }
    catch(error: any) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = error.response.data.message;
    }
}

const redirectToCompanyIndex = () => {
    showSuccess.value = false;
    router.push(`/branches/${branchId.value}/companies/`);
}

const fetchCompany = async() => {
    try {
        loader.value = true;
        const response = await CompanyService.getSelectedCompany(companyId.value)
        loader.value = false;
        const companyResponse = response.data.company;

        if(companyResponse) {
            payload.value.corporate_name = companyResponse.name;
        }
    }
    catch(error: any) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = error.response.data.message;
    }
}

const submitBooking = () => {
    loader.value = true;

    payload.value.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];
    console.log(payload.value);
    BookingService.submitCorporateBooking(payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                showSuccess.value = true;
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const pushRiderInPayload = (filteredRiders: filteredRider[]) => {
    let riderPrice = 0;
    const rider_item_ids: number[] = [];
    rider_names.value = [];

    filteredRiders.forEach(rider => {
        const formattedRider: OtherChargesLinePayload = {
            ItemID: null,
            Price: null,
        };

        formattedRider.ItemID = rider.item_id;
        formattedRider.Price = rider.item_price;
        riderPrice += rider.item_price;
        payload.value.other_charges_line.push(formattedRider);

        rider_item_ids.push(rider.item_id)
    });

    riderGroups.value.forEach(rider => {
        const item = rider.items.filter(obj => rider_item_ids.includes(obj.item_id));
        if(item.length > 0) {
            item.forEach(a => {
                rider_names.value.push(a.item_name);
            });
        }
    });

    payload.value.other_charges_fee = riderPrice;
    payload.value.riders = rider_names.value;
}

const selectRiders = () => {
    payload.value.other_charges_line = [];

    let filteredRiders: filteredRider[] = [];

    riderGroups.value.forEach(rider => {
        filteredRiders = filteredRiders.concat(rider.items.filter(filteredRider => selectedRiders.value.includes(filteredRider.item_id)));
    });
    
    pushRiderInPayload(filteredRiders);
}

const clearPackagePayload = () => {
    payload.value.package_fee = 0;
    payload.value.package_id = null;
    
    selectedDateRef.value?.datePicker.clearValue()
}

const selectPackage = () => {
    clearPackagePayload();

    const filteredPackage = unformattedPackages.value.filter(unformattedPackage => selectedPackage.value == unformattedPackage.id);
    
    if(filteredPackage){
        payload.value.package_id = filteredPackage[0]?.id;
        payload.value.package_fee = filteredPackage[0]?.price || 0;
        payload.value.package_name = filteredPackage[0]?.name;
    }
}

const getDisabledDates = computed<number[]>(():number[] => {
    let disabledDates:number[] = [];
    
    if(payload.value.package_id){
        const index = packages.value.findIndex( item => item.id == payload.value.package_id );
        
        disabledDates = [...new Set([...disabledDates, ...packages.value[index].disabledDates])]
    }

    if(payload.value.riders){

        payload.value.riders.forEach(rider => {
            const index = riders.value.findIndex(item => item.name == rider);
            disabledDates = [...new Set([...disabledDates, ...riders.value[index].disabledDates])]
        });
    }

    return disabledDates;
});

const isDateValid = (dateString: string) => {
    const timestamp = Date.parse(dateString);
    if (isNaN(timestamp)) {
        return false;
    } else {
        return true;
    }
}

const pushEmployeesFromCsv = () => {
    if(csvFile.value) {
        // FileReader Object
        const reader = new FileReader();
        // Read file as string 
        reader.readAsText(csvFile.value);

        // Load event
        reader.onload = function(event) {

            // Read file data
            const csvdata = event.target.result;

            // Split by line break to gets rows Array
            const rowData = csvdata.split('\n');

            let invalidPatientCount = 0;

            // Loop on the row Array (change row=0 if you also want to read 1st row)
            for (let row = 1; row < rowData.length; row++) {

                // Split by comma (,) to get column Array
                const rowColData = rowData[row].split(',');
                
                if(isDateValid(rowColData[6])) {
                employeeCount.value++;

                const employeeDetails = {
                    first_name   : rowColData[0],
                    middle_name  : rowColData[1],
                    last_name    : rowColData[2],
                    suffix       : rowColData[3],
                    email_address: rowColData[4],
                    sex          : rowColData[5],
                    birthdate    : rowColData[6],
                };

                payload.value.employee_list.push(Object.assign(employeeDetails, employeeDefaultValues));
                } else {
                    invalidPatientCount++;
                }
            }

            if(invalidPatientCount > 0) {
                showError.value = true;
                errorMsg.value = `${invalidPatientCount} row/s were not uploaded due to incorrect birth date values`;
            }
        };
    }
}

const fetchDateAndTime = () => {
    if (selectedDate.value) {
        loader.value = true
        
        const data = {
            branch_id: payload.value.branch_id,
            selected_date: selectedDate.value,
            from: from.value,
            to: to.value,
            package: payload.value.package_name,
            riders: rider_names.value,
        }
    
        BookingService.fetchDateAndTime(data)
            .then((result: any) => {
                loader.value = false;
                timeSlots.value = result.data.response
                fullyBookedDates.value = result.data.response.fully_booked_dates;
            })
            .catch((error) => {
                loader.value = false;
                showError.value = true;
                errorMsg.value = error.response.data.message;
    
                if(error.response.status == 401) {
                    window.location.replace('/login')
                }
            })
    }
}

const updateMonth = (val:any = null) => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch fetchDateAndTime for Active Month
    fetchDateAndTime();
}

const firstDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

const setStartEndTime = (value:string) => {
    const [start, end] = value.split('-');

    payload.value.booking_time_start = stringToTime(start);
    payload.value.booking_time_end = stringToTime(end);
}

// This function takes a string in the format of "h:mm AM/PM" and returns a string in the format of "hh:mm:ss"
const stringToTime = (str:string): string => {
    // Split the string by space and colon
    const time:string[] = str.split(/[: ]/);
    // Convert hour and minute to numbers
    let hour:number = parseInt(time[0]);
    const minute:number = parseInt(time[1]);
    const period:string = time[2];
    // Check if the period is valid
    if (period !== "AM" && period !== "PM") {
        return "Invalid period";
    }
    // Check if the hour and minute are valid
    if (hour < 1 || hour > 12 || minute < 0 || minute > 59) {
        return "Invalid time";
    }
    // Convert hour to 24-hour format
    if (period === "PM" && hour < 12) {
        hour += 12;
    }
    if (period === "AM" && hour === 12) {
        hour = 0;
    }
    // Pad hour and minute with zeros if needed
    const newHour:string = hour.toString().padStart(2, "0");
    const newMinute:string = minute.toString().padStart(2, "0");
    // Return the time in the format of "hh:mm:ss"
    return `${newHour}:${newMinute}:00`;
}

const resetDateTime = (): void => {
    selectedDate.value = null;
    selectedSlot.value = null;

    selectedDateRef.value?.datePicker.clearValue()

    fetchDateAndTime()
}

watch(
    () => selectedRiders.value,
    (val) => {
        if (val) {
            selectRiders();
        }

        resetDateTime()
    },
    { immediate: true }
);

watch(
    () => selectedPackage.value,
    (val) => {
        if (val) {
            selectPackage();
        } else {
            payload.value.package_id = null;
            payload.value.package_fee = 0;
            payload.value.package_name = '';
        }

        resetDateTime()
    },
    { immediate: true }
);

watch(
    () => csvFile.value,
    () => {
        pushEmployeesFromCsv()
    },
    { immediate: true }
);

watch(
    () => selectedDate.value,
    (val) => {
        if(val){      
            const selectedDate = new Date(val);
    
            updateMonth({
                year: selectedDate.getFullYear(),
                month: selectedDate.getMonth()
            })
        }
    },
    { immediate: true }
);


onMounted(() => {
    fetchPackagesAndRiders();
    fetchCompany();
    fetchBranch();
    // fetchDateAndTime();
})
</script>