<template>
    <admin-layout 
    title="View Patient"
    show-back
    backUrl="/accounts/patients">
        <PatientLayout :patientId="patientId">
            <template #contents>
                <div class="p-7">
                    <FormSection>
                        <template #title>
                            Patient Information
                        </template>

                        <template #description>
                            Relevant data and other details
                        </template>

                        <template #form>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.first_name"
                                    id="first_name"
                                    name="first_name"
                                    disabled
                                    label="First Name"
                                    placeholder="First Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.middle_name"
                                    id="middle_name"
                                    name="middle_name"
                                    disabled
                                    label="Middle Name"
                                    placeholder="Middle Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.last_name"
                                    id="last_name"
                                    name="last_name"
                                    disabled
                                    label="Last Name"
                                    placeholder="Last Name"
                                />
                            </div>
                            <div class="col-span-12">
                                <TextInput 
                                    v-model="form.email_address"
                                    id="email_address"
                                    name="email_address"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    :disabled="!isNetworkAvailable"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.sex"
                                    id="sex"
                                    name="sex"
                                    disabled
                                    label="Gender"
                                    placeholder="Gender"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.birthdate"
                                    id="birthdate"
                                    name="birthdate"
                                    disabled
                                    label="Birthday"
                                    placeholder="Birthday"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.cellphone_number"
                                    id="cellphone_number"
                                    name="cellphone_number"
                                    disabled
                                    label="Mobile Number"
                                    placeholder="Mobile Number"
                                />
                                <!-- <MobileNumber 
                                    v-model="form.cellphone_number"
                                    disabled
                                    label="Mobile Number"
                                    placeholder="Mobile Number"
                                /> -->
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.patient_id"
                                    id="patient_id"
                                    name="patient_id"
                                    disabled
                                    label="Patient ID"
                                    placeholder="Patient ID"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.hospital_no"
                                    id="hospital_no"
                                    name="hospital_no"
                                    disabled
                                    label="Hospital ID"
                                    placeholder="Hospital ID"
                                />
                            </div>
                        </template>

                        <template #actions v-if="isNetworkAvailable">
                            <ButtonComponent @click="submit">
                                Save Changes
                            </ButtonComponent>
                        </template>
                    </FormSection>
                </div>
            </template>
        </PatientLayout>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Patient"
            content="Patient Email updated Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import PatientLayout from './components/PatientLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
// import MobileNumber from '@/components/inputs/MobileNumber.vue';
import PageLoader from '@/components/PageLoader.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import Patient from '@/classes/Patient';
import { useRoute } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import { useNetworkStore } from '@/store/network';
import { onIonViewWillEnter } from '@ionic/vue';

const route       = useRoute();
const loader      = ref(true)
const errors      = ref([]);
const showError   = ref(false);
const errorMsg    = ref(false);
const form        = ref({})
const showSuccess = ref(false);

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const patientId = ref(route.params.id);

const submit = () => {
    loader.value      = true;

    Patient.updatePatient(form.value)
    .then(() => {
        loader.value      = false;
        showSuccess.value = true
    })
    .catch((err) => {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response.data.message;

        if(err.response.status == 422) {
            errors.value = err.response.data.errors;
        }
    })
}

const showPatient = () => {
    Patient.showPatient(patientId.value).then((response) => {
        loader.value = false;
        Object.assign(form.value, response.data)
    })
    .catch((err) => {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response.data.message;
    })
}

onMounted(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        showPatient();
    }, 1000);
})

onIonViewWillEnter(() => {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        showPatient();
    }, 1000);
})

</script>