<template>
    <admin-layout title="Admins">
        <div>
            <TabComponent
                :tabs="tabs"
                :button-items="true"
            >
                <template #buttons>
                    <ButtonComponent
                        class="text-gray-900"
                        secondary
                        exportBtn
                        @click="exportData"
                        :disabled="loader"
                    >
                        Export
                    </ButtonComponent>
                    <ButtonComponent
                        v-if="$hasPermission('create-admin-account') || $hasPermission('register-for-an-account')"
                        createBtn
                        @click="router.push(`/accounts/admins/create`)"
                    > 
                        Create
                    </ButtonComponent>
                </template>
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6">
            <FilterComponent
                :search="searchText"
                @fetchData="searchData"
                :text-filters="currentRoutePath !== '/accounts/admins/logs'"
                @update:filters="applyFilters"
                :custom-filters="activeTab !== 'activity_logs'"
                :sidebar-filters="false"
            >
            </FilterComponent>
        </div>
        <div class="" v-if="currentRoutePath !== '/accounts/admins/logs'">
            <DataTable 
                :headers="headers" 
                :count="items?.total"
            >
                <template #body>
                    <tr v-for="item in items?.data" :key="item.id">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-8/12">
                            <div>
                                <p>{{ item.name}}</p>
                                <p class="text-gray-500">{{ item.email }}</p>
                            </div>
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.roleName }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ item.created_at }}
                        </td>
                        <td class="text-center whitespace-nowrap">
                            <ActionButton
                                v-if="$hasPermission('update-admin-account') || $hasPermission('register-for-an-account')"
                                edit-btn
                                @click="router.push(`/accounts/admins/show/${item.id}`)"
                            />
                            <ActionButton 
                                v-if="getLoggedAdmin.id != item.id && !item.deleted_at && $hasPermission('archive-admin-account')"
                                delete-btn
                                @click="selectedAdmin(item.id)"
                            />
                            <ActionButton 
                                v-if="item.deleted_at && $hasPermission('restore-admin-account')"
                                restore-btn
                                @click="restoreAdmin(item.id)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexAdmins"
            />
        </div>
        <div v-else>
            <ActivityLogsTable
                :logs="logs"
                @fetch-index="indexAdmins"
            />
        </div>

        <PageLoader v-if="loader" />

        <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; archiveAdmin() "
            cancel-text="Cancel"
            action-text="Confirm"
            title="Archive Admin ?"
            content="Are you sure you wish to archive this admin?"
        />

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Admin Export"
            content="Export is being processed. Please check your registered email."
        />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from '@/components/PageLoader.vue';
import PagePagination from '@/components/PagePagination.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ActivityLogsTable from '@/components/ActivityLogsTable.vue';

import throttle from "lodash/throttle";
import AdminData from '@/classes/AdminData';
import { useRouter } from 'vue-router';
import { paginatePage } from '@/helpers/PageHelper';
import { onMounted, ref, computed, watch } from 'vue';

const router = useRouter();
// Data
const loader           = ref(false);
const showError        = ref(false);
const errorMsg         = ref(null);
const seletected       = ref();
const showConfirmation  = ref(false);
const showSuccess      = ref(false);

const currentRoutePath = computed(() => router.currentRoute.value.path);
const getLoggedAdmin = computed(() => AdminData.getAdmin()) 

// Query
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();
const tab        = ref('all');
// Page Renderer Data
const items           = ref(null);
const items_count     = ref(null);
const rows            = ref(null);
const query           = ref(null);
const selected_tab    = ref('all');
const filterDate       = ref(null);
const log_event       = ref(null);
const log_query       = ref(null);
const log_date        = ref(null);
const active_count    = ref(0);
const archived_count  = ref(0);
const log_counts      = ref(0);
const logs            = ref({});
const log_event_lists = ref(null);


const tabs: { name: string, count: number, href: string, allowed: boolean }[] = computed(() => [
    {
        name: 'Active',
        count: active_count.value,
        href: "/accounts/admins",
        allowed: true
    },
    {
        name: 'Archived',
        count: archived_count.value,
        href: "/accounts/admins/archived",
        allowed: true
    },
    {
        name: 'Activity logs',
        count: log_counts.value,
        href: "/accounts/admins/logs",
        allowed:
            window.$hasPermission('view-activity-logs')
    }
]);

const headers: { text: string }[] = [
    { text: 'Admin' },
    { text: 'Role' },
    { text: 'Date Created'}
];

const applyFilters = () => {
    indexAdmins(goToPage.value)
}

const searchData = (val: string) => {
    searchText.value = val
}

/**
 * ********************************
 * Fetch Admin 
 * ********************************
 */
 const indexAdmins = async (data: string) => {
    try {
        loader.value = true;
        const queryString     = searchText.value ? `&query=${searchText.value}` : 'query';
        const dateQuery       = filterDate.value ? `&filterDate=${filterDate.value}` : '';
        const isArchivedRoute = currentRoutePath.value === '/accounts/admins/archived';
        const tabValue        = isArchivedRoute ? 'archived' : tab.value;
        const goToPageValue   = paginatePage(data);

        // const response = await AdminData.indexAdmin(`${goToPageValue}&tab=${tabValue}&${queryString}`);
        const response = await AdminData.indexAdmin(`${goToPageValue}&tab=${tabValue}&${queryString}&${dateQuery}`)
       
        loader.value          = false;
        items.value           = response?.data.items;
        items_count.value     = response?.data.items_count
        rows.value            = response?.data.rows
        query.value           = response?.data.query
        selected_tab.value    = response?.data.selected_tab
        log_event.value       = response?.data.log_event
        log_query.value       = response?.data.log_query
        log_date.value        = response?.data.log_date
        active_count.value    = response?.data.active_count
        archived_count.value  = response?.data.archived_count
        log_counts.value      = response?.data.log_counts
        logs.value            = response?.data.logs
        log_event_lists.value = response?.data.log_event_lists

    } catch(err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err?.response?.data?.message;

        if (err?.response?.data?.status === 401) {
            window.location.replace('/login');
        }

    }
};

/**
 * ********************************
 * Archive Admin 
 * ********************************
 */
const selectedAdmin = (id: string) => {
    showConfirmation.value = true
    seletected.value = id
}

const archiveAdmin = async () => {
  try {
    loader.value = true;
    await AdminData.deleteAdmin(seletected.value);
    loader.value = false;
    window.location.replace('/accounts/admins/archived');
  } catch (err) {
    loader.value    = false;
    showError.value = true;
    errorMsg.value  = err?.response?.data.message;
  }
};

/**
 * ********************************
 * Restore Admin 
 * ********************************
 */

 const restoreAdmin = async (id: string) => {
  try {
    loader.value = true;
    await AdminData.restoreAdmin(id);
    loader.value = false;
    window.location.replace('/accounts/admins')
  } catch (err) {
    loader.value    = false;
    showError.value = true;
    errorMsg.value  = err?.response?.data.message;
  }
};

/**
 * ********************************
 * Export Admin 
 * ********************************
 */
 const exportData = async () => {
  try {
    loader.value = true;
    const params = currentRoutePath.value === '/accounts/admins/archived' ? { tab: 'archived' } : {};

    await AdminData.exportAdmin(params);
    showSuccess.value = true;
  } catch (error) {
    showError.value = true;
    errorMsg.value  = error.response?.data.message;
  } finally {
    loader.value = false;
  }
};

watch(
      searchText,
      throttle(() => {
        indexAdmins(goToPage.value)
      }, 1000)
    );

onMounted(() => indexAdmins(goToPage.value))

</script>