<template>
    <admin-layout title="Packages">
        <!-- <div>
            <TabComponent :tabs="tabs" />
        </div> -->
        <!-- Filter -->
        <div class="px-6 py-3">
            <FilterComponent
                :search="searchText"
                @fetchData="searchData"
                @update:filters="indexPackages"
                :modelValue="searchText"
                :sidebarFilters="false"
                :custom-filters="activeTab !== 'activity_logs'"
            >
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
                :headers="headers" 
                :count="items?.total"
            >
                <template #body>
                    <tr v-for="item in items?.data" :key="item.id">
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.name }}
                        </td>
                        <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.type }}
                        </td>
                        <!-- <td class="px-6 py-3 text-sm text-gray-900 whitespace-nowrap">
                            Jan 19, 2023 (00:00:00)
                        </td> -->
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push('/contents/packages/show/' + item.name )"/>
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexPackages"
            />
        </div>
        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import PageLoader from '@/components/PageLoader.vue';
import PagePagination from '@/components/PagePagination.vue';
import throttle from "lodash/throttle";

import Packages from '@/classes/Packages';
import { useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';

/**
 * ********************************
 * Data 
 * ********************************
 */
const router    = useRouter();
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

/**
 * ********************************
 * query 
 * ********************************
 */
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();

// Page Renderer Data
const items       = ref(null);
const items_count = ref(0);
const query       = ref(null);

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Active',
        count: items_count.value,
        href: '/contents/packages',
        allowed: true
    }
];

const headers: { text: string }[] = [
    { text: 'Package' },
    { text: 'Package Type' },
    // { text: 'Date Created' },
];

/**
 * ********************************
 * Methods 
 * ********************************
 */
const searchData = (val: string) => {
    searchText.value = val
}

const indexPackages = async (data: string) => {
    try {
        loader.value = true;

        const pageNum     = data?.match(/\d+/)?.[0] ?? '1';
        const queryString = searchText.value ? `&query=${searchText.value}` : '';
        const goToPage    = data ? `page=${pageNum}` : 'page=1';

        const response = await Packages.indexPackages(goToPage + queryString);

        loader.value      = false;
        items.value       = response.data;
        items_count.value = response.data.total;
        query.value       = response.data.query;

    } catch (err) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response?.data?.message ?? 'An error occurred';
    }
};

/**
 * ********************************
 * Wathchers 
 * ********************************
 */
watch(
      searchText,
      throttle(() => {
        indexPackages(goToPage.value)
      }, 1000)
    );

onMounted(() => indexPackages(goToPage.value))

</script>