<template>
    <div>
        <div
            class="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between lg:px-6"
        >
            <div class="flex min-w-0">
                <button
                    v-if="showBack"
                    @click="goBack"
                    class="border border-transparent text-xs text-gray-600 hover:text-gray-300 focus:outline-none mr-4"
                >
                    <ChevronLeftIcon class="h-5 w-5" />
                </button>
                <h1 class="text-lg leading-6 text-gray-900 truncate">
                    {{ title }}
                </h1>
            </div>
            <div class="mt-4 flex sm:mt-0 sm:ml-4">
                <div class="flex" aria-label="HeaderMenu">
                    <div class="flex items-center space-x-6">
                        <div v-for="item in navigation" :key="item">
                            <div v-if="item.allowed" class="">
                                <a 
                                :href="item.href"
                                class="relative"
                                :class="[
                                    currentRoutePath == item.href
                                    ? 'text-primary-700'
                                    : 'text-gray-500 hover:text-gray-400 dark:text-white'
                                ]"
                                >
                                    <img
                                    :src="item.icon"
                                    :class="[ currentRoutePath == item.href ? '' : 'opacity-40', 'h-7 w-7' ]"
                                    />
                                    <span
                                    class="bg-black w-full h-1 rounded-tl rounded-tr absolute -bottom-4"
                                    v-if="currentRoutePath == item.href"
                                    />
                                </a>
                            </div>
                        </div>
                        <div @click="logout" v-if="isNetworkAvailable">
                            <div class="cursor-pointer">
                                <img
                                src="/assets/icons/nav/ic-nav-logout.svg"
                                class="w-7 h-7"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon } from "@heroicons/vue/24/solid";
import { useRouter } from 'vue-router';
import AuthService from "@/classes/AuthService";
import AdminData from '@/classes/AdminData';
import { computed } from 'vue';
import { useNetworkStore } from '@/store/network';

const router = useRouter();
const networkStore = useNetworkStore();

const currentRoutePath = router.currentRoute.value.path;
const getLoggedAdmin = computed(() => AdminData.getAdmin()) 
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const props = defineProps ({
    title: String,
    backUrl: {
        type: String,
        default: null,
    },
    showBack: {
        type: Boolean,
        default: false,
    },
});

const goBack = () => {
    const url = props.backUrl;
    router.replace(url);
}

interface generalNavType {
    name: string,
    href: string,
    icon: any,
    allowed: boolean
}

const navigation: generalNavType[] = computed(() =>[
    {
        name: "Profile",
        href: getLoggedAdmin.value ? `/profile/${getLoggedAdmin?.value.id}` : '',
        icon: "/assets/icons/nav/ic-nav-user.svg",
        allowed:
            isNetworkAvailable.value &&
            window.$hasPermission('update-account-profile')
    },
    {
        name: "Notification",
        href: "/notifications",
        icon: "/assets/icons/nav/ic-nav-notification.svg",
        allowed:
            isNetworkAvailable.value &&
            window.$hasPermission('receive-system-notifications')
    }
]);

const logout = () => {
    AuthService.logout()
}

</script>