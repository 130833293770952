<template>
    <FormSection>
        <template #title>
            Company Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-12">
                <TextInput
                    v-model="payload.name"
                    id="name"
                    name="name"
                    label="Company Name"
                    placeholder="Company Name"
                    :error="errors.name ? errors.name[0] : ''"
                />
            </div>
            <div class="col-span-12">
                <TextInput
                    v-model="payload.address_line"
                    id="address_line"
                    name="address_line"
                    label="Address Line"
                    placeholder="Address Line"
                    :error="errors.address_line ? errors.address_line[0] : ''"
                />
            </div>
            
            <div class="col-span-6">
                <MultiselectInput
                    id="regions"
                    name="regions"
                    label="Region"
                    v-model="payload.region_code"
                    placeholder="Region"
                    :options="regions"
                    searchable
                    @click="$emit('clearProvince')"
                    :error="errors.region_code ? errors.region_code[0] : ''"
                />
            </div>

            <div class="col-span-6">
                <MultiselectInput
                    id="province"
                    name="province"
                    label="Province"
                    v-model="payload.province_code"
                    placeholder="Province"
                    :options="provinces"
                    searchable
                    @click="$emit('clearCity')"
                    :error="errors.province_code ? errors.province_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <MultiselectInput
                    id="city"
                    name="city"
                    label="City"
                    v-model="payload.city_code"
                    placeholder="City"
                    :options="cities"
                    searchable
                    @click="$emit('clearBgy')"
                    :error="errors.city_code ? errors.city_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <MultiselectInput
                    id="barangay"
                    name="barangay"
                    label="Barangay"
                    v-model="payload.barangay_code"
                    placeholder="Barangay"
                    :options="barangays"
                    searchable
                    :error="errors.barangay_code ? errors.barangay_code[0] : ''"
                />
            </div>
            
            <div class="col-span-6">
                <TextInput
                    v-model="payload.zip_code"
                    id="zip_code"
                    name="zip_code"
                    label="Zip Code"
                    placeholder="Zip Code"
                    :error="errors.zip_code ? errors.zip_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <text-input
                    id="mobile_number"
                    name="mobile_number"
                    label="Mobile Number"
                    :add-on-left="true"
                    add-on-text="+63"
                    @keypress="numberonly"
                    placeholder="Mobile Number"
                    maxlength="10"
                    v-model="payload.mobile_number"
                    :error="errors.mobile_number ? errors.mobile_number[0] : ''"
                />
            </div>
        </template>
        <template #actions>
            <slot />
        </template>
    </FormSection>
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import { ref } from 'vue';
import { numberonly } from '@/helpers/NumberHelper';


const props = defineProps ({
    form: {
        type: Object,
    },
    errors: {
        type: Array,
        default: () => []
    },
    regions: {
        type: Array,
        default: () => []
    },
    provinces: {
        type: Array,
        default: () => []
    },
    cities: {
        type: Array,
        default: () => []
    },
    barangays: {
        type: Array,
        default: () => []
    }
});

const payload = ref(props.form)
</script>