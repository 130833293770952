<template>
    <ion-app>
        <ion-router-outlet animated="false"/>

        <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Confirm"
        title="Hoooray!"
        content="Internet Connection has been restored."
        />

        <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        >
            <template #content>
                Oppps... No Internet Connection.
            </template>
        </ErrorModal>

    </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet, onIonViewWillEnter } from '@ionic/vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';

import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import AuthService from "@/classes/AuthService";
import { useNetworkStore } from '@/store/network';
import { ref, watch } from 'vue';

const router = useRouter();
const networkStore = useNetworkStore();

const showError        = ref(false)
const showSuccess = ref(false);

const checkAuthSession = () => {
    const onAuthRoute = router.currentRoute.value.meta.needsAuth
    if(!AuthService.isAuthenticated() && onAuthRoute) {
        AuthService.logout()
    }
}

const setNetworkAvailability = () => {
    // console.log(router.currentRoute.value.name)
    // console.log(networkStore.isNetworkAvailable)
    if(!networkStore.isNetworkAvailable) {
        // showSuccess.value = false;
        // showError.value = true;
        if(
            router.currentRoute.value.name !== 'BranchIndex' &&
            router.currentRoute.value.name !== 'BookingIndex' &&
            router.currentRoute.value.name !== 'BookingEdit' &&
            router.currentRoute.value.name !== 'PatientsIndex' &&
            router.currentRoute.value.name !== 'PatientsShow' &&
            router.currentRoute.value.name !== 'PatientBookingIndex' &&
            router.currentRoute.value.name !== 'PatientBookingShow'
        ) {     
            // Redirect to Branch Managmeent
            router.replace('/branches');
        }
    } else {
        showError.value = false;
        showSuccess.value = true;
        setTimeout(() => {
            window.location.reload();
        }, 1000)
    }
}

onMounted( () => {
    // setNetworkAvailability();
    checkAuthSession();
})

onIonViewWillEnter(() => {
    // setNetworkAvailability();
})

watch(
    () => networkStore.isNetworkAvailable,
    (newValue, oldValue) => {
        console.log("New: "+newValue)
        console.log("Old: "+oldValue)
        setNetworkAvailability();
    }
);

</script>
