<template>
    <ConfirmationModal
        :show="show"
        @cancel="close"
        @confirm="rescheduleBooking()"
        cancel-text="Cancel"
        action-text="Submit"
        title="Reschedule Appointment?"
        content="Are you sure you wish to reschedule this appointment?">
            <template #fields>
                <p class="mb-4 -mt-2 text-xs font-bold text-left text-red-600">WARNING: Canceling/Rescheduling a Company Booking will affect <br/> all bookings associated with it.</p>
                <div class="grid grid-cols-2 gap-5 text-left">
                    <div class="col-span-6">
                        <TextInput
                            v-model="payload.remarks"
                            label="Reason"
                            placeholder="Reason"
                        />
                    </div>

                    <div class="col-span-6">
                        <DatePicker
                            v-model="payload.booking_date"
                            label="Booking Date"
                            placeholder="MM/DD/YYYY"
                            :min-date="new Date()"
                            hide-offset-dates
                            highlight-disabled-days
                            @updateMonth="updateMonth"
                            teleport-center
                            :disabledWeekDays="Object.values(disabledDates)"
                        />
                    </div>
                    
                    <div class="col-span-6">
                        <MultiselectInput
                            v-model="selectedSlot"
                            id="selectedSlot"
                            name="selectedSlot"
                            label="Time Slots"
                            placeholder="Time Slots"
                            searchable
                            :options="timeSlots"
                            @update:modelValue="setStartEndTime"
                        />  
                    </div>
                </div>
        </template>
    </ConfirmationModal>

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="close()"
        @confirm="showError = false"
        :errors="errors"
    >
        <template #content>
            <p v-html="errorMsg"/>
        </template>
    </ErrorModal>

    <PageLoader v-if="loader" />
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import PageLoader from "@/components/PageLoader.vue";

import BookingService from '@/classes/BookingService';
import { daysOfTheWeek } from '@/helpers/PackageRiderHelper';

const emit = defineEmits(['close', 'success']);


const props = defineProps({
    show: {
        type: Boolean,
        defualt: false,
    },
    bookingId: {
        type: Number,
        required: true
    },
    branchId: {
        type: Number,
        required: true
    },
    bookingType: {
        type: String,
        required: true
    },
    bookingDate: {
        type: String,
        required: true
    },
    package: {
        type: String,
        required: true
    },
    riders: {
        type: Object,
        default: () => ({})
    },
});


const show = ref<boolean>(props.show)
const loader = ref<boolean>(true)
const showError = ref<boolean>(false)
const errorMsg = ref<string>('')
const errors = ref<any[]>([])

const from = ref<string>('')
const to = ref<string>('')
const selectedSlot = ref<string>('')
const timeSlots = ref<any[]>(daysOfTheWeek.map((date) => date.value))
const disabledDates = ref<any[]>([])

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();

interface IndividualReschedulePayloadInterface {
    booking_id : number,
    branch_id : number,
    booking_date: string,
    booking_start_time: string,
    booking_end_time : string,
    remarks: string,
    updated_by: string 
}

interface CorporateReschedulePayloadInterface {
    corporate_booking_id : number,
    new_booking_date: string,
    new_booking_time: string,
    new_booking_end_time : string,
    remarks: string,
}

const payload = ref<IndividualReschedulePayloadInterface>({
    booking_id : props.bookingId,
    branch_id : props.branchId,
    booking_date: props.bookingDate,
    booking_start_time: '',
    booking_end_time : '',
    remarks: '',
    updated_by: 'admin' 
})

const getGetAvailableDates = () => {
    loader.value = true

    BookingService.getGetAvailableDates({
        package: props.package,
        riders: props.riders
    })
    .then(({data}: any) => {
        loader.value = false
        console.log(data);

        disabledDates.value = data.response
    })
    .catch((err) => {
        loader.value = false;
        showError.value = true;
        errorMsg.value = err.response.data.message;
        
        if(err.response.status == 401) {
            window.location.replace('/login')
        }
    })
}
const fetchDateAndTime = () => {
    if(payload.value.booking_date !== ''){
        loader.value = true
    
        const data = {
            branch_id: props.branchId,
            selected_date: payload.value.booking_date,
            from: from.value,
            to: to.value,
            package: props.package,
            riders: props.riders,
        }
    
        BookingService.fetchDateAndTime(data)
            .then((result: any) => {
                loader.value = false;
    
                timeSlots.value = result.data.response
            })
            .catch((error) => {
                loader.value = false;
                showError.value = true;
                errorMsg.value = error;
    
                if(error.response.status == 401) {
                    window.location.replace('/login')
                }
            })
    }
}

const rescheduleBooking = () => {
    caches.delete('shinagawa-admin');
    
    loader.value = true;

    BookingService.rescheduleBooking(props.bookingType, buildPayload())
        .then(({data}) => {
            if(data.response.result_status == 200 && data.response.message == 'Success') {
                success()
            } else if(data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value  = "Something went wrong. Please try again later";
            }
        })
        .catch((error: any) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const buildPayload = (): IndividualReschedulePayloadInterface|CorporateReschedulePayloadInterface => {
    
    payload.value.booking_date = new Date(payload.value.booking_date).toISOString().split('T')[0];

    if( props.bookingType.toLowerCase() == 'individual' ){
        return payload.value
    } else {
        return {
            corporate_booking_id: payload.value.booking_id,
            new_booking_date: payload.value.booking_date,
            new_booking_time: payload.value.booking_start_time,
            new_booking_end_time: payload.value.booking_end_time,
            remarks: payload.value.remarks,
        }
    }
}

const updateMonth = (val:any = null) => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch fetchDateAndTime for Active Month
    fetchDateAndTime();
}

const firstDayOfMonth = (val:any) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val:any) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}


const setStartEndTime = (value:string) => {
    if(value){
        const [start, end] = value.split('-');
    
        payload.value.booking_start_time = stringToTime(start);
        payload.value.booking_end_time = stringToTime(end);
    }
}

// This function takes a string in the format of "h:mm AM/PM" and returns a string in the format of "hh:mm:ss"
const stringToTime = (str:string): string => {
    // Split the string by space and colon
    const time:string[] = str.split(/[: ]/);
    // Convert hour and minute to numbers
    let hour:number = parseInt(time[0]);
    const minute:number = parseInt(time[1]);
    const period:string = time[2];
    // Check if the period is valid
    if (period !== "AM" && period !== "PM") {
        return "Invalid period";
    }
    // Check if the hour and minute are valid
    if (hour < 1 || hour > 12 || minute < 0 || minute > 59) {
        return "Invalid time";
    }
    // Convert hour to 24-hour format
    if (period === "PM" && hour < 12) {
        hour += 12;
    }
    if (period === "AM" && hour === 12) {
        hour = 0;
    }
    // Pad hour and minute with zeros if needed
    const newHour:string = hour.toString().padStart(2, "0");
    const newMinute:string = minute.toString().padStart(2, "0");
    // Return the time in the format of "hh:mm:ss"
    return `${newHour}:${newMinute}:00`;
}

const close = () => {
    show.value = false
    emit('close')
}

const success = () => {
    loader.value = false;
    show.value = false
    emit('success')
}


watch(
    () => payload.value.booking_date,
    (val) => {
        if(val){      
            const selectedDate = new Date(val);
    
            updateMonth({
                year: selectedDate.getFullYear(),
                month: selectedDate.getMonth()
            })
        }
    },
    { immediate: true }
);


onMounted(() => {
    getGetAvailableDates()
})

</script>