<template>
    <admin-layout 
        title="View Booking"
        show-back
        :backUrl="`/accounts/patients/bookings/${patientId}`"
        >
        <PatientLayout :patient-id="patientId">
            <template #contents>
                <div class="p-7">
                    <FormSection>
                        <template #title>
                            Booking Information
                        </template>

                        <template #description>
                            Relevant data and other details
                        </template>

                        <template #form>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.first_name"
                                    id="first_name"
                                    name="first_name"
                                    disabled
                                    label="First Name"
                                    placeholder="First Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.middle_name"
                                    id="middle_name"
                                    name="middle_name"
                                    disabled
                                    label="Middle Name"
                                    placeholder="Middle Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.last_name"
                                    id="last_name"
                                    name="last_name"
                                    disabled
                                    label="Last Name"
                                    placeholder="Last Name"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.patient_id"
                                    id="patient_id"
                                    name="patient_id"
                                    disabled
                                    label="Patient ID"
                                    placeholder="Patient ID"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.hospital_no"
                                    id="hospital_no"
                                    name="hospital_no"
                                    disabled
                                    label="Hospital ID"
                                    placeholder="Hospital ID"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput
                                    v-model="form.email_address"
                                    id="email_address"
                                    name="email_address"
                                    disabled
                                    label="Email Address"
                                    placeholder="Email Address"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.booking_start_time"
                                    id="booking_start_time"
                                    name="booking_start_time"
                                    disabled
                                    label="Start Time"
                                    placeholder="Start Time"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.booking_end_time"
                                    id="booking_end_time"
                                    name="booking_end_time"
                                    disabled
                                    label="End Time"
                                    placeholder="End Time"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.booking_date"
                                    id="booking_date"
                                    name="booking_date"
                                    disabled
                                    label="Booking Date"
                                    placeholder="Booking Date"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.medical_questionnaire"
                                    id="medical_questionnaire"
                                    name="medical_questionnaire"
                                    disabled
                                    label="Medical Questionnaire Filled"
                                    placeholder="Medical Questionnaire Filled"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.package_name"
                                    id="package_name"
                                    name="package_name"
                                    disabled
                                    label="Package"
                                    placeholder="Package"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.riders"
                                    id="riders"
                                    name="riders"
                                    disabled
                                    label="Rider/s"
                                    placeholder="Rider/s"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.package_fee"
                                    id="package_fee"
                                    name="package_fee"
                                    disabled
                                    add-on-left
                                    add-on-text="₱"
                                    label="Package Fee"
                                    placeholder="Package Fee"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.misc_fee"
                                    id="misc_fee"
                                    name="misc_fee"
                                    disabled
                                    add-on-left
                                    add-on-text="₱"
                                    label="Miscellaneous Fee"
                                    placeholder="Miscellaneous Fee"
                                />
                            </div>
                            <!-- <div class="col-span-6">
                                <TextInput 
                                    v-model="form.patient_type"
                                    id="patient_type"
                                    name="patient_type"
                                    disabled
                                    label="Patient Type"
                                    placeholder="Patient Type"
                                />
                            </div> -->
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.branch_name"
                                    id="branch_name"
                                    name="branch_name"
                                    disabled
                                    label="Branch"
                                    placeholder="Branch"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput 
                                    v-model="form.booking_type"
                                    id="booking_type"
                                    name="booking_type"
                                    disabled
                                    label="Booking Type"
                                    placeholder="Booking Type"
                                />
                            </div>
                            <div class="col-span-6">
                                <TextInput
                                    v-model="form.booking_status"
                                    id="booking_status"
                                    name="booking_status"
                                    disabled
                                    label="Booking Status"
                                    placeholder="Booking Status"
                                />
                            </div>
                            <div class="col-span-12">
                                <TextInput
                                    v-if="canViewMedicalRecords"
                                    v-model="form.remarks"
                                    id="remarks"
                                    name="remarks"
                                    textarea
                                    label="Doctor Notes/Remarks"
                                    placeholder="Doctor Notes/Remarks"
                                />
                            </div>
                            <!-- @TODO: Medical/Test Results -->
                            <div v-if="canViewMedicalRecords" class="col-span-12">
                                <DropzoneInput 
                                    label="Medical Result/s"
                                />
                            </div>
                        </template>
                        <!-- <template #actions>
                            <ButtonComponent v-if="canViewMedicalRecords && $hasPermission('add-medical-notes-and-results')" @click="submit">
                                Save Changes
                            </ButtonComponent>
                        </template> -->
                    </FormSection>
                </div>
            </template>
        </PatientLayout>
        <PageLoader v-if="loader" />
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import PatientLayout from '../components/PatientLayout.vue';

import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DropzoneInput from '@/components/inputs/DropzoneInput.vue';
import PageLoader from '@/components/PageLoader.vue';
import Patient from '@/classes/Patient';

import { useRoute } from 'vue-router';
import { ref, computed, onMounted } from 'vue';
import { formatter } from '@/helpers/NumberHelper'
import { format } from 'date-fns';

const route = useRoute();
const bookingId = ref(route.params.bookingId);
const patientId = ref(route.params.id);
const loader      = ref(true)
const showError   = ref(false);
const errorMsg    = ref(false);
const form        = ref({
    first_name            : null,
    middle_name          : null,
    last_name            : null,
    patient_id           : null,
    hospital_no          : null,
    email_address        : null,
    booking_start_time   : null,
    booking_end_time     : null,
    booking_date         : null,
    medical_questionnaire: null,
    package_name         : null,
    riders               : null,
    package_fee          : 0,
    misc_fee             : 0,
    // patient_type         : null,
    branch_name          : null,
    booking_type         : null,
    booking_status       : null,
    remarks              : null
})

const canViewMedicalRecords = computed(() => {
    if(
        window.$hasPermission('view-corporate-patient-medical-records') ||
        window.$hasPermission('view-web-booking-medical-records') ||
        window.$hasPermission('view-contact-center-booking-medical-records') ||
        window.$hasPermission('view-patient-medical-records') ||
        window.$hasPermission('view-medical-records-in-offline')
    ) {
        return true;
    }

    return false;
});

onMounted(() => {
    loader.value = true;
    setTimeout(() => {
        Patient.showSingleBooking(bookingId.value).then((response) => {
            loader.value = false;
            const result = response.data.response;
            const patient = result?.patient;
            // const packageDetails = result?.package_details[0];

            form.value.first_name     = patient?.first_name
            form.value.middle_name   = patient?.middle_name
            form.value.last_name     = patient?.last_name
            form.value.patient_id    = patient?.patient_id
            form.value.hospital_no   = patient?.hospital_no
            form.value.email_address = patient?.email_address

            form.value.booking_start_time = format(new Date(result?.booking_start_time), 'h:mm a')
            form.value.booking_end_time   = format(new Date(result?.booking_end_time), 'h:mm a')
            form.value.booking_date       = format(new Date(result?.booking_date), 'MMM dd, yyy')

            form.value.medical_questionnaire = result?.medical_questionnaire ? 'Yes' : 'No'
            form.value.package_name          = result?.package_name
            form.value.package_fee           = formatter.format(result?.total_fee)

            if(result?.other_charges_details.length > 0) {
                result?.other_charges_details.forEach(otherCharges => {
                    otherCharges.trans_line.forEach(trans_line => {
                        const totalAmount = trans_line.price * trans_line.qty;
                        form.value.misc_fee = formatter.format(totalAmount)
                    });
                });
            }

            form.value.branch_name    = result?.branch_name
            // form.value.patient_type   = result?.booking_type
            form.value.booking_type   = result?.booking_type
            form.value.booking_status = result?.status
            form.value.remarks        = '' //@TODO: doctors remarks
        })
        .catch((err) => {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = err.response.data.message;
        })
    }, 1000);
})

</script>