<template>
    <ion-page>
        <ion-content :fullscreen="true" class="">
            <div class="flex flex-wrap items-center h-screen">
                <div class="w-1/2 text-center px-20">
                    <div class="logo-img">
                        <img src="assets/logo.png" alt="logo" class="w-full max-w-[500px]">
                    </div>
                </div>

                <div class="w-1/2">
                    <form class="w-[60%] mx-auto" @submit.prevent="login">
                        <h3 class="text-lg font-bold">Admin</h3>
                        <p class="text-sm text-gray-500 mb-6">Kindly input your credentials to continue</p>
                        <div class="mb-6">
                            <text-input
                                type="email"
                                id="email"
                                name="email"
                                v-model="form.email"
                                label="Email Address"
                                placeholder="Email Address"
                                autocomplete="email"
                                autofocus
                            />
                        </div>
                        <div class="mb-2">
                            <password-input
                                id="password"
                                name="password"
                                v-model="form.password"
                                label="Password"
                                placeholder="Password"
                            />
                        </div>
                        <div class="flex items-center justify-end mb-6">
                            <a href="/forgot-password" class="text-xs m-0">Forgot Password?</a>
                        </div>
                        <button-component
                            :type="'submit'"
                            class="w-full"
                        >
                            Login
                        </button-component>
                    </form>
                </div>
            </div>
            <PageLoader v-if="loader" />
        </ion-content>

        <ErrorModal
            width="w-[548px]"
            title="Warning!"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import { ref, onMounted } from 'vue';
import Storage from '@/helpers/storage';
import AuthService from "@/classes/AuthService";

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref()
const errors = ref([])

const form = ref({
    email: '',
    password: '',
})

const redirectToPage = () => {
    const hasDashboardPermission = window.$hasPermission('view-dashboard');
    
    if(hasDashboardPermission) {
        window.location.replace('/home');
    } else {
        // Redirect to the content management if cms admin
        window.location.replace('/contents/packages');
    }
}
const login = () => {
    loader.value = true;

    AuthService.login(form.value.email, form.value.password)
    .then((result: any) => {
        loader.value = false;

        if(result.data.access_token) {
            const tokens = new Storage();
            const accessToken = `${result.data.token_type} ${result.data.access_token}`;
            const hybrainToken = result.data.hybrain;

            tokens.set('access_token', accessToken)
            tokens.set('hybrain_token', hybrainToken)
            tokens.set('admin', JSON.stringify(result.data.admin))
            tokens.set('permissions', JSON.stringify(result.data.permissions));

            redirectToPage();
        }
    }).catch( (err: any) => {
        loader.value = false;
        showError.value = true;
        errorMsg.value = err.response.data.message;

        if(err.response.status == 422) {
            errors.value = err.response.data.errors;
        }
    })
}

onMounted(() => {
    setTimeout(() => {
        caches.delete('shinagawa-admin');
    }, 1000);
})
</script>