<template>
    <FormSection>
        <template #title>
            Individual Booking Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-4">
                <TextInput 
                    v-model="payload.patient.first_name"
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    placeholder="First Name"
                />
            </div>

            <div class="col-span-4">
                <TextInput 
                    v-model="payload.patient.middle_name"
                    id="middle_name"
                    name="middle_name"
                    label="Middle Name"
                    placeholder="Middle Name"
                />
            </div>

            <div class="col-span-4">
                <TextInput
                    v-model="payload.patient.last_name"
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                />
            </div>

            <!-- <div class="col-span-4">
                <TextInput
                    v-model="payload.patient.suffix"
                    id="suffix"
                    name="suffix"
                    label="Suffix"
                    placeholder="Suffix"
                />
            </div> -->

            <div class="col-span-6">
                <MultiselectInput
                    v-model="payload.patient.sex"
                    id="sex"
                    name="sex"
                    label="Sex"
                    placeholder="Sex"
                    :options="sexes"
                />
            </div>


            <div class="col-span-6">
                <DatePicker
                    v-model="payload.patient.birthdate"
                    id="birthdate"
                    name="birthdate"
                    label="Birthday"
                    placeholder="MM/DD/YYYY"
                />
            </div>

            <div class="col-span-full">
                <TextInput
                    v-model="payload.patient.email_address"
                    id="email_address"
                    name="email_address"
                    label="Patient Email"
                    placeholder="Patient Email"
                />
            </div>

            <div class="col-span-full">
                <TextInput
                    v-model="payload.branch_name"
                    id="branch"
                    name="branch"
                    label="Branch"
                    placeholder="Branch"
                    disabled
                />
            </div>

            <div class="col-span-full">
                <MultiselectInput
                    v-model="selectedPackage"
                    id="selectedPackage"
                    name="selectedPackage"
                    label="Package"
                    placeholder="Package"
                    :options="packages"
                />
            </div>

            <div class="col-span-full">
                <MultiselectInput
                    v-model="selectedRiders"
                    id="selectedRiders"
                    name="selectedRiders"
                    label="Rider/s"
                    placeholder="Rider/s"
                    mode="tags"
                    :close-on-select="false"
                    searchable
                    :options="riders"
                />
            </div>

         


            <!-- <div class="col-span-full">
                <TextInput
                    v-model="payload.patient.patientType"
                    id="patientType"
                    name="patientType"
                    label="Patient Type"
                    placeholder="Patient Type"
                />
            </div> -->

            <div class="col-span-6">
                <DatePicker
                    ref="selectedDateRef"
                    v-model="selectedDate"
                    id="selectedDate"
                    name="selectedDate"
                    label="Booking Date"
                    placeholder="MM/DD/YYYY"
                    :min-date="currentDate"
                    :fully-booked-dates="fullyBookedDates"
                    hide-offset-dates
                    highlight-disabled-days
                    @updateMonth="updateMonth"
                    :disabledWeekDays="getDisabledDates"
                />
            </div>

            <div class="col-span-6">
                <MultiselectInput
                    v-model="selectedSlot"
                    id="selectedSlot"
                    name="selectedSlot"
                    label="Time Slots"
                    placeholder="Time Slots"
                    searchable
                    :options="timeSlots"
                    @update:modelValue="setStartEndTime"
                />  
            </div>
        </template>

        <template #actions>
            <ButtonComponent :disabled="loader" v-if="$hasPermission('soft-book-dates-for-individual-patient')" @click="showConfirmation = true">
                Submit
            </ButtonComponent>
            <slot />
        </template>

        
    </FormSection>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        :errors="errors"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="redirectToCompanyIndex()"
        action-text="Confirm"
        title="Booking Submitted!"
        content="Booking has been submitted successfully"
    />

    <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; submitSoftBooking() "
        cancel-text="Cancel"
        action-text="Confirm"
        title="Submit Booking?"
        content="Are you sure you wish to submit this booking?"
    />
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { ref, watch, onMounted, computed } from 'vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import BookingService from '@/classes/BookingService';
import BranchService from "@/classes/Branch";
import { daysOfTheWeek } from "@/helpers/PackageRiderHelper";
import { formatter } from "@/helpers/NumberHelper";

import { 
    formattedPackage,
    formattedRider,
} from '@/interfaces/CorporateBookingInterface'

import { useRouter } from 'vue-router';

const router    = useRouter();
const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)
const selectedSlot = ref(null);

const props = defineProps ({
    branchId: {
        type: String,
        default: null
    }
});

// const suffixes = [
//     { id: '1', label: 'Mr.', value: 'Mr.' },
//     { id: '2', label: 'Ms.', value: 'Ms.' },
//     { id: '3', label: 'Mx.', value: 'Mx.' }
// ];

const sexes = [
    { id: '1', label: 'Male', value: 'Male' },
    { id: '2', label: 'Female', value: 'Female' },
    // { id: '3', label: 'Prefer not to Say', value: 'Prefer not to Say' },
];

const packages = ref([]);
const riders = ref([]);
const selectedRiders = ref([]);
const selectedPackage = ref(null);
const riderGroups = ref([]);
const unformattedPackages = ref([]);

const selectedTime = ref(null)
const fullyBookedDates = ref([]);
const unformattedTimeSlots = ref([]);
const timeSlots = ref([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const timezoneOffset = 8; // Philippines time zone is UTC+8
const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000 + timezoneOffset * 60 * 60 * 1000); // For 3 days advance booking

const minDate = computed(() => threeDaysLater.toISOString().slice(0, 10));
const from = ref(null);
const to = ref(null);

const selectedDateRef = ref<any>(null)
const selectedDate = ref(null)

interface PatientInterface {
    first_name: string,
    middle_name?: string,
    last_name: string,
    suffix?: string,
    sex: string,
    birthdate: string,
    email_address: string,
}

const payload = ref<{
    is_patient_portal: boolean,
    branch_id: number,
    patient: PatientInterface,
    package: any,
    riders: string[],
    package_id: string|null,
    package_fee: number,
    other_charges_line: any[],
    other_charges_fee: number,
    booking_date: string,
    booking_start_time: string,
    booking_end_time: string,
    branch_name: string,
    branch_address: string,
    package_name: string,
}>({
    is_patient_portal: false,
    branch_id: Number(props.branchId),
    patient: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        sex: '',
        birthdate: '',
        email_address: '',
    },
    package: {},
    riders: [],
    package_id: null,
    package_fee: 0,
    other_charges_line: [],
    other_charges_fee: 0,
    booking_date: '',
    booking_start_time: '',
    booking_end_time: '',
    branch_name: '',
    branch_address: '',
    package_name: '',
});

const formatPackages = () => {
    unformattedPackages.value.forEach(unformattedPackage => {
        const formattedPackage: formattedPackage = {
            id   : null,
            label: null,
            value: null,
            disabledDates: null,
        };

        formattedPackage.id    = unformattedPackage.id;
        formattedPackage.value = unformattedPackage.id;
        formattedPackage.label = unformattedPackage.name + ' - ' + formatter.format(unformattedPackage.price);
        formattedPackage.disabledDates = unformattedPackage.disabledDates;
        packages.value.push(formattedPackage);
    });
}

const formatRiders = () => {
    riderGroups.value.forEach(riderGroup => {
        riderGroup.items.forEach(unformattedRider => {
            const formattedRider: formattedRider = {
                id   : null,
                label: null,
                value: null,
                disabledDates: null,
                name: null,
            };
    
            formattedRider.id    = unformattedRider.item_id;
            formattedRider.value = unformattedRider.item_id;
            formattedRider.label = unformattedRider.item_name + ' - ' + formatter.format(unformattedRider.item_price);
            formattedRider.disabledDates = unformattedRider.disabledDates;
            formattedRider.name = unformattedRider.item_name;

            riders.value.push(formattedRider);
        });
    });
}

const isEmailValid = () => {
    const reg = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    
    return reg.test(payload.value.patient.email_address) ? true : false;
}

const fetchPackagesAndRiders = () => {
    loader.value = true

    BookingService.getPackagesAndRiders()
        .then((result: any) => {
            loader.value = false;
            unformattedPackages.value = result.data.packageResponse;

            const services = result.data.serviceResponse;
            const allergyTests = result.data.allergyTestResponse;
            riderGroups.value = services.concat(allergyTests);

            formatPackages();
            formatRiders();
        })
        .catch((error) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error;

            if(error.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const fetchDateAndTime = () => {
    if(selectedDate.value){
        loader.value = true
    
        const data = {
            branch_id: payload.value.branch_id,
            selected_date: selectedDate.value,
            from: from.value,
            to: to.value,
            package: payload.value.package_name,
            riders: payload.value.riders,
        }
    
        BookingService.fetchDateAndTime(data)
            .then((result: any) => {
                loader.value = false;
    
                timeSlots.value = result.data.response
                fullyBookedDates.value = result.data.response.fully_booked_dates;
            })
            .catch((error) => {

                loader.value = false;
                showError.value = true;
                errorMsg.value = error.response.data.message;
    
                if(error.response.status == 401) {
                    window.location.replace('/login')
                }
            })
    } else {
        timeSlots.value = [];
    }
}

const updateMonth = (val:any = null) => {
    console.log(val)
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch fetchDateAndTime for Active Month
    fetchDateAndTime();
}


const firstDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

const submitSoftBooking = () => {
    if(!isEmailValid()) {
        showError.value = true;
        errorMsg.value = "Please input a valid email address";

        return false;
    }

    loader.value = true;

    payload.value.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];

    BookingService.submitSoftBooking(payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                showSuccess.value = true;
                clearFields();
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const clearFields = () => {
    payload.value.is_patient_portal = false;
    payload.value.patient = {};
    payload.value.package = {};
    payload.value.riders = [];
    payload.value.package_id = null;
    payload.value.package_fee = null;
    payload.value.other_charges_line = [];
    payload.value.other_charges_fee = null;
    payload.value.booking_date = null;
    payload.value.booking_start_time = null;
    payload.value.booking_end_time = null;

    selectedRiders.value = [];
    selectedPackage.value = null;
    selectedDate.value = null;
    selectedTime.value = null;
    selectedSlot.value = [];

    selectedDateRef.value?.datePicker.clearValue()
}

const pushRiderInPayload = (filteredRiders) => {
    let riderPrice = 0;
    const rider_item_ids:string[] = [];
    const rider_names:string[] = [];
    filteredRiders.forEach(rider => {
        const formattedRider = {
            item_id: null,
            item_name: null,
            price: null,
        };

        formattedRider.item_id = rider.item_id;
        formattedRider.item_name = rider.item_name;
        formattedRider.price = rider.item_price;
        riderPrice += rider.item_price;
        payload.value.other_charges_line.push(formattedRider);

        rider_item_ids.push(formattedRider.item_id)
    });

    payload.value.other_charges_fee = riderPrice;

    riderGroups.value.forEach(rider => {
        const item = rider.items.filter(obj => rider_item_ids.includes(obj.item_id));
        if(item.length > 0) {
            item.forEach(a => {
                rider_names.push(a.item_name);
            });
        }
    });

    payload.value.riders = rider_names;
}

const selectRiders = (val) => {
    payload.value.other_charges_line = [];

    let filteredRiders = [];

    riderGroups.value.forEach(rider => {
        filteredRiders = filteredRiders.concat(rider.items.filter(filteredRider => val.includes(filteredRider.item_id)));
    });
    
    pushRiderInPayload(filteredRiders);
}

const clearPackagePayload = () => {
    payload.value.package_fee = null;
    payload.value.package_id = null;
}

const selectPackage = (packageId) => {
    clearPackagePayload();

    const filteredPackage = unformattedPackages.value.filter(unformattedPackage => packageId == unformattedPackage.id);
    payload.value.package = filteredPackage[0];
    payload.value.package_id = filteredPackage[0]?.id;
    payload.value.package_fee = filteredPackage[0]?.price;
    payload.value.package_name = filteredPackage[0]?.name;
}

const selectTimeSlot = (val) => {
    if(val) {
        console.log(val);
        payload.value.booking_start_time = val.split('-')[0];
        payload.value.booking_end_time = val.split('-')[1];
    }
}

const fetchBranch = async () => {
    try {
        loader.value = true;
        const response = await BranchService.editBranch(props.branchId)
        loader.value = false;
        const branchResponse = response.data.branch;
        
        if(branchResponse) {
            payload.value.branch_name = branchResponse.name;
            payload.value.branch_address = branchResponse.address_line;
        }
    }
    catch(error: any) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = error.response.data.message;
    }
}

const setStartEndTime = (value:string) => {
    if(value){
        const [start, end] = value.split('-');
    
        payload.value.booking_start_time = stringToTime(start);
        payload.value.booking_end_time = stringToTime(end);
    }
}


const getDisabledDates = computed<number[]>(():number[] => {
    let disabledDates:number[] = [];
    
    if(payload.value.package_id){
        const index = packages.value.findIndex( item => item.id == payload.value.package_id );
        
        disabledDates = [...new Set([...disabledDates, ...packages.value[index].disabledDates])]
    }

    if(payload.value.riders){

        payload.value.riders.forEach(rider => {
            const index = riders.value.findIndex(item => item.name == rider);
            disabledDates = [...new Set([...disabledDates, ...riders.value[index].disabledDates])]
        });
    }

    return disabledDates;
});

// This function takes a string in the format of "h:mm AM/PM" and returns a string in the format of "hh:mm:ss"
const stringToTime = (str:string): string => {
    // Split the string by space and colon
    const time:string[] = str.split(/[: ]/);
    // Convert hour and minute to numbers
    let hour:number = parseInt(time[0]);
    const minute:number = parseInt(time[1]);
    const period:string = time[2];
    // Check if the period is valid
    if (period !== "AM" && period !== "PM") {
        return "Invalid period";
    }
    // Check if the hour and minute are valid
    if (hour < 1 || hour > 12 || minute < 0 || minute > 59) {
        return "Invalid time";
    }
    // Convert hour to 24-hour format
    if (period === "PM" && hour < 12) {
        hour += 12;
    }
    if (period === "AM" && hour === 12) {
        hour = 0;
    }
    // Pad hour and minute with zeros if needed
    const newHour:string = hour.toString().padStart(2, "0");
    const newMinute:string = minute.toString().padStart(2, "0");
    // Return the time in the format of "hh:mm:ss"
    return `${newHour}:${newMinute}:00`;
}

const redirectToCompanyIndex = () => {
    showSuccess.value = false;
    router.push(`/branches/${props.branchId}/bookings/`);
}

const resetDateTime = (): void => {
    selectedDate.value = null;
    selectedSlot.value = null;

    selectedDateRef.value?.datePicker.clearValue()
    
    fetchDateAndTime()
} 

watch(
    () => selectedRiders.value,
    (val) => {
        if (val) {
            selectRiders(val);
        }
    
        resetDateTime()
    },
    { immediate: true }
);

watch(
    () => selectedPackage.value,
    (val) => {
        if(val){
            selectPackage(val);
        } else {
            payload.value.package = null;
            payload.value.package_id = null;
            payload.value.package_fee = 0;
            payload.value.package_name = '';
        }

        resetDateTime()
    },
    { immediate: true }
);

watch(
    () => selectedTime.value,
    (val) => {
        if(val)
        selectTimeSlot(val);
    },
    { immediate: true }
);

// watch(
//     () => selectedDate.value,
//     (val) => {
//         if(val)
//             selectedTime.value = null;
//             payload.value.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];
//             updateMonth();
//     },
//     { immediate: true }
// );


watch(
    () => selectedDate.value,
    (val) => {
        if(val){      
            const selectedDate = new Date(val);
    
            updateMonth({
                year: selectedDate.getFullYear(),
                month: selectedDate.getMonth()
            })
        }
    },
    { immediate: true }
);

onMounted(() => {
    fetchPackagesAndRiders();
    fetchBranch();
})

</script>