<template>
    <admin-layout 
        title="Create Branch"
        show-back
        backUrl="/branches"
    >
        <BranchesLayout isHidden>
            <template #contents>
                <div class="p-7">
                    <FormSection 
                        :form="form"
                        :errors="errors"
                        :regions="regions"
                        :provinces="provinces"
                        :cities="cities"
                        :barangays="barangays"
                        @clearProvince="locationService.clearAddress('province', form)"
                        @clearCity="locationService.clearAddress('city', form)"
                        @clearBgy="locationService.clearAddress('barangay', form)"
                    >
                        <ButtonComponent 
                        @click="submit">
                            Submit
                        </ButtonComponent>
                    </FormSection>
                </div>
            </template>
        </BranchesLayout>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Branch"
            content="Branch Created Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
        
        <PageLoader v-if="loader"/>

        <PageLoader v-if="loader"/>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import BranchesLayout from './components/BranchesLayout.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import PageLoader from '@/components/PageLoader.vue';

import branch from "@/classes/Branch";
import AuthService from '@/classes/AuthService';
import locationService from '@/classes/LocationService';
import { onMounted, ref, watch } from 'vue';

/**
 * ********************************
 * Data 
 * ********************************
 */
const loader      = ref(false)
const errors      = ref([]);
const show        = ref(false);
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref(false);

const provinces = ref([]);
const regions   = ref([]);
const cities    = ref([]);
const barangays = ref([]);

const form = ref({
    name         : '',
    address_line : '',
    region       : '',
    region_code  : '',
    province     : '',
    province_code: '',
    city         : '',
    city_code    : '',
    barangay     : '',
    barangay_code: '',
    zip_code     : '',
    mobile_number: '',
    start_time   : '',
    end_time     : '',
    image_path   : ''
})

/**
 * ********************************
 * Submit Form 
 * ********************************
 */
const submit = async () => {
    loader.value = true;

    const data = branch.formatData(form.value, null)

    try {
        await branch.storeBranch(data)
        loader.value      = false;
        show.value        = false;
        showSuccess.value = true
                
        setTimeout(() => {
            window.location.replace('/branches')
        }, 2000);
    }
    catch(error) {
        loader.value    = false;
        show.value      = false;
        showError.value = true;
        errorMsg.value  = error.response.data.message;

        if(error.response.status == 422) {
            errors.value = error.response.data.errors;
        }
        errorResponse(error)
    }
}

/**
 * ********************************
 * Methods 
 * ********************************
 */
const errorResponse = (err: object) => {
    loader.value = false

    if(err?.response.status == 401) {
        AuthService.redirectToLogin()
    }
}

const fetchData = async () => {
    loader.value = true
    const result = await locationService.fetchRegions()
    loader.value = false
    regions.value = result?.data
}

const fetchProvince = async () => {
    loader.value = true;
    await locationService.fetchLocationData(locationService.fetchProvinces, form.value.region_code, 'Prov', provinces);
    loader.value = false;
};

const fetchCity = async () => {
    loader.value = true;
    await locationService.fetchLocationData(locationService.fetchCities, form.value.province_code, 'Mun', cities);
    loader.value = false;
};

const fetchBrgy = async () => {
    loader.value = true;
    await locationService.fetchLocationData(locationService.fetchBarangays, form.value.city_code, 'Bgy', barangays);
    loader.value = false;
};

/**
 * ********************************
 * Watchers 
 * ********************************
 */
const watchFormValue = (value, callback) => {
    watch(
        () => form.value[value],
        (val) => {
        callback(val);
        },
        { immediate: true }
    );
}

watchFormValue("barangay_code", (val) => {
    if (val) {
        form.value.barangay = barangays.value.find((e) => e.code === val)?.label;
    }
});

watchFormValue("city_code", (val) => {
    barangays.value = [];
    if (val) {
        form.value.city = cities.value.find((e) => e.code === val)?.label;
        fetchBrgy();
    }
});

watchFormValue("province_code", (val) => {
    cities.value = [];
    barangays.value = [];
    if (val) {
        form.value.province = provinces.value.find((e) => e.code === val)?.label;
        fetchCity();
    }
});

watchFormValue("region_code", (val) => {
    cities.value = [];
    barangays.value = [];
    provinces.value = [];
    if (val) {
        form.value.region = regions.value.find((e) => e.code === val)?.label;
        fetchProvince();
  }
});

onMounted(() => fetchData())

</script>