<template>
    <admin-layout 
        title="View Company"
        show-back
        :backUrl="`/branches/${form.branch_id}/companies`">
        <div>
            <TabComponent :tabs="tabs" />
        </div>
        <div class="p-7">
            <FormSection
                :form="form"
                :errors="errors"
                :regions="regions"
                :provinces="provinces"
                :cities="cities"
                :barangays="barangays"
                @clearProvince="clearProvince"
                @clearCity="clearCity"
                @clearBgy="clearBgy"
            >
            <ButtonComponent @click="submit">
                    Submit
                </ButtonComponent>
            </FormSection>
        </div>
        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Company"
            content="Company Updated Successfully!"
        />

        <ErrorModal
            width="w-[548px]"
            title="Warning"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';

import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Company from '@/classes/Company';
import AuthService from '@/classes/AuthService';
import locationService from '@/classes/LocationService';

const route = useRoute();
const loader = ref(false)
const errors = ref([]);
const show = ref(false);
const branchId = ref(route.params.branchId);
const companyId = ref(route.params.companyId);
const showSuccess = ref(false);
const showError = ref(false);
const errorMsg = ref(false);

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Information',
        count: 0,
        href: `branches/${branchId.value}/companies/edit/${companyId.value}`,
        allowed: true
    },
    {
        name: 'Booking',
        count: 0,
        href: `branches/${branchId.value}/companies/booking/${companyId.value}`,
        allowed: true
    }
];

const form = ref({
    name: null,
    address_line: null,
    region: null,
    region_code: null,
    province: null,
    province_code: null,
    city: null,
    city_code: null,
    barangay: null,
    barangay_code: null,
    zip_code: null,
    mobile_number: null,
    branch_id: null,
})

const provinces = ref([]);
const regions = ref([]);
const cities = ref([]);
const barangays = ref([]);


const submit = () => {
    loader.value = true;

    Company.updateCompany(companyId.value, form.value)
        .then(() => {
            loader.value      = false;
            show.value        = false;
            showSuccess.value = true
            
            setTimeout(() => {
                window.location.replace(`branches/${form.value.branch_id}/companies/`)
            }, 2000);
        })
        .catch((err) => {
            loader.value    = false;
            show.value      = false;
            showError.value = true;
            errorMsg.value  = err.response.data.message;

            if(err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
            errorResponse(err)
        })
}

const errorResponse = (err: object) => {
    loader.value = false

    if(err?.response.status == 401) {
        AuthService.redirectToLogin()
    }
}


const fetchData = () => {
        loader.value = true
        locationService.fetchRegions()
        .then((result: any) => {
            loader.value = false
            regions.value = result.data
        })
}

 const fetchProvince = () => {
    loader.value = true
    locationService.fetchProvinces(`code=${form.value.region_code}&level=Prov`)
    .then((result: any) => {
        loader.value = false
        provinces.value = result.data
    })
}

 const fetchCity = () => {
    loader.value = true
    locationService.fetchCities(`code=${form.value.province_code}&level=Mun`)
    .then((result: any) => {
        loader.value = false
        cities.value = result.data
    })
}

const fetchBrgy = () => {
    loader.value = true
    locationService.fetchBarangays(`code=${form.value.city_code}&level=Bgy`)
    .then((result: any) => {
        loader.value = false
        barangays.value = result.data
    })
}

const clearProvince = () => {
    form.value.province_code = null
    form.value.city_code = null
    form.value.barangay_code = null
}

const clearCity = () => {
    form.value.city_code = null
    form.value.barangay_code = null
}

const clearBgy = () => {
    form.value.barangay_code = null
}
 
watch(
      () => form.value.barangay_code,
      (val) => {
        if(val) {
            form.value.barangay = barangays.value.find((e) => e.code == val)?.label
        }
      },
      { immediate: true }
    );

watch(
      () => form.value.city_code,
      (val) => {
        barangays.value = [];
        if(val) {
            form.value.city = cities.value.find((e) => e.code == val)?.label
            fetchBrgy()
        }
      },
      { immediate: true }
    );

watch(
      () => form.value.province_code,
      (val) => {
        cities.value = [];
        barangays.value = [];
        if(val) {
            form.value.province = provinces.value.find((e) => e.code == val)?.label
            fetchCity()
        }
      },
      { immediate: true }
    );

watch(
      () => form.value.region_code,
      (val) => {
        cities.value = [];
        barangays.value = [];
        provinces.value = [];
        if(val) {
            form.value.region = regions.value.find((e) => e.code == val)?.label
            fetchProvince()
        }
      },
      { immediate: true }
    );

onMounted(() => {
    fetchData()
    loader.value = true;

    Company.getSelectedCompany(companyId.value).then((response) => {
        loader.value = false;
        Object.assign(form.value, response.data.company)

        if(response.data.company) {
            setTimeout(() => {
                form.value.region = response.data.company.region;
                form.value.province = response.data.company.province;
                form.value.city = response.data.company.city;
                form.value.barangay = response.data.company.barangay;
            }, 100);
        }
    })
    .catch((err) => {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = err.response.data.message;

        if(err.response.status == 422) {
            errors.value = err.response.data.errors;
        }
    })
    
})
</script>