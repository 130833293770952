<template>
    <admin-layout title="Patients">
        <div v-if="isNetworkAvailable">
            <TabComponent
            :key="goToPage"
            :tabs="tabs"
            :button-items="true"
            >
                <template #buttons v-if="isNetworkAvailable">
                    <ButtonComponent
                        class="text-gray-900"
                        secondary
                        exportBtn
                        @click="exportData"
                        :disabled="loader"
                    >
                        Export
                    </ButtonComponent>
                    <ButtonComponent
                    exportBtn
                    @click="downloadResources"> 
                        Sync Offline Resources
                    </ButtonComponent>
                </template>
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6" v-if="isNetworkAvailable">
            <FilterComponent
                :search="searchText"
                @fetchData="searchData"
                :sidebar-filters="false"
                :custom-filters="activeTab !== 'activity_logs'"
            >
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
                :headers="headers" 
                :count="items?.total"
            >
                <template #body>
                    <tr v-for="(item, key) in items?.data" :key="key">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-3">
                            <p>{{ `${item.first_name} ${item.last_name}` }}</p>
                            <p class="text-gray-500">{{ item.email_address }}</p>
                        </td>
                        <td class="text-center">
                            <ActionButton 
                            edit-btn
                            @click="router.push(`/accounts/patients/show/${item.patient_id}`)"/>
                        </td>
                    </tr>
                </template>
            </DataTable>
            <page-pagination class="mb-6" 
                :items="items"  
                @nextPage="indexPatients"
            />
        </div>
        <PageLoader v-if="loader" />

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="Patients Export"
            content="Export is being processed. Please check your registered email."
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <LoadingModal
            :show="preCacheLoader && isNetworkAvailable"
            @cancel="preCacheLoader = false"
            @confirm="preCacheLoader = false"
            :restartLoader="preCacheLoader"
            title="Downloading Resources"
            content="Resources is being download for offline use. Please wait."
        />

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from '@/components/PageLoader.vue';
import PagePagination from '@/components/PagePagination.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import LoadingModal from '@/components/modal/LoadingModal.vue';
import ErrorModal from '@/components/ErrorModal.vue';

import throttle from "lodash/throttle";
import Patient from '@/classes/Patient';
import { useNetworkStore } from '@/store/network';
import AuthService from "@/classes/AuthService";

import { useRouter } from 'vue-router';
import { ref, onMounted, watch, computed } from 'vue';
import axios from 'axios';

const router = useRouter();
/**
 * ********************************
 * Data 
 * ********************************
 */
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

const showSuccess     = ref(false);

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

// const currentRoutePath = computed(() => router.currentRoute.value.path);
// const mainRoute = ref(`/accounts/patients`);
/**
 * ********************************
 * query 
 * ********************************
 */
const searchText = ref();
const activeTab  = ref();
const goToPage   = ref();

// Page Renderer Data
const items       = ref(null);
const items_count = ref();
const query       = ref(null);

const tabs: { name: string, count: string | null, href: string, allowed: boolean }[] = computed(() => [
    {
        name: 'Active',
        count: items_count.value,
        href: '/accounts/patients',
        allowed: isNetworkAvailable.value
    }
]);

const headers: { text: string }[] = [
    { text: 'Patient' },
];

/**
 * ********************************
 * Methods 
 * ********************************
 */

const indexPatients = async (data: string) => {
    const currentDate = new Date();
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 30);

    const withBooking = `&type=with_booking&booking_date_from=${currentDate.toISOString().split("T")[0]}&booking_date_to=${date.toISOString().split("T")[0]}`

    try {
        loader.value = true;

        const pageNum     = data?.match(/\d+/)[0] ? data?.match(/\d+/)[0] : 1;
        const queryString = searchText.value ? `&query=${searchText.value}` : '';
        const goToPage    = data ? `page=${pageNum}` : 'page=1';

        if(isNetworkAvailable.value) {
            console.log("Online - Fetching Patients...")
            const response = await Patient.indexPatient(`${goToPage}&page_number=${pageNum}&per_page=10&request_at=${new Date().toISOString()}` + queryString)
            
            loader.value          = false;
            items.value           = response.data;
            items_count.value     = response.data.data.total
            query.value           = response.data.query
        } else {
            console.log("Offline - Fetching Patients...")

            const response = await Patient.indexPatient(`${goToPage}&page_number=${pageNum}&per_page=10${withBooking}` + queryString)

            loader.value          = false;
            items.value           = response.data;
            items_count.value     = response.data.data.total
            query.value           = response.data.query
        }


    }catch(error: any){
        loader.value = false;
        showError.value = true;

        if(isNetworkAvailable.value) {
            errorMsg.value = error.response.data.message;
            
            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        } else {
            errorMsg.value = "Oppps... No available data was saved for offline use.";
        }
    }
}

const searchData = (val: string) => {
    searchText.value = val
}

const preCacheSelectedPatient = async (patient: any) => {
    try {
        // Precache Selected Patient
        await Patient.showPatient(patient.patient_id)
    } catch(error: any){
        console.log(error.response.status)
    }
}

const preCachedSelectedPatientSelectedBooking = async (booking: any) => {
    try {
        // Precache Selected Patient Selected Booking
        await Patient.showSingleBooking(booking.booking_id)
    } catch(error: any){
        console.log(error.response.status)
    }
}

const isLastSelectedPatientBookingCached = ref(false);

const preCachedSelectedPatientBookings = async (patient: any) => {
    try {
        // Precache Selected Patient Booking
        const response = await Patient.showPatientBookings(patient.patient_id, `page=1&page_number=1&per_page=10`)
        const total = response.data.total;
        const pages = Math.ceil(total / 10);

        for (let index = 0; index < pages; index++) {
            const page = index + 1;
            const res = await Patient.showPatientBookings(patient.patient_id, `page=${page}&page_number=${page}&per_page=10`)

            if(typeof res.data.data == 'object') {
                let booking = null;
                for (let i = 0; i < Object.values(res.data.data).length; i++) { 
                    booking = Object.values(res.data.data)[i];
                    preCachedSelectedPatientSelectedBooking(booking);
                    if (i === Object.values(res.data.data).length - 1) {
                        isLastSelectedPatientBookingCached.value = true;
                        console.log("Last iteration for selected patient booking");
                    }
                }
            } else {
                let booking = null;
                for (let i = 0; i < res.data.data.length; i++) {
                    booking = res.data.data[i];
                    preCachedSelectedPatientSelectedBooking(booking);
                    if (i === res.data.data.length - 1) {
                        isLastSelectedPatientBookingCached.value = true;
                        console.log("Last iteration for selected patient booking");
                    }
                }
            }
        }
    } catch(error: any){
        console.log(error.response.status)
    }
}

const preCacheLoader = ref(false);
const isLastPatientIndexCached = ref(false);
const isLastSelectedPatientCached = ref(false);

const preCachePatients = async () => {
    const currentDate = new Date();
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 30);

    const withBooking = `&type=with_booking&booking_date_from=${currentDate.toISOString().split("T")[0]}&booking_date_to=${date.toISOString().split("T")[0]}`

    const cache = await caches.open('shinagawa-admin');
    const cachedResponse = await cache.match(`${process.env.VUE_APP_API_URL}patients/index?page=1&page_number=1&per_page=10${withBooking}&${AuthService.getHybrainTokenQueryString()}`);

    if(!cachedResponse) {
        preCacheLoader.value = true;
        try {
            // Precache Patients
            const response = await Patient.indexPatient(`page=1&page_number=1&per_page=10${withBooking}`)
            const total = response.data.total;
            const pages = Math.ceil(total / 10);

            for (let index = 0; index < pages; index++) {
                const page = index + 1;
                const res = await Patient.indexPatient(`page=${page}&page_number=${page}&per_page=10${withBooking}`)
    
                if(typeof res.data.data == 'object') {
                    let patient = null;
                    for (let i = 0; i < Object.values(res.data.data).length; i++) { 
                        patient = Object.values(res.data.data)[i];
                        preCacheSelectedPatient(patient);
                        preCachedSelectedPatientBookings(patient);

                        if (i === Object.values(res.data.data).length - 1) {
                            isLastSelectedPatientCached.value = true;
                            console.log("Last iteration for selected patient");
                        }
                    }
                } else {
                    let patient = null;
                    for (let i = 0; i < res.data.data.length; i++) {
                        patient = res.data.data[i];
                        preCacheSelectedPatient(patient);
                        preCachedSelectedPatientBookings(patient);

                        if (i === res.data.data.length - 1) {
                            isLastSelectedPatientCached.value = true;
                            console.log("Last iteration for selected patient");
                        }
                    }
                }

                if (index === pages - 1) {
                    isLastPatientIndexCached.value = true;
                    console.log("Last iteration for patients index");
                }
            }

        } catch(error: any){
            console.log(error.response.status)
        }
        // preCacheLoader.value = false;
    } else {
        isLastPatientIndexCached.value = true;
        isLastSelectedPatientCached.value = true;
        isLastSelectedPatientBookingCached.value = true;
    }
}

/**
 * ********************************
 * Export 
 * ********************************
 */
 const exportData = async () => {
    try {
        loader.value = true;
        const queryString = searchText.value ? `&query=${searchText.value}` : 'query';
        const goToPage    = 'page=1';

        await Patient.exportPatient(`${goToPage}&page_number=1&per_page=1000` + queryString);

        showSuccess.value = true;
    } catch (error: any) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

const loadingInterval = () => {
    const checker = setInterval(() => {
        if(isLastPatientIndexCached.value && isLastSelectedPatientCached.value && isLastSelectedPatientBookingCached.value) {
            console.log("All resources has ben cached")
            setTimeout(() => {
                preCacheLoader.value = false;
            }, 3000);
            clearInterval(checker);
        } else {
            console.log("Caching... plase wait")
        }
    }, 1000)
}

const downloadResources = async () => {
    caches.delete('shinagawa-admin');
    isLastPatientIndexCached.value = false;
    isLastSelectedPatientCached.value  = false;
    isLastSelectedPatientBookingCached.value  = false;

    // setTimeout(() => {
        preCachePatients();
        loadingInterval();
    // }, 1000);
}

/**
 * ********************************
 * Wathchers 
 * ********************************
 */
 watch(
    searchText,
    throttle(() => {
        indexPatients(goToPage.value)
    }, 1000)
);

watch(
    () => isNetworkAvailable.value,
    (val) => {
        console.log('Watcher - Network: ' + val);
        indexPatients(goToPage.value);
    }
);

onMounted(() =>  {
    loader.value = true;
    setTimeout(() => {
        console.log('Mounted Hook - Network: ' + isNetworkAvailable.value);
        indexPatients(goToPage.value);
        preCachePatients();
    }, 1000);

    // Set Internal for Resources Downloader
    loadingInterval();
})

</script>