<template>
    <FormSection>
        <template #title>
            Illness/Condition Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-12">
                <TextInput 
                    label="Illness/Condition Name"
                    placeholder="Illness/Condition Name"
                    v-model="item.name"
                    id="name"
                    name="name"
                />
            </div>
        </template>
        <template #actions>
            <slot />
        </template>
    </FormSection>
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import { ref } from 'vue';

const props = defineProps ({
    form: {
        type: Object,
        default: (() => {
            return {};
        })
    }
});

const item = ref(props.form)

</script>