<template>
    <FormSection>
        <template #title>
            Branch Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-12">
                <TextInput 
                    label="Branch Name"
                    placeholder="Branch Name"
                    id="name"
                    name="name"
                    v-model="item.name"
                    :error="errors.name ? errors.name[0] : ''"
                />
            </div>
            <div class="col-span-12">
                <TextInput 
                    label="Address Line"
                    placeholder="Address Line"
                    id="address_line"
                    name="address_line"
                    v-model="item.address_line"
                    :error="errors.address_line ? errors.address_line[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <MultiselectInput
                    id="regions"
                    label="Region"
                    name="regions"
                    v-model="item.region_code"
                    placeholder="Region"
                    :options="regions"
                    searchable
                    @click="$emit('clearProvince')"
                    :error="errors.region_code ? errors.region_code[0] : ''"
                />
            </div>

            <div class="col-span-6">
                <MultiselectInput
                    id="province"
                    name="province"
                    label="Province"
                    v-model="item.province_code"
                    placeholder="Province"
                    :options="provinces"
                    searchable
                    @click="$emit('clearCity')"
                    :error="errors.province_code ? errors.province_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <MultiselectInput
                    id="city"
                    name="city"
                    label="City"
                    v-model="item.city_code"
                    placeholder="City"
                    :options="cities"
                    searchable
                    @click="$emit('clearBgy')"
                    :error="errors.city_code ? errors.city_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <MultiselectInput
                    id="barangay"
                    name="barangay"
                    label="Barangay"
                    v-model="item.barangay_code"
                    placeholder="Barangay"
                    :options="barangays"
                    searchable
                    :error="errors.barangay_code ? errors.barangay_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    label="Zip Code"
                    placeholder="Zip Code"
                    id="zip_code"
                    name="zip_code"
                    v-model="item.zip_code"
                    :error="errors.zip_code ? errors.zip_code[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <text-input
                    id="mobile_number"
                    name="mobile_number"
                    label="Mobile Number"
                    :add-on-left="true"
                    add-on-text="+63"
                    @keypress="numberonly"
                    placeholder="Mobile Number"
                    :maxlength="10"
                    v-model="item.mobile_number"
                    :error="errors.mobile_number ? errors.mobile_number[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <!-- <DatePicker     
                    label="Start Time"
                    placeholder="Start Time"
                    timePicker
                    enableTimePicker
                    v-model="item.start_time"
                    :error="errors.start_time ? errors.start_time[0] : ''"
                /> -->
                <TextInput
                    type="time"
                    label="Start Time"
                    placeholder="Start Time"
                    id="start_time"
                    name="start_time"
                    v-model="item.start_time"
                    :error="errors.start_time ? errors.start_time[0] : ''"
                    :step="60"
                    helper="Do not include minutes"
                />
            </div>
            <div class="col-span-6">
                <!-- <DatePicker     
                    label="End Time"
                    placeholder="End Time"
                    id="end_time"
                    name="end_time"
                    timePicker
                    enableTimePicker
                    v-model="item.end_time"
                    :error="errors.end_time ? errors.end_time[0] : ''"
                /> -->
                <TextInput
                    type="time"
                    label="End Time"
                    placeholder="End Time"
                    id="end_time"
                    name="end_time"
                    v-model="item.end_time"
                    :error="errors.end_time ? errors.end_time[0] : ''"
                    :step="60"
                    helper="Do not include minutes"
                />
            </div>
            <div class="col-span-12">
                <DropzoneInput 
                    v-model:file="item.image_path"
                    v-model:path="item.image_path"
                    label="Image"
                    :error="errors.image_path ? errors.image_path[0] : ''"
                />
            </div>
        </template>

        <template #actions>
            <slot />
        </template>
    </FormSection>
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import DropzoneInput from '@/components/inputs/DropzoneInput.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';

import { ref } from 'vue';
import { numberonly } from '@/helpers/NumberHelper';

const props = defineProps ({
    form: {
        type: Object,
    },
    errors: {
        type: Array,
        default: () => []
    },
    regions: {
        type: Array,
        default: () => []
    },
    provinces: {
        type: Array,
        default: () => []
    },
    cities: {
        type: Array,
        default: () => []
    },
    barangays: {
        type: Array,
        default: () => []
    }
});

const item = ref(props.form)

</script>