<template>
    <admin-layout 
    title="Number of Bookings"
    show-back
    backUrl="/reports">
        <div>
            <TabComponent
            :button-items="true"
            >
                <template #buttons>
                    <div class="py-3">
                        <ButtonComponent
                        @click="exportNewBookings()"
                        class="text-gray-900"
                        secondary
                        exportBtn>
                            Export
                        </ButtonComponent>
                    </div>
                </template>
            </TabComponent>
        </div>
        <!-- Filter -->
        <div class="py-3 px-6">
            <FilterComponent
                :text-filters="false"
                @update:filters="applyFilters"
            >
                <template #fields>
                    <div class="space-y-6">
                        <DatePicker
                            id="dateRange"
                            name="dateRange"
                            range
                            is24
                            isPresetRanges
                            v-model="filters.dateRange"
                            placeholder="Start Date & End Date"
                            label="Start Date & End Date"
                        />
                        <MultiselectInput
                            id="timePeriod"
                            name="timePeriod"
                            label="Time Period"
                            v-model="filters.timePeriod"
                            placeholder="Time Period"
                            :options="timePeriods"
                        />
                        <MultiselectInput
                            id="bookingStatus"
                            name="bookingStatus"
                            label="Booking Status"
                            v-model="filters.bookingStatus"
                            placeholder="Booking Status"
                            :options="bookingStatuses"
                        />
                        <MultiselectInput
                            id="bookingType"
                            name="bookingType"
                            label="Booking Type"
                            v-model="filters.bookingType"
                            placeholder="Booking Type"
                            :options="bookingTypes"
                        />
                    </div>
                </template>
            </FilterComponent>
        </div>

        <div class="">
            <DataTable 
            :headers="headers" 
            :noAction="true"
            :count="items?.total">
                <template #body>
                    <tr v-for="(newBooking, key) in items?.data" :key="key">
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5 w-8/12">
                            {{ `${newBooking.patient.first_name} ${newBooking.patient.last_name}` }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ newBooking.booking_type }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            <StatusPill
                            :color="getStatusColor(newBooking.status)" 
                            :text="newBooking.status"/>
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ `${formatDate(newBooking.booking_date)} (${formatTime(newBooking.booking_start_time)} - ${formatTime(newBooking.booking_end_time)})` }}
                        </td>
                        <td class="text-sm text-gray-900 whitespace-nowrap px-6 py-5">
                            {{ `${formatDate(newBooking.booking_created_date)} (${formatTime(newBooking.booking_created_date)})` }}
                        </td>
                    </tr>
                </template>
            </DataTable>
            <PagePagination class="mb-6" 
                :items="items"  
                @nextPage="fetchNewBookings"
            />
        </div>

        <PageLoader v-if="loader" />

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Close"
            title="New Bookings Export"
            content="Export is being processed. Please check your registered email."
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import DataTable from '@/components/DataTable.vue';
import TabComponent from '@/components/TabComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StatusPill from '@/components/StatusPill.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import PagePagination from '@/components/PagePagination.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import { ref, onMounted } from 'vue';
import { paginatePage } from '@/helpers/PageHelper';
import ReportService from '@/classes/ReportService';
import { startOfYear, endOfYear } from 'date-fns';
import { format } from 'date-fns';

interface Patient {
    first_name: string,
    last_name: string
}

interface newBooking {
    patient: Patient
    booking_type: string,
    status: string,
    booking_date: Date,
    booking_start_time: Date,
    booking_end_time: Date,
    booking_created_date: Date
}

interface Item {
    data: newBooking[]
    total: number
}

const loader      = ref(false);
const showSuccess = ref(false);
const showError   = ref(false);
const errorMsg    = ref();
const goToPage    = ref();

const items = ref<Item>({
    data: [],
    total: 0
});

const filters = ref({
    dateRange: [
        startOfYear(new Date).toISOString(),
        endOfYear(new Date).toISOString(),
    ],
    bookingType: 'All',
    bookingStatus: 'All',
    timePeriod: 'year'
})

const bookingTypes = [
    { id: '1', label: 'All', value: 'All'},
    { id: '2', label: 'Individual', value: 'Individual'},
    { id: '3', label: 'Corporate', value: 'Corporate'},
];

const bookingStatuses = [
    { id: '1', label: 'All', value: 'All'},
    { id: '2', label: 'Booked', value: 'Booked'},
    { id: '3', label: 'Cancelled/No-Show', value: 'Cancelled/No-Show'},
    { id: '4', label: 'Done', value: 'Done'},
];

const timePeriods = [
    { id: '1', label: '24 hours', value: 'day'},
    { id: '2', label: '1 week', value: 'week'},
    { id: '3', label: '1 month', value: 'month'},
    { id: '4', label: '1 year', value: 'year'},
];

const headers: { text: string }[] = [
    { text: 'Patient Name' },
    { text: 'Booking Type' },
    { text: 'Status' },
    { text: 'Booking Date and Time' },
    { text: 'Date Created'}
];

const formatDate = (date: Date) => {
    if(date) {
        return format(new Date(date), 'LLL d, yyyy')
    }

    return '--';
}

const formatTime = (date: Date) => {
    if(date) {
        return format(new Date(date), 'hh:mm:ss')
    }

    return '--';
}

const getStatusColor = (status: string) => {
    if(status) {
        let color = null;
        switch (status) {
            case 'Booked':
                color = 'blue';
                break;
        
            case 'Cancelled/No-Show':
            case 'Cancelled':
                color = 'red';
                break;
        
            case 'Done':
                color = 'green';
                break;
            
            default:
                color = 'yellow'
                break;
        }

        return color;
    }

    return 'yellow';
}

const applyFilters = () => {
    fetchNewBookings(goToPage.value);
}

const fetchNewBookings = async (data: string) => {
    try {
        loader.value = true;

        const startDate     = filters.value.dateRange ? `&startDate=${filters.value.dateRange[0]}` : '';
        const endDate       = filters.value.dateRange ? `&endDate=${filters.value.dateRange[1]}` : '';
        const bookingType   = filters.value.dateRange ? `&bookingType=${filters.value.bookingType}` : '';
        const bookingStatus = filters.value.dateRange ? `&bookingStatus=${filters.value.bookingStatus}` : '';
        const timePeriod    = filters.value.dateRange ? `&timePeriod=${filters.value.timePeriod}` : '';
        
        goToPage.value = paginatePage(data)

        const response   = await ReportService.fetchNewBookings(`${goToPage.value}${startDate}${endDate}${bookingType}${bookingStatus}${timePeriod}`);
        loader.value = false;
        
        items.value = response.data;
    } catch (error: any) {
            loader.value    = false;
            showError.value = true;
            errorMsg.value  = error;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
    }
    finally{
        loader.value = false
    }
}

const exportNewBookings = async () => {
    try {
        loader.value = true;

        const startDate     = filters.value.dateRange ? `&startDate=${filters.value.dateRange[0]}` : '';
        const endDate       = filters.value.dateRange ? `&endDate=${filters.value.dateRange[1]}` : '';
        const bookingType   = filters.value.dateRange ? `&bookingType=${filters.value.bookingType}` : '';
        const bookingStatus = filters.value.dateRange ? `&bookingStatus=${filters.value.bookingStatus}` : '';
        const timePeriod    = filters.value.dateRange ? `&timePeriod=${filters.value.timePeriod}` : '';

        await ReportService.exportNewBookings(`${startDate}${endDate}${bookingType}${bookingStatus}${timePeriod}`);

        showSuccess.value = true;
    } catch (error: any) {
        showError.value = true;
        errorMsg.value  = error.response?.data.message;
    } finally {
        loader.value = false;
    }
};

onMounted(() => { fetchNewBookings(goToPage.value); })
</script>