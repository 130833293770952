<template>
    <div class="w-full relative">
        <text-input 
        :modelValue="modelValue"
        :label="label"
        :placeholder="placeholder"
        :type="showPassword ? 'text' : 'password'"
        :id="id"
        @input="$emit('update:modelValue', $event.target.value)"
        :autocomplete="autocomplete"
        >
            <template #password>
                <div
                    @click="showPassword = !showPassword"
                    class="
                        cursor-pointer
                        absolute
                        inset-y-0
                        right-0
                        pr-3
                        flex
                        items-center
                    "
                >
                    <template v-if="showPassword">
                        <EyeIcon
                            class="h-5 w-5 text-primary-500"
                            aria-hidden="true"
                        />
                    </template>
                    <template v-else>
                        <EyeSlashIcon
                            class="h-5 w-5 text-primary-500"
                            aria-hidden="true"
                        />
                    </template>
                </div>
            </template>
        </text-input>
        <!-- <label :for="id" class="block text-sm text-gray-900">{{
            label
        }}</label>
        <div
            class="relative rounded-md"
            :class="label ? 'mt-1' : ''"
        >
            <input
                :id="id"
                :type="showPassword ? 'text' : 'password'"
                class="
                    block
                    w-full
                    text-sm
                    h-11
                    disabled:bg-gray-100
                    focus:outline-none
                    focus:ring-0
                    focus:border-gray-900
                    text-gray-800
                    border-gray-300
                    placeholder-gray-500
                    rounded-md
                "
                :placeholder="placeholder"
            />
        </div> -->
        
        <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p>

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="email-error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/solid";
import { ref } from 'vue';
import TextInput from '@/components/inputs/TextInput.vue';

defineProps({
    modelValue: {
        default: null,
        required: true,
    },
    id: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: "current-password",
    },
    placeholder: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    helper: {
        type: String,
        default: null,
    },
    error: {
        type: String,
        default: null,
    },
});

const showPassword = ref<boolean>(false);

</script>