<template>
    <admin-layout title="Pages">
        <div class="">
            <DataTable 
            :headers="headers" 
            :count="items.length">
                <template #body>
                    <tr v-for="item in items" :key="item.name">
                        <td class="w-6/12 px-6 py-5 text-sm text-gray-900 whitespace-nowrap">
                            {{ item.title }}
                        </td>
                        <td class="text-center">
                            <ActionButton 
                                edit-btn    
                                @click="router.push(`/contents/pages/show/${item.id}`)"
                            />
                        </td>
                    </tr>
                </template>
            </DataTable>
        </div>

    <PageLoader v-if="loader" />

    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import DataTable from '@/components/DataTable.vue';
import ActionButton from '@/components/ActionButton.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import Pages from "@/classes/Pages";

import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';

import { PageInterface } from '@/interfaces/BaseInterface';

const router = useRouter();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

const items = ref<PageInterface[]>([]);

// Methods
const indexSystemNotification = () => {
    loader.value = true;

    Pages.fetch()
        .then((response: any) => {
            loader.value = false;

            items.value = response.data;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
    loader.value = false;
}

const headers: { text: string }[] = [
    { text: 'Page' },
];

onMounted( () => {
    indexSystemNotification();
});

</script>