<template>
    <admin-layout 
    title="View Setting"
    show-back
    backUrl="/settings">
        <div>
            <TabComponent
                :tabs="tabs"
            >
            </TabComponent>
        </div>
        <div class="p-7" v-if="currentRoutePath !== `/settings/edit/${route.params.id}/logs`">
            <FormSection>
                <template #title>
                    Reservation Capacity Per Course Information
                </template>

                <template #description>
                    Relevant data and other details
                </template>

                <template #form>
                    <div class="col-span-12">
                        <p class="text-sm mb-6">Reservation Capacity Per Course</p>
                        <QuantitySelector
                            v-if="item"
                            v-model="payload.count"
                            :value="item.count"
                            id="count"
                            name="count"
                        />
                    </div>
                </template>
                <template #actions>
                    <ButtonComponent v-if="$hasPermission('manage-settings')" @click.prevent="showConfirmation = true">
                        Save Changes
                    </ButtonComponent>
                </template>
            </FormSection>
        </div>

        <div v-else>
            <ActivityLogsTable
                :logs="logs"
                @fetch-index="editSetting"
            />
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Confirm"
            title="Reservation Capacity Updated!"
            content="Reservation Capacity has been updated successfully"
        />

        <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; updateSetting() "
            cancel-text="Cancel"
            action-text="Confirm"
            title="Update Reservation Capacity?"
            content="Are you sure you wish to update this Reservation Capacity?"
        />
       
    </admin-layout>
</template>

<script setup lang="ts">
import AdminLayout from '@/layouts/AdminLayout.vue';
import TabComponent from '@/components/TabComponent.vue';
import FormSection from '@/components/FormSection.vue';
import QuantitySelector from '@/components/inputs/QuantitySelector.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import ActivityLogsTable from '@/components/ActivityLogsTable.vue';

import Setting from "@/classes/Setting";

import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';

const route = useRoute();
const router = useRouter();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

const item = ref(null);
const payload = computed(() => {
    return {
        id: route.params.id,
        count: item.value.count
    }
})

const currentRoutePath = computed(() => router.currentRoute.value.path);

const log_counts      = ref(0);
const logs            = ref({});

// Methods
const editSetting = () => {
    loader.value = true;

    Setting.editSetting(route.params.id)
        .then((response: any) => {
            loader.value = false;

            item.value = response.data.reservationCapacity;

            log_counts.value      = response?.data.log_counts
            logs.value            = response?.data.logs
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const updateSetting = () => {
    loader.value = true;

    Setting.updateSetting(route.params.id, payload.value)
        .then((response: any) => {
            loader.value = false;
            showSuccess.value = true;
            item.value = response.data.reservationCapacity;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const tabs: { name: string, count: number, href?: string, allowed: boolean}[] = computed(() => [
    {
        name: 'Information',
        count: 0,
        href: `/settings/edit/${route.params.id}`,
        allowed: true
    },
    {
        name: 'Activity logs',
        count: log_counts.value,
        href: `/settings/edit/${route.params.id}/logs`,
        allowed:
            window.$hasPermission('view-activity-logs')
    },
]);

onMounted( () => {
    editSetting();
});

</script>