<template>
    <FormSection>
        <template #title>
            Admin Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-6">
                <TextInput 
                    v-model="item.first_name"
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    placeholder="First Name"
                    :error="errors.first_name ? errors.first_name[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    v-model="item.last_name"
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                    :error="errors.last_name ? errors.last_name[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    v-model="item.email"
                    id="email"
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    :error="errors.email ? errors.email[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <TextInput 
                    v-model="item.mobile_number"
                    id="mobile_number"
                    name="mobile_number"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    addOnLeft
                    addOnText="+63"
                    :maxlength="10"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    :error="errors.mobile_number ? errors.mobile_number[0] : ''"
                />
            </div>
            <div class="col-span-6">
                <MultiselectInput
                    id="roles"
                    name="roles"
                    label="Role"
                    v-model="item.role_id"
                    placeholder="Role"
                    :options="roles"
                    searchable
                    :error="errors.role_id ? errors.role_id[0] : ''"
                />
            </div>
        </template>
        <template #actions>
            <slot />
        </template>
    </FormSection>
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';

import { onMounted, ref } from 'vue';
import AdminData from '@/classes/AdminData';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    errors: {
        type: Array,
        required: true
    }
});

const item = ref(props.form)
const roles = ref([]);

const fetchRoles = async () => {
    const response = await AdminData.getRoles();
    roles.value = response.data
}

onMounted(() => fetchRoles())

</script>