<template>
    <FormSection>
        <template #title>
            Role Information
        </template>

        <template #description>
            Relevant data and other details
        </template>

        <template #form>
            <div class="col-span-6">
                <TextInput
                    :model-value="props.role.name"
                    id="role_name"
                    name="role_name"
                    label="Role Name"
                    placeholder="Role Name"
                    disabled
                />
            </div>
        </template>
    </FormSection>
</template>

<script setup lang="ts">
import FormSection from '@/components/FormSection.vue';
import TextInput from '@/components/inputs/TextInput.vue';

const props = defineProps ({
    role: {
        type: Object,
        default: () => ({})
    },
});
</script>